var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("dcwmt_navigationdrawer", {
        attrs: { nowlink: _vm.nowlink, canDraw: _vm.canDraw },
      }),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { app: "" } },
        [_c("tab", { on: { onClick: _vm.selectMenu } })],
        1
      ),
      _vm._v(" "),
      _c("v-main", { attrs: { app: "" } }, [_c("dcwmt_map")], 1),
      _vm._v(" "),
      _c(
        "v-footer",
        { attrs: { app: "" } },
        [
          _c("dcwmt_footer", {
            attrs: { viewerController: _vm.viewerController },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }