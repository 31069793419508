var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { subheader: "", tile: "" } },
    [
      _c("v-subheader", [_vm._v("描画方法の切り替え")]),
      _vm._v(" "),
      _vm._l(_vm.projections, function (projection) {
        return _c(
          "v-list-item",
          {
            key: projection.title,
            on: {
              click: function ($event) {
                return _vm.onClick(projection)
              },
            },
          },
          [
            _c("v-list-item-title", [
              _vm._v("\n      " + _vm._s(projection.title) + "\n    "),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }