var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.layers, function (layer, index) {
              return _c(
                "v-col",
                { key: index, attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("v-switch", {
                                staticStyle: {
                                  float: "left",
                                  margin: "0",
                                  padding: "0",
                                },
                                attrs: { inset: "" },
                                model: {
                                  value: layer.show,
                                  callback: function ($$v) {
                                    _vm.$set(layer, "show", $$v)
                                  },
                                  expression: "layer.show",
                                },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(layer.name) +
                                  "\n              "
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.canRaise(index),
                                          expression: "canRaise(index)",
                                        },
                                      ],
                                      attrs: {
                                        icon: "",
                                        "x-small": "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.raise(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  ▲\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.canLower(index),
                                          expression: "canLower(index)",
                                        },
                                      ],
                                      attrs: {
                                        icon: "",
                                        "x-small": "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.lower(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  ▼\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(
                            "\n            opacity = " +
                              _vm._s(layer.opacity) +
                              "\n            "
                          ),
                          _c("v-slider", {
                            attrs: {
                              min: "0",
                              max: "1",
                              step: "0.1",
                              "hide-details": "",
                            },
                            model: {
                              value: layer.opacity,
                              callback: function ($$v) {
                                _vm.$set(layer, "opacity", $$v)
                              },
                              expression: "layer.opacity",
                            },
                          }),
                          _vm._v(" "),
                          layer.type === "contour"
                            ? _c(
                                "div",
                                [
                                  _vm._v(
                                    "\n              threshold interval = " +
                                      _vm._s(layer.thretholdinterval) +
                                      "\n              "
                                  ),
                                  _c("v-slider", {
                                    attrs: {
                                      min: "0",
                                      max: "20",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: layer.thretholdinterval,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          layer,
                                          "thretholdinterval",
                                          $$v
                                        )
                                      },
                                      expression: "layer.thretholdinterval",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          layer.type === "tone"
                            ? _c(
                                "div",
                                [
                                  _vm._v(
                                    "\n              color map\n              "
                                  ),
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: {
                                        scrollable: "",
                                        "max-width": "500px",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "margin-left": "auto",
                                                        },
                                                        attrs: {
                                                          "x-small": "",
                                                          outlined: "",
                                                          tile: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                    Change color map\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.dialog,
                                        callback: function ($$v) {
                                          _vm.dialog = $$v
                                        },
                                        expression: "dialog",
                                      },
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v(" カラーマップの切り替え "),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c("DcwmtColormap", {
                                                ref: "colormap",
                                                refInFor: true,
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "blue darken-1",
                                                    text: "",
                                                  },
                                                  on: { click: _vm.close },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Close\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "blue darken-1",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.save(index)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Save\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c("ColorBar", {
                                        style: _vm.colorBarSize,
                                        attrs: {
                                          width: 200,
                                          height: 25,
                                          clrindex: layer.clrindex,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          layer.type === "vector"
                            ? _c(
                                "div",
                                [
                                  _vm._v(
                                    "\n              vector interval = { x: " +
                                      _vm._s(layer.vecinterval.x) +
                                      ", y:\n              " +
                                      _vm._s(layer.vecinterval.y) +
                                      " }\n              "
                                  ),
                                  _c("v-slider", {
                                    attrs: {
                                      min: "0",
                                      max: "10",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: layer.vecinterval.x,
                                      callback: function ($$v) {
                                        _vm.$set(layer.vecinterval, "x", $$v)
                                      },
                                      expression: "layer.vecinterval.x",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-slider", {
                                    attrs: {
                                      min: "0",
                                      max: "10",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: layer.vecinterval.y,
                                      callback: function ($$v) {
                                        _vm.$set(layer.vecinterval, "y", $$v)
                                      },
                                      expression: "layer.vecinterval.y",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }