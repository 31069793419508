

















import Vue from 'vue';
import Panel from './Panel.vue';

export default Vue.extend({
  components: {
    Panel,
  },
  data: () => ({
    fab: false,
  }),
});
