/*カラーマップの定義　命名規則 clrmap_xx xx:2桁の数 */

export type ClrmapType = {
  r: number;
  g: number;
  b: number;
};

export const clrmap: Array<Array<ClrmapType>> = [
  [
    { r: 255, g: 0, b: 209 },
    { r: 242, g: 0, b: 209 },
    { r: 228, g: 0, b: 209 },
    { r: 214, g: 0, b: 209 },
    { r: 198, g: 0, b: 209 },
    { r: 181, g: 0, b: 209 },
    { r: 161, g: 0, b: 209 },
    { r: 140, g: 0, b: 209 },
    { r: 114, g: 0, b: 209 },
    { r: 80, g: 0, b: 209 },
    { r: 0, g: 0, b: 209 },
    { r: 0, g: 0, b: 219 },
    { r: 0, g: 0, b: 228 },
    { r: 0, g: 0, b: 238 },
    { r: 0, g: 0, b: 247 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 66, b: 255 },
    { r: 0, g: 93, b: 255 },
    { r: 0, g: 114, b: 255 },
    { r: 0, g: 132, b: 255 },
    { r: 0, g: 147, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 186, b: 255 },
    { r: 0, g: 198, b: 255 },
    { r: 0, g: 209, b: 255 },
    { r: 0, g: 219, b: 255 },
    { r: 0, g: 228, b: 255 },
    { r: 0, g: 238, b: 255 },
    { r: 0, g: 247, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 247 },
    { r: 0, g: 255, b: 238 },
    { r: 0, g: 255, b: 228 },
    { r: 0, g: 255, b: 219 },
    { r: 0, g: 255, b: 209 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 186 },
    { r: 0, g: 255, b: 174 },
    { r: 0, g: 255, b: 161 },
    { r: 0, g: 255, b: 147 },
    { r: 0, g: 255, b: 132 },
    { r: 0, g: 255, b: 114 },
    { r: 0, g: 255, b: 93 },
    { r: 0, g: 255, b: 66 },
    { r: 0, g: 255, b: 0 },
    { r: 66, g: 255, b: 0 },
    { r: 93, g: 255, b: 0 },
    { r: 114, g: 255, b: 0 },
    { r: 132, g: 255, b: 0 },
    { r: 147, g: 255, b: 0 },
    { r: 161, g: 255, b: 0 },
    { r: 174, g: 255, b: 0 },
    { r: 186, g: 255, b: 0 },
    { r: 198, g: 255, b: 0 },
    { r: 209, g: 255, b: 0 },
    { r: 219, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 238, g: 255, b: 0 },
    { r: 247, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 114, b: 0 },
    { r: 255, g: 93, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 66, b: 66 },
    { r: 255, g: 93, b: 93 },
    { r: 255, g: 114, b: 114 },
    { r: 255, g: 132, b: 132 },
    { r: 255, g: 147, b: 147 },
    { r: 255, g: 161, b: 161 },
    { r: 255, g: 174, b: 174 },
    { r: 255, g: 186, b: 186 },
    { r: 255, g: 198, b: 198 },
    { r: 255, g: 209, b: 209 },
    { r: 255, g: 219, b: 219 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 238, b: 238 },
    { r: 255, g: 247, b: 247 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 35, g: 0, b: 0 },
    { r: 57, g: 0, b: 0 },
    { r: 73, g: 0, b: 0 },
    { r: 86, g: 0, b: 0 },
    { r: 97, g: 0, b: 0 },
    { r: 107, g: 0, b: 0 },
    { r: 113, g: 0, b: 0 },
    { r: 122, g: 0, b: 0 },
    { r: 130, g: 0, b: 0 },
    { r: 137, g: 0, b: 0 },
    { r: 145, g: 0, b: 0 },
    { r: 150, g: 0, b: 0 },
    { r: 152, g: 0, b: 0 },
    { r: 155, g: 0, b: 0 },
    { r: 157, g: 0, b: 0 },
    { r: 160, g: 0, b: 0 },
    { r: 163, g: 0, b: 0 },
    { r: 165, g: 0, b: 0 },
    { r: 168, g: 0, b: 0 },
    { r: 170, g: 0, b: 0 },
    { r: 172, g: 0, b: 0 },
    { r: 174, g: 22, b: 0 },
    { r: 177, g: 35, b: 0 },
    { r: 179, g: 45, b: 0 },
    { r: 182, g: 53, b: 0 },
    { r: 184, g: 57, b: 0 },
    { r: 186, g: 64, b: 0 },
    { r: 188, g: 68, b: 0 },
    { r: 191, g: 73, b: 0 },
    { r: 193, g: 78, b: 0 },
    { r: 197, g: 83, b: 0 },
    { r: 202, g: 87, b: 0 },
    { r: 205, g: 90, b: 0 },
    { r: 210, g: 94, b: 0 },
    { r: 214, g: 98, b: 0 },
    { r: 219, g: 102, b: 0 },
    { r: 223, g: 106, b: 0 },
    { r: 227, g: 109, b: 0 },
    { r: 230, g: 112, b: 0 },
    { r: 233, g: 114, b: 0 },
    { r: 238, g: 117, b: 0 },
    { r: 242, g: 121, b: 0 },
    { r: 245, g: 124, b: 0 },
    { r: 249, g: 127, b: 0 },
    { r: 252, g: 129, b: 0 },
    { r: 255, g: 136, b: 0 },
    { r: 255, g: 142, b: 0 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 154, b: 0 },
    { r: 255, g: 160, b: 0 },
    { r: 255, g: 165, b: 0 },
    { r: 255, g: 168, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 179, b: 0 },
    { r: 255, g: 184, b: 0 },
    { r: 255, g: 189, b: 0 },
    { r: 255, g: 193, b: 0 },
    { r: 255, g: 196, b: 0 },
    { r: 255, g: 201, b: 0 },
    { r: 255, g: 205, b: 0 },
    { r: 255, g: 210, b: 0 },
    { r: 255, g: 214, b: 0 },
    { r: 255, g: 218, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 225, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 232, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 240, b: 32 },
    { r: 255, g: 244, b: 66 },
    { r: 255, g: 246, b: 81 },
    { r: 255, g: 249, b: 98 },
    { r: 255, g: 253, b: 114 },
    { r: 255, g: 255, b: 128 },
    { r: 255, g: 255, b: 140 },
    { r: 255, g: 255, b: 151 },
    { r: 255, g: 255, b: 161 },
    { r: 255, g: 255, b: 168 },
    { r: 255, g: 255, b: 177 },
    { r: 255, g: 255, b: 186 },
    { r: 255, g: 255, b: 195 },
    { r: 255, g: 255, b: 204 },
    { r: 255, g: 255, b: 211 },
    { r: 255, g: 255, b: 216 },
    { r: 255, g: 255, b: 224 },
    { r: 255, g: 255, b: 231 },
    { r: 255, g: 255, b: 238 },
    { r: 255, g: 255, b: 245 },
    { r: 255, g: 255, b: 251 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 27 },
    { r: 0, g: 0, b: 45 },
    { r: 0, g: 0, b: 57 },
    { r: 0, g: 0, b: 68 },
    { r: 0, g: 0, b: 76 },
    { r: 0, g: 0, b: 86 },
    { r: 0, g: 0, b: 90 },
    { r: 0, g: 0, b: 97 },
    { r: 0, g: 0, b: 103 },
    { r: 0, g: 0, b: 109 },
    { r: 0, g: 0, b: 115 },
    { r: 0, g: 0, b: 121 },
    { r: 0, g: 0, b: 124 },
    { r: 0, g: 0, b: 129 },
    { r: 0, g: 0, b: 134 },
    { r: 0, g: 0, b: 138 },
    { r: 0, g: 0, b: 143 },
    { r: 0, g: 0, b: 148 },
    { r: 0, g: 0, b: 152 },
    { r: 0, g: 0, b: 155 },
    { r: 0, g: 0, b: 159 },
    { r: 0, g: 0, b: 163 },
    { r: 0, g: 0, b: 167 },
    { r: 0, g: 0, b: 170 },
    { r: 0, g: 0, b: 172 },
    { r: 0, g: 0, b: 174 },
    { r: 0, g: 0, b: 177 },
    { r: 0, g: 0, b: 179 },
    { r: 0, g: 0, b: 182 },
    { r: 0, g: 0, b: 184 },
    { r: 0, g: 0, b: 186 },
    { r: 0, g: 0, b: 189 },
    { r: 0, g: 0, b: 191 },
    { r: 0, g: 0, b: 193 },
    { r: 0, g: 0, b: 195 },
    { r: 0, g: 0, b: 197 },
    { r: 0, g: 0, b: 200 },
    { r: 0, g: 0, b: 202 },
    { r: 0, g: 0, b: 204 },
    { r: 0, g: 0, b: 205 },
    { r: 0, g: 0, b: 208 },
    { r: 0, g: 0, b: 210 },
    { r: 0, g: 0, b: 212 },
    { r: 0, g: 0, b: 214 },
    { r: 0, g: 0, b: 215 },
    { r: 0, g: 39, b: 218 },
    { r: 0, g: 62, b: 219 },
    { r: 0, g: 76, b: 222 },
    { r: 0, g: 90, b: 223 },
    { r: 0, g: 102, b: 226 },
    { r: 0, g: 112, b: 227 },
    { r: 0, g: 118, b: 229 },
    { r: 0, g: 128, b: 231 },
    { r: 0, g: 136, b: 233 },
    { r: 0, g: 144, b: 235 },
    { r: 0, g: 152, b: 237 },
    { r: 0, g: 159, b: 238 },
    { r: 0, g: 164, b: 240 },
    { r: 50, g: 171, b: 242 },
    { r: 69, g: 177, b: 244 },
    { r: 86, g: 183, b: 245 },
    { r: 100, g: 189, b: 247 },
    { r: 111, g: 195, b: 249 },
    { r: 122, g: 200, b: 251 },
    { r: 129, g: 204, b: 252 },
    { r: 137, g: 210, b: 253 },
    { r: 146, g: 215, b: 255 },
    { r: 155, g: 220, b: 255 },
    { r: 162, g: 224, b: 255 },
    { r: 170, g: 226, b: 255 },
    { r: 174, g: 227, b: 255 },
    { r: 182, g: 228, b: 255 },
    { r: 189, g: 230, b: 255 },
    { r: 195, g: 232, b: 255 },
    { r: 201, g: 233, b: 255 },
    { r: 207, g: 235, b: 255 },
    { r: 213, g: 237, b: 255 },
    { r: 217, g: 238, b: 255 },
    { r: 223, g: 239, b: 255 },
    { r: 227, g: 241, b: 255 },
    { r: 231, g: 243, b: 255 },
    { r: 233, g: 244, b: 255 },
    { r: 237, g: 246, b: 255 },
    { r: 239, g: 247, b: 255 },
    { r: 242, g: 248, b: 255 },
    { r: 245, g: 250, b: 255 },
    { r: 248, g: 251, b: 255 },
    { r: 251, g: 253, b: 255 },
    { r: 253, g: 254, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 147 },
    { r: 0, g: 0, b: 156 },
    { r: 0, g: 0, b: 168 },
    { r: 0, g: 0, b: 179 },
    { r: 0, g: 0, b: 191 },
    { r: 0, g: 0, b: 200 },
    { r: 0, g: 0, b: 210 },
    { r: 0, g: 0, b: 216 },
    { r: 0, g: 0, b: 225 },
    { r: 0, g: 0, b: 234 },
    { r: 0, g: 0, b: 242 },
    { r: 0, g: 0, b: 250 },
    { r: 0, g: 35, b: 255 },
    { r: 0, g: 59, b: 255 },
    { r: 0, g: 86, b: 255 },
    { r: 0, g: 105, b: 255 },
    { r: 0, g: 122, b: 255 },
    { r: 0, g: 136, b: 255 },
    { r: 0, g: 149, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 168, b: 255 },
    { r: 0, g: 179, b: 255 },
    { r: 0, g: 189, b: 255 },
    { r: 55, g: 198, b: 255 },
    { r: 78, g: 207, b: 255 },
    { r: 94, g: 216, b: 255 },
    { r: 105, g: 222, b: 255 },
    { r: 118, g: 230, b: 255 },
    { r: 131, g: 238, b: 255 },
    { r: 141, g: 246, b: 255 },
    { r: 152, g: 253, b: 255 },
    { r: 161, g: 255, b: 255 },
    { r: 171, g: 255, b: 255 },
    { r: 177, g: 255, b: 255 },
    { r: 184, g: 255, b: 255 },
    { r: 193, g: 255, b: 255 },
    { r: 200, g: 255, b: 255 },
    { r: 208, g: 255, b: 255 },
    { r: 215, g: 255, b: 255 },
    { r: 219, g: 255, b: 255 },
    { r: 226, g: 255, b: 255 },
    { r: 233, g: 255, b: 255 },
    { r: 239, g: 255, b: 255 },
    { r: 245, g: 255, b: 255 },
    { r: 251, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 249 },
    { r: 255, g: 255, b: 244 },
    { r: 255, g: 255, b: 237 },
    { r: 255, g: 255, b: 231 },
    { r: 255, g: 255, b: 225 },
    { r: 255, g: 255, b: 218 },
    { r: 255, g: 255, b: 213 },
    { r: 255, g: 255, b: 206 },
    { r: 255, g: 255, b: 199 },
    { r: 255, g: 255, b: 191 },
    { r: 255, g: 255, b: 183 },
    { r: 255, g: 255, b: 175 },
    { r: 255, g: 255, b: 169 },
    { r: 255, g: 255, b: 160 },
    { r: 255, g: 255, b: 151 },
    { r: 255, g: 248, b: 140 },
    { r: 255, g: 241, b: 130 },
    { r: 255, g: 232, b: 117 },
    { r: 255, g: 224, b: 105 },
    { r: 255, g: 218, b: 94 },
    { r: 255, g: 210, b: 76 },
    { r: 255, g: 200, b: 55 },
    { r: 255, g: 191, b: 0 },
    { r: 255, g: 181, b: 0 },
    { r: 255, g: 170, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 150, b: 0 },
    { r: 255, g: 137, b: 0 },
    { r: 255, g: 123, b: 0 },
    { r: 255, g: 106, b: 0 },
    { r: 255, g: 86, b: 0 },
    { r: 255, g: 62, b: 0 },
    { r: 255, g: 35, b: 0 },
    { r: 248, g: 0, b: 0 },
    { r: 240, g: 0, b: 0 },
    { r: 232, g: 0, b: 0 },
    { r: 223, g: 0, b: 0 },
    { r: 215, g: 0, b: 0 },
    { r: 209, g: 0, b: 0 },
    { r: 199, g: 0, b: 0 },
    { r: 189, g: 0, b: 0 },
    { r: 179, g: 0, b: 0 },
    { r: 168, g: 0, b: 0 },
    { r: 156, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 26, g: 26, b: 26 },
    { r: 38, g: 38, b: 38 },
    { r: 46, g: 46, b: 46 },
    { r: 53, g: 53, b: 53 },
    { r: 60, g: 60, b: 60 },
    { r: 66, g: 66, b: 66 },
    { r: 71, g: 71, b: 71 },
    { r: 76, g: 76, b: 76 },
    { r: 80, g: 80, b: 80 },
    { r: 85, g: 85, b: 85 },
    { r: 89, g: 89, b: 89 },
    { r: 93, g: 93, b: 93 },
    { r: 97, g: 97, b: 97 },
    { r: 100, g: 100, b: 100 },
    { r: 104, g: 104, b: 104 },
    { r: 107, g: 107, b: 107 },
    { r: 111, g: 111, b: 111 },
    { r: 114, g: 114, b: 114 },
    { r: 117, g: 117, b: 117 },
    { r: 120, g: 120, b: 120 },
    { r: 123, g: 123, b: 123 },
    { r: 126, g: 126, b: 126 },
    { r: 129, g: 129, b: 129 },
    { r: 132, g: 132, b: 132 },
    { r: 134, g: 134, b: 134 },
    { r: 137, g: 137, b: 137 },
    { r: 140, g: 140, b: 140 },
    { r: 142, g: 142, b: 142 },
    { r: 145, g: 145, b: 145 },
    { r: 147, g: 147, b: 147 },
    { r: 150, g: 150, b: 150 },
    { r: 152, g: 152, b: 152 },
    { r: 155, g: 155, b: 155 },
    { r: 157, g: 157, b: 157 },
    { r: 159, g: 159, b: 159 },
    { r: 161, g: 161, b: 161 },
    { r: 164, g: 164, b: 164 },
    { r: 166, g: 166, b: 166 },
    { r: 168, g: 168, b: 168 },
    { r: 170, g: 170, b: 170 },
    { r: 172, g: 172, b: 172 },
    { r: 174, g: 174, b: 174 },
    { r: 176, g: 176, b: 176 },
    { r: 178, g: 178, b: 178 },
    { r: 181, g: 181, b: 181 },
    { r: 183, g: 183, b: 183 },
    { r: 184, g: 184, b: 184 },
    { r: 186, g: 186, b: 186 },
    { r: 188, g: 188, b: 188 },
    { r: 190, g: 190, b: 190 },
    { r: 192, g: 192, b: 192 },
    { r: 194, g: 194, b: 194 },
    { r: 196, g: 196, b: 196 },
    { r: 198, g: 198, b: 198 },
    { r: 200, g: 200, b: 200 },
    { r: 201, g: 201, b: 201 },
    { r: 203, g: 203, b: 203 },
    { r: 205, g: 205, b: 205 },
    { r: 207, g: 207, b: 207 },
    { r: 209, g: 209, b: 209 },
    { r: 210, g: 210, b: 210 },
    { r: 212, g: 212, b: 212 },
    { r: 214, g: 214, b: 214 },
    { r: 215, g: 215, b: 215 },
    { r: 217, g: 217, b: 217 },
    { r: 219, g: 219, b: 219 },
    { r: 220, g: 220, b: 220 },
    { r: 222, g: 222, b: 222 },
    { r: 224, g: 224, b: 224 },
    { r: 225, g: 225, b: 225 },
    { r: 227, g: 227, b: 227 },
    { r: 228, g: 228, b: 228 },
    { r: 230, g: 230, b: 230 },
    { r: 232, g: 232, b: 232 },
    { r: 233, g: 233, b: 233 },
    { r: 235, g: 235, b: 235 },
    { r: 236, g: 236, b: 236 },
    { r: 238, g: 238, b: 238 },
    { r: 239, g: 239, b: 239 },
    { r: 241, g: 241, b: 241 },
    { r: 242, g: 242, b: 242 },
    { r: 244, g: 244, b: 244 },
    { r: 245, g: 245, b: 245 },
    { r: 247, g: 247, b: 247 },
    { r: 248, g: 248, b: 248 },
    { r: 250, g: 250, b: 250 },
    { r: 251, g: 251, b: 251 },
    { r: 253, g: 253, b: 253 },
    { r: 254, g: 254, b: 254 },
  ],
  [
    { r: 255, g: 181, b: 255 },
    { r: 251, g: 181, b: 255 },
    { r: 247, g: 181, b: 255 },
    { r: 242, g: 181, b: 255 },
    { r: 238, g: 181, b: 255 },
    { r: 233, g: 181, b: 255 },
    { r: 228, g: 181, b: 255 },
    { r: 224, g: 181, b: 255 },
    { r: 219, g: 181, b: 255 },
    { r: 214, g: 181, b: 255 },
    { r: 209, g: 181, b: 255 },
    { r: 203, g: 181, b: 255 },
    { r: 198, g: 181, b: 255 },
    { r: 192, g: 181, b: 255 },
    { r: 186, g: 181, b: 255 },
    { r: 181, g: 181, b: 255 },
    { r: 181, g: 186, b: 255 },
    { r: 181, g: 192, b: 255 },
    { r: 181, g: 198, b: 255 },
    { r: 181, g: 203, b: 255 },
    { r: 181, g: 209, b: 255 },
    { r: 181, g: 214, b: 255 },
    { r: 181, g: 219, b: 255 },
    { r: 181, g: 224, b: 255 },
    { r: 181, g: 228, b: 255 },
    { r: 181, g: 233, b: 255 },
    { r: 181, g: 238, b: 255 },
    { r: 181, g: 242, b: 255 },
    { r: 181, g: 247, b: 255 },
    { r: 181, g: 251, b: 255 },
    { r: 181, g: 255, b: 255 },
    { r: 181, g: 255, b: 251 },
    { r: 181, g: 255, b: 247 },
    { r: 181, g: 255, b: 242 },
    { r: 181, g: 255, b: 238 },
    { r: 181, g: 255, b: 233 },
    { r: 181, g: 255, b: 228 },
    { r: 181, g: 255, b: 224 },
    { r: 181, g: 255, b: 219 },
    { r: 181, g: 255, b: 214 },
    { r: 181, g: 255, b: 209 },
    { r: 181, g: 255, b: 203 },
    { r: 181, g: 255, b: 198 },
    { r: 181, g: 255, b: 192 },
    { r: 181, g: 255, b: 186 },
    { r: 181, g: 255, b: 181 },
    { r: 186, g: 255, b: 181 },
    { r: 192, g: 255, b: 181 },
    { r: 198, g: 255, b: 181 },
    { r: 203, g: 255, b: 181 },
    { r: 209, g: 255, b: 181 },
    { r: 214, g: 255, b: 181 },
    { r: 219, g: 255, b: 181 },
    { r: 224, g: 255, b: 181 },
    { r: 228, g: 255, b: 181 },
    { r: 233, g: 255, b: 181 },
    { r: 238, g: 255, b: 181 },
    { r: 242, g: 255, b: 181 },
    { r: 247, g: 255, b: 181 },
    { r: 251, g: 255, b: 181 },
    { r: 255, g: 255, b: 181 },
    { r: 255, g: 251, b: 181 },
    { r: 255, g: 247, b: 181 },
    { r: 255, g: 242, b: 181 },
    { r: 255, g: 238, b: 181 },
    { r: 255, g: 233, b: 181 },
    { r: 255, g: 228, b: 181 },
    { r: 255, g: 224, b: 181 },
    { r: 255, g: 219, b: 181 },
    { r: 255, g: 214, b: 181 },
    { r: 255, g: 209, b: 181 },
    { r: 255, g: 203, b: 181 },
    { r: 255, g: 198, b: 181 },
    { r: 255, g: 192, b: 181 },
    { r: 255, g: 186, b: 181 },
    { r: 255, g: 181, b: 181 },
    { r: 255, g: 181, b: 186 },
    { r: 255, g: 181, b: 192 },
    { r: 255, g: 181, b: 198 },
    { r: 255, g: 181, b: 203 },
    { r: 255, g: 181, b: 209 },
    { r: 255, g: 181, b: 214 },
    { r: 255, g: 181, b: 219 },
    { r: 255, g: 181, b: 224 },
    { r: 255, g: 181, b: 228 },
    { r: 255, g: 181, b: 233 },
    { r: 255, g: 181, b: 238 },
    { r: 255, g: 181, b: 242 },
    { r: 255, g: 181, b: 247 },
    { r: 255, g: 181, b: 251 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 66 },
    { r: 0, g: 0, b: 93 },
    { r: 0, g: 0, b: 114 },
    { r: 0, g: 0, b: 132 },
    { r: 0, g: 0, b: 147 },
    { r: 0, g: 0, b: 161 },
    { r: 0, g: 0, b: 174 },
    { r: 0, g: 0, b: 186 },
    { r: 0, g: 0, b: 198 },
    { r: 0, g: 0, b: 209 },
    { r: 0, g: 0, b: 219 },
    { r: 0, g: 0, b: 228 },
    { r: 0, g: 0, b: 238 },
    { r: 0, g: 0, b: 247 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 66, b: 255 },
    { r: 0, g: 93, b: 255 },
    { r: 0, g: 114, b: 255 },
    { r: 0, g: 132, b: 255 },
    { r: 0, g: 147, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 186, b: 255 },
    { r: 0, g: 198, b: 255 },
    { r: 0, g: 209, b: 255 },
    { r: 0, g: 219, b: 255 },
    { r: 0, g: 228, b: 255 },
    { r: 0, g: 238, b: 255 },
    { r: 0, g: 247, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 247 },
    { r: 0, g: 255, b: 238 },
    { r: 0, g: 255, b: 228 },
    { r: 0, g: 255, b: 219 },
    { r: 0, g: 255, b: 209 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 186 },
    { r: 0, g: 255, b: 174 },
    { r: 0, g: 255, b: 161 },
    { r: 0, g: 255, b: 147 },
    { r: 0, g: 255, b: 132 },
    { r: 0, g: 255, b: 114 },
    { r: 0, g: 255, b: 93 },
    { r: 0, g: 255, b: 66 },
    { r: 0, g: 255, b: 0 },
    { r: 66, g: 255, b: 0 },
    { r: 93, g: 255, b: 0 },
    { r: 114, g: 255, b: 0 },
    { r: 132, g: 255, b: 0 },
    { r: 147, g: 255, b: 0 },
    { r: 161, g: 255, b: 0 },
    { r: 174, g: 255, b: 0 },
    { r: 186, g: 255, b: 0 },
    { r: 198, g: 255, b: 0 },
    { r: 209, g: 255, b: 0 },
    { r: 219, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 238, g: 255, b: 0 },
    { r: 247, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 114, b: 0 },
    { r: 255, g: 93, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 247, g: 0, b: 0 },
    { r: 238, g: 0, b: 0 },
    { r: 228, g: 0, b: 0 },
    { r: 219, g: 0, b: 0 },
    { r: 209, g: 0, b: 0 },
    { r: 198, g: 0, b: 0 },
    { r: 186, g: 0, b: 0 },
    { r: 174, g: 0, b: 0 },
    { r: 161, g: 0, b: 0 },
    { r: 147, g: 0, b: 0 },
    { r: 132, g: 0, b: 0 },
    { r: 114, g: 0, b: 0 },
    { r: 93, g: 0, b: 0 },
    { r: 66, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 247 },
    { r: 255, g: 255, b: 238 },
    { r: 255, g: 255, b: 228 },
    { r: 255, g: 255, b: 219 },
    { r: 255, g: 255, b: 209 },
    { r: 255, g: 255, b: 198 },
    { r: 255, g: 255, b: 186 },
    { r: 255, g: 255, b: 174 },
    { r: 255, g: 255, b: 161 },
    { r: 255, g: 255, b: 147 },
    { r: 255, g: 255, b: 132 },
    { r: 255, g: 255, b: 114 },
    { r: 255, g: 255, b: 93 },
    { r: 255, g: 255, b: 66 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 245, b: 0 },
    { r: 255, g: 243, b: 0 },
    { r: 255, g: 240, b: 0 },
    { r: 255, g: 237, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 232, b: 0 },
    { r: 255, g: 229, b: 0 },
    { r: 255, g: 226, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 218, b: 0 },
    { r: 255, g: 215, b: 0 },
    { r: 255, g: 212, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 205, b: 0 },
    { r: 255, g: 202, b: 0 },
    { r: 255, g: 199, b: 0 },
    { r: 255, g: 196, b: 0 },
    { r: 255, g: 193, b: 0 },
    { r: 255, g: 189, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 179, b: 0 },
    { r: 255, g: 175, b: 0 },
    { r: 255, g: 171, b: 0 },
    { r: 255, g: 168, b: 0 },
    { r: 255, g: 164, b: 0 },
    { r: 255, g: 160, b: 0 },
    { r: 255, g: 156, b: 0 },
    { r: 255, g: 152, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 143, b: 0 },
    { r: 255, g: 138, b: 0 },
    { r: 255, g: 134, b: 0 },
    { r: 255, g: 129, b: 0 },
    { r: 255, g: 124, b: 0 },
    { r: 255, g: 118, b: 0 },
    { r: 255, g: 113, b: 0 },
    { r: 255, g: 107, b: 0 },
    { r: 255, g: 101, b: 0 },
    { r: 255, g: 94, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 80, b: 0 },
    { r: 255, g: 71, b: 0 },
    { r: 255, g: 62, b: 0 },
    { r: 255, g: 50, b: 0 },
    { r: 255, g: 35, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 251, g: 0, b: 0 },
    { r: 247, g: 0, b: 0 },
    { r: 243, g: 0, b: 0 },
    { r: 239, g: 0, b: 0 },
    { r: 235, g: 0, b: 0 },
    { r: 230, g: 0, b: 0 },
    { r: 226, g: 0, b: 0 },
    { r: 221, g: 0, b: 0 },
    { r: 216, g: 0, b: 0 },
    { r: 212, g: 0, b: 0 },
    { r: 207, g: 0, b: 0 },
    { r: 202, g: 0, b: 0 },
    { r: 196, g: 0, b: 0 },
    { r: 191, g: 0, b: 0 },
    { r: 186, g: 0, b: 0 },
    { r: 180, g: 0, b: 0 },
    { r: 174, g: 0, b: 0 },
    { r: 168, g: 0, b: 0 },
    { r: 162, g: 0, b: 0 },
    { r: 156, g: 0, b: 0 },
    { r: 149, g: 0, b: 0 },
    { r: 142, g: 0, b: 0 },
    { r: 135, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 252, g: 252, b: 255 },
    { r: 249, g: 249, b: 255 },
    { r: 245, g: 245, b: 255 },
    { r: 242, g: 242, b: 255 },
    { r: 239, g: 239, b: 255 },
    { r: 235, g: 235, b: 255 },
    { r: 232, g: 232, b: 255 },
    { r: 228, g: 228, b: 255 },
    { r: 225, g: 225, b: 255 },
    { r: 221, g: 221, b: 255 },
    { r: 217, g: 217, b: 255 },
    { r: 213, g: 213, b: 255 },
    { r: 210, g: 210, b: 255 },
    { r: 205, g: 205, b: 255 },
    { r: 202, g: 202, b: 255 },
    { r: 198, g: 198, b: 255 },
    { r: 193, g: 193, b: 255 },
    { r: 189, g: 189, b: 255 },
    { r: 184, g: 184, b: 255 },
    { r: 180, g: 180, b: 255 },
    { r: 176, g: 176, b: 255 },
    { r: 171, g: 171, b: 255 },
    { r: 166, g: 166, b: 255 },
    { r: 161, g: 161, b: 255 },
    { r: 156, g: 156, b: 255 },
    { r: 151, g: 151, b: 255 },
    { r: 145, g: 145, b: 255 },
    { r: 139, g: 139, b: 255 },
    { r: 134, g: 134, b: 255 },
    { r: 127, g: 127, b: 255 },
    { r: 121, g: 121, b: 255 },
    { r: 114, g: 114, b: 255 },
    { r: 106, g: 106, b: 255 },
    { r: 98, g: 98, b: 255 },
    { r: 89, g: 89, b: 255 },
    { r: 80, g: 80, b: 255 },
    { r: 69, g: 69, b: 255 },
    { r: 55, g: 55, b: 255 },
    { r: 39, g: 39, b: 255 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 0, b: 253 },
    { r: 0, g: 0, b: 250 },
    { r: 0, g: 0, b: 248 },
    { r: 0, g: 0, b: 245 },
    { r: 0, g: 0, b: 243 },
    { r: 0, g: 0, b: 240 },
    { r: 0, g: 0, b: 237 },
    { r: 0, g: 0, b: 235 },
    { r: 0, g: 0, b: 232 },
    { r: 0, g: 0, b: 229 },
    { r: 0, g: 0, b: 226 },
    { r: 0, g: 0, b: 223 },
    { r: 0, g: 0, b: 220 },
    { r: 0, g: 0, b: 218 },
    { r: 0, g: 0, b: 215 },
    { r: 0, g: 0, b: 212 },
    { r: 0, g: 0, b: 209 },
    { r: 0, g: 0, b: 205 },
    { r: 0, g: 0, b: 202 },
    { r: 0, g: 0, b: 199 },
    { r: 0, g: 0, b: 196 },
    { r: 0, g: 0, b: 193 },
    { r: 0, g: 0, b: 189 },
    { r: 0, g: 0, b: 186 },
    { r: 0, g: 0, b: 182 },
    { r: 0, g: 0, b: 179 },
    { r: 0, g: 0, b: 175 },
    { r: 0, g: 0, b: 171 },
    { r: 0, g: 0, b: 168 },
    { r: 0, g: 0, b: 164 },
    { r: 0, g: 0, b: 160 },
    { r: 0, g: 0, b: 156 },
    { r: 0, g: 0, b: 152 },
    { r: 0, g: 0, b: 147 },
    { r: 0, g: 0, b: 143 },
    { r: 0, g: 0, b: 138 },
    { r: 0, g: 0, b: 134 },
    { r: 0, g: 0, b: 129 },
    { r: 0, g: 0, b: 124 },
    { r: 0, g: 0, b: 118 },
    { r: 0, g: 0, b: 113 },
    { r: 0, g: 0, b: 107 },
    { r: 0, g: 0, b: 101 },
    { r: 0, g: 0, b: 94 },
    { r: 0, g: 0, b: 87 },
    { r: 0, g: 0, b: 80 },
    { r: 0, g: 0, b: 71 },
    { r: 0, g: 0, b: 62 },
    { r: 0, g: 0, b: 50 },
  ],
  [
    { r: 255, g: 0, b: 209 },
    { r: 242, g: 0, b: 209 },
    { r: 228, g: 0, b: 209 },
    { r: 214, g: 0, b: 209 },
    { r: 198, g: 0, b: 209 },
    { r: 181, g: 0, b: 209 },
    { r: 161, g: 0, b: 209 },
    { r: 140, g: 0, b: 209 },
    { r: 114, g: 0, b: 209 },
    { r: 80, g: 0, b: 209 },
    { r: 0, g: 0, b: 209 },
    { r: 0, g: 0, b: 213 },
    { r: 0, g: 0, b: 219 },
    { r: 0, g: 0, b: 223 },
    { r: 0, g: 0, b: 228 },
    { r: 0, g: 0, b: 233 },
    { r: 0, g: 0, b: 238 },
    { r: 0, g: 0, b: 242 },
    { r: 0, g: 0, b: 247 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 55, b: 255 },
    { r: 0, g: 78, b: 255 },
    { r: 0, g: 96, b: 255 },
    { r: 0, g: 111, b: 255 },
    { r: 0, g: 124, b: 255 },
    { r: 0, g: 136, b: 255 },
    { r: 0, g: 146, b: 255 },
    { r: 0, g: 157, b: 255 },
    { r: 0, g: 167, b: 255 },
    { r: 0, g: 176, b: 255 },
    { r: 0, g: 184, b: 255 },
    { r: 0, g: 193, b: 255 },
    { r: 0, g: 200, b: 255 },
    { r: 0, g: 208, b: 255 },
    { r: 0, g: 216, b: 255 },
    { r: 0, g: 223, b: 255 },
    { r: 0, g: 230, b: 255 },
    { r: 0, g: 236, b: 255 },
    { r: 0, g: 243, b: 255 },
    { r: 0, g: 249, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 228 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 161 },
    { r: 0, g: 255, b: 114 },
    { r: 103, g: 255, b: 0 },
    { r: 147, g: 255, b: 0 },
    { r: 180, g: 255, b: 0 },
    { r: 209, g: 255, b: 0 },
    { r: 233, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 233, b: 0 },
    { r: 255, g: 227, b: 0 },
    { r: 255, g: 221, b: 0 },
    { r: 255, g: 215, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 202, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 188, b: 0 },
    { r: 255, g: 180, b: 0 },
    { r: 255, g: 172, b: 0 },
    { r: 255, g: 165, b: 0 },
    { r: 255, g: 156, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 137, b: 0 },
    { r: 255, g: 127, b: 0 },
    { r: 255, g: 116, b: 0 },
    { r: 255, g: 103, b: 0 },
    { r: 255, g: 89, b: 0 },
    { r: 255, g: 73, b: 0 },
    { r: 255, g: 50, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 66, b: 66 },
    { r: 255, g: 93, b: 93 },
    { r: 255, g: 114, b: 114 },
    { r: 255, g: 132, b: 132 },
    { r: 255, g: 147, b: 147 },
    { r: 255, g: 161, b: 161 },
    { r: 255, g: 174, b: 174 },
    { r: 255, g: 186, b: 186 },
    { r: 255, g: 198, b: 198 },
    { r: 255, g: 209, b: 209 },
    { r: 255, g: 219, b: 219 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 238, b: 238 },
    { r: 255, g: 247, b: 247 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 66 },
    { r: 0, g: 0, b: 93 },
    { r: 0, g: 0, b: 114 },
    { r: 0, g: 0, b: 132 },
    { r: 0, g: 0, b: 147 },
    { r: 0, g: 0, b: 161 },
    { r: 0, g: 0, b: 174 },
    { r: 0, g: 0, b: 186 },
    { r: 0, g: 0, b: 198 },
    { r: 0, g: 0, b: 209 },
    { r: 0, g: 0, b: 219 },
    { r: 0, g: 0, b: 228 },
    { r: 0, g: 0, b: 238 },
    { r: 0, g: 0, b: 247 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 66, b: 255 },
    { r: 0, g: 93, b: 255 },
    { r: 0, g: 114, b: 255 },
    { r: 0, g: 132, b: 255 },
    { r: 0, g: 147, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 186, b: 255 },
    { r: 0, g: 198, b: 255 },
    { r: 0, g: 209, b: 255 },
    { r: 0, g: 219, b: 255 },
    { r: 0, g: 228, b: 255 },
    { r: 0, g: 238, b: 255 },
    { r: 0, g: 247, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 247 },
    { r: 0, g: 255, b: 238 },
    { r: 0, g: 255, b: 228 },
    { r: 0, g: 255, b: 219 },
    { r: 0, g: 255, b: 209 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 186 },
    { r: 0, g: 255, b: 174 },
    { r: 0, g: 255, b: 161 },
    { r: 0, g: 255, b: 147 },
    { r: 0, g: 255, b: 132 },
    { r: 0, g: 255, b: 114 },
    { r: 0, g: 255, b: 93 },
    { r: 0, g: 255, b: 66 },
    { r: 0, g: 255, b: 0 },
    { r: 66, g: 255, b: 0 },
    { r: 93, g: 255, b: 0 },
    { r: 114, g: 255, b: 0 },
    { r: 132, g: 255, b: 0 },
    { r: 147, g: 255, b: 0 },
    { r: 161, g: 255, b: 0 },
    { r: 174, g: 255, b: 0 },
    { r: 186, g: 255, b: 0 },
    { r: 198, g: 255, b: 0 },
    { r: 209, g: 255, b: 0 },
    { r: 219, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 238, g: 255, b: 0 },
    { r: 247, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 114, b: 0 },
    { r: 255, g: 93, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 66, b: 66 },
    { r: 255, g: 93, b: 93 },
    { r: 255, g: 114, b: 114 },
    { r: 255, g: 132, b: 132 },
    { r: 255, g: 147, b: 147 },
    { r: 255, g: 161, b: 161 },
    { r: 255, g: 174, b: 174 },
    { r: 255, g: 186, b: 186 },
    { r: 255, g: 198, b: 198 },
    { r: 255, g: 209, b: 209 },
    { r: 255, g: 219, b: 219 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 238, b: 238 },
    { r: 255, g: 247, b: 247 },
  ],
  [
    { r: 255, g: 0, b: 255 },
    { r: 247, g: 0, b: 255 },
    { r: 238, g: 0, b: 255 },
    { r: 228, g: 0, b: 255 },
    { r: 219, g: 0, b: 255 },
    { r: 209, g: 0, b: 255 },
    { r: 198, g: 0, b: 255 },
    { r: 186, g: 0, b: 255 },
    { r: 174, g: 0, b: 255 },
    { r: 161, g: 0, b: 255 },
    { r: 147, g: 0, b: 255 },
    { r: 132, g: 0, b: 255 },
    { r: 114, g: 0, b: 255 },
    { r: 93, g: 0, b: 255 },
    { r: 66, g: 0, b: 255 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 66, b: 255 },
    { r: 0, g: 93, b: 255 },
    { r: 0, g: 114, b: 255 },
    { r: 0, g: 132, b: 255 },
    { r: 0, g: 147, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 186, b: 255 },
    { r: 0, g: 198, b: 255 },
    { r: 0, g: 209, b: 255 },
    { r: 0, g: 219, b: 255 },
    { r: 0, g: 228, b: 255 },
    { r: 0, g: 238, b: 255 },
    { r: 0, g: 247, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 247 },
    { r: 0, g: 255, b: 238 },
    { r: 0, g: 255, b: 228 },
    { r: 0, g: 255, b: 219 },
    { r: 0, g: 255, b: 209 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 186 },
    { r: 0, g: 255, b: 174 },
    { r: 0, g: 255, b: 161 },
    { r: 0, g: 255, b: 147 },
    { r: 0, g: 255, b: 132 },
    { r: 0, g: 255, b: 114 },
    { r: 0, g: 255, b: 93 },
    { r: 0, g: 255, b: 66 },
    { r: 0, g: 255, b: 0 },
    { r: 66, g: 255, b: 0 },
    { r: 93, g: 255, b: 0 },
    { r: 114, g: 255, b: 0 },
    { r: 132, g: 255, b: 0 },
    { r: 147, g: 255, b: 0 },
    { r: 161, g: 255, b: 0 },
    { r: 174, g: 255, b: 0 },
    { r: 186, g: 255, b: 0 },
    { r: 198, g: 255, b: 0 },
    { r: 209, g: 255, b: 0 },
    { r: 219, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 238, g: 255, b: 0 },
    { r: 247, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 114, b: 0 },
    { r: 255, g: 93, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 66 },
    { r: 255, g: 0, b: 93 },
    { r: 255, g: 0, b: 114 },
    { r: 255, g: 0, b: 132 },
    { r: 255, g: 0, b: 147 },
    { r: 255, g: 0, b: 161 },
    { r: 255, g: 0, b: 174 },
    { r: 255, g: 0, b: 186 },
    { r: 255, g: 0, b: 198 },
    { r: 255, g: 0, b: 209 },
    { r: 255, g: 0, b: 219 },
    { r: 255, g: 0, b: 228 },
    { r: 255, g: 0, b: 238 },
    { r: 255, g: 0, b: 247 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 109, g: 0, b: 122 },
    { r: 122, g: 0, b: 134 },
    { r: 134, g: 0, b: 144 },
    { r: 144, g: 0, b: 144 },
    { r: 154, g: 0, b: 154 },
    { r: 164, g: 0, b: 164 },
    { r: 173, g: 0, b: 173 },
    { r: 181, g: 0, b: 181 },
    { r: 189, g: 0, b: 189 },
    { r: 197, g: 0, b: 197 },
    { r: 204, g: 0, b: 204 },
    { r: 197, g: 0, b: 212 },
    { r: 189, g: 0, b: 219 },
    { r: 181, g: 0, b: 225 },
    { r: 173, g: 0, b: 232 },
    { r: 164, g: 0, b: 238 },
    { r: 154, g: 0, b: 244 },
    { r: 144, g: 0, b: 250 },
    { r: 134, g: 0, b: 255 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 158, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 186, b: 255 },
    { r: 0, g: 195, b: 255 },
    { r: 0, g: 203, b: 255 },
    { r: 0, g: 210, b: 255 },
    { r: 0, g: 217, b: 255 },
    { r: 0, g: 224, b: 255 },
    { r: 0, g: 228, b: 255 },
    { r: 0, g: 236, b: 255 },
    { r: 0, g: 241, b: 255 },
    { r: 0, g: 246, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 245 },
    { r: 0, g: 255, b: 238 },
    { r: 0, g: 255, b: 231 },
    { r: 0, g: 255, b: 224 },
    { r: 0, g: 255, b: 219 },
    { r: 0, g: 255, b: 212 },
    { r: 0, g: 255, b: 205 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 188 },
    { r: 0, g: 255, b: 177 },
    { r: 0, g: 255, b: 162 },
    { r: 165, g: 255, b: 0 },
    { r: 182, g: 255, b: 0 },
    { r: 191, g: 255, b: 0 },
    { r: 200, g: 255, b: 0 },
    { r: 208, g: 255, b: 0 },
    { r: 215, g: 255, b: 0 },
    { r: 221, g: 255, b: 0 },
    { r: 226, g: 255, b: 0 },
    { r: 231, g: 255, b: 0 },
    { r: 236, g: 255, b: 0 },
    { r: 241, g: 255, b: 0 },
    { r: 246, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 246, b: 0 },
    { r: 255, g: 242, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 233, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 217, b: 0 },
    { r: 255, g: 210, b: 0 },
    { r: 255, g: 203, b: 0 },
    { r: 255, g: 193, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 168, b: 0 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 131, b: 0 },
    { r: 255, g: 110, b: 0 },
    { r: 255, g: 78, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 138, b: 138 },
    { r: 255, g: 155, b: 155 },
    { r: 255, g: 170, b: 170 },
    { r: 255, g: 184, b: 190 },
    { r: 255, g: 184, b: 205 },
    { r: 255, g: 184, b: 219 },
    { r: 255, g: 184, b: 232 },
    { r: 255, g: 184, b: 245 },
    { r: 255, g: 189, b: 255 },
    { r: 255, g: 202, b: 255 },
    { r: 255, g: 215, b: 255 },
    { r: 255, g: 229, b: 255 },
    { r: 255, g: 239, b: 255 },
    { r: 255, g: 248, b: 255 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 38, g: 38, b: 255 },
    { r: 53, g: 53, b: 255 },
    { r: 66, g: 66, b: 255 },
    { r: 76, g: 76, b: 255 },
    { r: 85, g: 85, b: 255 },
    { r: 93, g: 93, b: 255 },
    { r: 100, g: 100, b: 255 },
    { r: 107, g: 107, b: 255 },
    { r: 114, g: 114, b: 255 },
    { r: 120, g: 120, b: 255 },
    { r: 126, g: 126, b: 255 },
    { r: 132, g: 132, b: 255 },
    { r: 137, g: 137, b: 255 },
    { r: 142, g: 142, b: 255 },
    { r: 147, g: 147, b: 255 },
    { r: 152, g: 152, b: 255 },
    { r: 157, g: 157, b: 255 },
    { r: 161, g: 161, b: 255 },
    { r: 166, g: 166, b: 255 },
    { r: 170, g: 170, b: 255 },
    { r: 174, g: 174, b: 255 },
    { r: 178, g: 178, b: 255 },
    { r: 183, g: 183, b: 255 },
    { r: 186, g: 186, b: 255 },
    { r: 190, g: 190, b: 255 },
    { r: 194, g: 194, b: 255 },
    { r: 198, g: 198, b: 255 },
    { r: 201, g: 201, b: 255 },
    { r: 205, g: 205, b: 255 },
    { r: 209, g: 209, b: 255 },
    { r: 212, g: 212, b: 255 },
    { r: 215, g: 215, b: 255 },
    { r: 219, g: 219, b: 255 },
    { r: 222, g: 222, b: 255 },
    { r: 225, g: 225, b: 255 },
    { r: 228, g: 228, b: 255 },
    { r: 232, g: 232, b: 255 },
    { r: 235, g: 235, b: 255 },
    { r: 238, g: 238, b: 255 },
    { r: 241, g: 241, b: 255 },
    { r: 244, g: 244, b: 255 },
    { r: 247, g: 247, b: 255 },
    { r: 250, g: 250, b: 255 },
    { r: 253, g: 253, b: 255 },
    { r: 255, g: 253, b: 253 },
    { r: 255, g: 250, b: 250 },
    { r: 255, g: 247, b: 247 },
    { r: 255, g: 244, b: 244 },
    { r: 255, g: 241, b: 241 },
    { r: 255, g: 238, b: 238 },
    { r: 255, g: 235, b: 235 },
    { r: 255, g: 232, b: 232 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 225, b: 225 },
    { r: 255, g: 222, b: 222 },
    { r: 255, g: 219, b: 219 },
    { r: 255, g: 215, b: 215 },
    { r: 255, g: 212, b: 212 },
    { r: 255, g: 209, b: 209 },
    { r: 255, g: 205, b: 205 },
    { r: 255, g: 201, b: 201 },
    { r: 255, g: 198, b: 198 },
    { r: 255, g: 194, b: 194 },
    { r: 255, g: 190, b: 190 },
    { r: 255, g: 186, b: 186 },
    { r: 255, g: 183, b: 183 },
    { r: 255, g: 178, b: 178 },
    { r: 255, g: 174, b: 174 },
    { r: 255, g: 170, b: 170 },
    { r: 255, g: 166, b: 166 },
    { r: 255, g: 161, b: 161 },
    { r: 255, g: 157, b: 157 },
    { r: 255, g: 152, b: 152 },
    { r: 255, g: 147, b: 147 },
    { r: 255, g: 142, b: 142 },
    { r: 255, g: 137, b: 137 },
    { r: 255, g: 132, b: 132 },
    { r: 255, g: 126, b: 126 },
    { r: 255, g: 120, b: 120 },
    { r: 255, g: 114, b: 114 },
    { r: 255, g: 107, b: 107 },
    { r: 255, g: 100, b: 100 },
    { r: 255, g: 93, b: 93 },
    { r: 255, g: 85, b: 85 },
    { r: 255, g: 76, b: 76 },
    { r: 255, g: 66, b: 66 },
    { r: 255, g: 53, b: 53 },
    { r: 255, g: 38, b: 38 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 181 },
    { r: 0, g: 0, b: 188 },
    { r: 0, g: 0, b: 196 },
    { r: 0, g: 0, b: 203 },
    { r: 0, g: 0, b: 211 },
    { r: 0, g: 0, b: 217 },
    { r: 0, g: 0, b: 224 },
    { r: 0, g: 0, b: 231 },
    { r: 0, g: 0, b: 237 },
    { r: 0, g: 0, b: 243 },
    { r: 0, g: 0, b: 249 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 50, b: 255 },
    { r: 0, g: 74, b: 255 },
    { r: 0, g: 92, b: 255 },
    { r: 0, g: 106, b: 255 },
    { r: 0, g: 119, b: 255 },
    { r: 0, g: 131, b: 255 },
    { r: 0, g: 142, b: 255 },
    { r: 0, g: 152, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 170, b: 255 },
    { r: 0, g: 178, b: 255 },
    { r: 0, g: 187, b: 255 },
    { r: 0, g: 194, b: 255 },
    { r: 0, g: 202, b: 255 },
    { r: 0, g: 209, b: 255 },
    { r: 0, g: 216, b: 255 },
    { r: 0, g: 222, b: 255 },
    { r: 0, g: 229, b: 255 },
    { r: 0, g: 235, b: 255 },
    { r: 0, g: 241, b: 255 },
    { r: 0, g: 247, b: 255 },
    { r: 0, g: 253, b: 255 },
    { r: 42, g: 255, b: 252 },
    { r: 69, g: 255, b: 246 },
    { r: 87, g: 255, b: 240 },
    { r: 103, g: 255, b: 234 },
    { r: 116, g: 255, b: 227 },
    { r: 128, g: 255, b: 221 },
    { r: 139, g: 255, b: 214 },
    { r: 149, g: 255, b: 207 },
    { r: 159, g: 255, b: 200 },
    { r: 168, g: 255, b: 192 },
    { r: 176, g: 255, b: 185 },
    { r: 185, g: 255, b: 176 },
    { r: 192, g: 255, b: 168 },
    { r: 200, g: 255, b: 159 },
    { r: 207, g: 255, b: 149 },
    { r: 214, g: 255, b: 139 },
    { r: 221, g: 255, b: 128 },
    { r: 227, g: 255, b: 116 },
    { r: 234, g: 255, b: 103 },
    { r: 240, g: 255, b: 87 },
    { r: 246, g: 255, b: 69 },
    { r: 252, g: 255, b: 42 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 229, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 202, b: 0 },
    { r: 255, g: 194, b: 0 },
    { r: 255, g: 187, b: 0 },
    { r: 255, g: 178, b: 0 },
    { r: 255, g: 170, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 152, b: 0 },
    { r: 255, g: 142, b: 0 },
    { r: 255, g: 131, b: 0 },
    { r: 255, g: 119, b: 0 },
    { r: 255, g: 106, b: 0 },
    { r: 255, g: 92, b: 0 },
    { r: 255, g: 74, b: 0 },
    { r: 255, g: 50, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 249, g: 0, b: 0 },
    { r: 243, g: 0, b: 0 },
    { r: 237, g: 0, b: 0 },
    { r: 231, g: 0, b: 0 },
    { r: 224, g: 0, b: 0 },
    { r: 217, g: 0, b: 0 },
    { r: 211, g: 0, b: 0 },
    { r: 203, g: 0, b: 0 },
    { r: 196, g: 0, b: 0 },
    { r: 188, g: 0, b: 0 },
    { r: 181, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 94, b: 0 },
    { r: 255, g: 115, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 175, b: 0 },
    { r: 255, g: 188, b: 0 },
    { r: 255, g: 199, b: 0 },
    { r: 255, g: 210, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 230, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 254, g: 255, b: 0 },
    { r: 245, g: 255, b: 0 },
    { r: 236, g: 255, b: 0 },
    { r: 227, g: 255, b: 0 },
    { r: 217, g: 255, b: 0 },
    { r: 206, g: 255, b: 0 },
    { r: 195, g: 255, b: 0 },
    { r: 184, g: 255, b: 0 },
    { r: 171, g: 255, b: 0 },
    { r: 158, g: 255, b: 0 },
    { r: 143, g: 255, b: 0 },
    { r: 127, g: 255, b: 0 },
    { r: 108, g: 255, b: 0 },
    { r: 85, g: 255, b: 0 },
    { r: 54, g: 255, b: 0 },
    { r: 0, g: 255, b: 38 },
    { r: 0, g: 255, b: 76 },
    { r: 0, g: 255, b: 101 },
    { r: 0, g: 255, b: 121 },
    { r: 0, g: 255, b: 138 },
    { r: 0, g: 255, b: 153 },
    { r: 0, g: 255, b: 167 },
    { r: 0, g: 255, b: 179 },
    { r: 0, g: 255, b: 191 },
    { r: 0, g: 255, b: 203 },
    { r: 0, g: 255, b: 213 },
    { r: 0, g: 255, b: 223 },
    { r: 0, g: 255, b: 233 },
    { r: 0, g: 255, b: 242 },
    { r: 0, g: 255, b: 251 },
    { r: 0, g: 251, b: 255 },
    { r: 0, g: 242, b: 255 },
    { r: 0, g: 233, b: 255 },
    { r: 0, g: 223, b: 255 },
    { r: 0, g: 213, b: 255 },
    { r: 0, g: 203, b: 255 },
    { r: 0, g: 191, b: 255 },
    { r: 0, g: 179, b: 255 },
    { r: 0, g: 167, b: 255 },
    { r: 0, g: 153, b: 255 },
    { r: 0, g: 138, b: 255 },
    { r: 0, g: 121, b: 255 },
    { r: 0, g: 101, b: 255 },
    { r: 0, g: 76, b: 255 },
    { r: 0, g: 38, b: 255 },
    { r: 54, g: 0, b: 255 },
    { r: 85, g: 0, b: 255 },
    { r: 108, g: 0, b: 255 },
    { r: 127, g: 0, b: 255 },
    { r: 143, g: 0, b: 255 },
    { r: 158, g: 0, b: 255 },
    { r: 171, g: 0, b: 255 },
    { r: 184, g: 0, b: 255 },
    { r: 195, g: 0, b: 255 },
    { r: 206, g: 0, b: 255 },
    { r: 217, g: 0, b: 255 },
    { r: 227, g: 0, b: 255 },
    { r: 236, g: 0, b: 255 },
    { r: 245, g: 0, b: 255 },
    { r: 254, g: 0, b: 255 },
    { r: 255, g: 0, b: 248 },
    { r: 255, g: 0, b: 239 },
    { r: 255, g: 0, b: 230 },
    { r: 255, g: 0, b: 220 },
    { r: 255, g: 0, b: 210 },
    { r: 255, g: 0, b: 199 },
    { r: 255, g: 0, b: 188 },
    { r: 255, g: 0, b: 175 },
    { r: 255, g: 0, b: 162 },
    { r: 255, g: 0, b: 148 },
    { r: 255, g: 0, b: 132 },
    { r: 255, g: 0, b: 115 },
    { r: 255, g: 0, b: 94 },
    { r: 255, g: 0, b: 66 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 42, g: 0, b: 0 },
    { r: 60, g: 0, b: 0 },
    { r: 74, g: 0, b: 0 },
    { r: 85, g: 0, b: 0 },
    { r: 95, g: 0, b: 0 },
    { r: 105, g: 0, b: 0 },
    { r: 113, g: 0, b: 0 },
    { r: 121, g: 0, b: 0 },
    { r: 128, g: 0, b: 0 },
    { r: 135, g: 0, b: 0 },
    { r: 142, g: 0, b: 0 },
    { r: 148, g: 0, b: 0 },
    { r: 154, g: 0, b: 0 },
    { r: 160, g: 0, b: 0 },
    { r: 166, g: 0, b: 0 },
    { r: 171, g: 0, b: 0 },
    { r: 176, g: 0, b: 0 },
    { r: 182, g: 0, b: 0 },
    { r: 187, g: 0, b: 0 },
    { r: 191, g: 0, b: 0 },
    { r: 196, g: 0, b: 0 },
    { r: 201, g: 0, b: 0 },
    { r: 205, g: 0, b: 0 },
    { r: 210, g: 0, b: 0 },
    { r: 214, g: 0, b: 0 },
    { r: 218, g: 0, b: 0 },
    { r: 222, g: 0, b: 0 },
    { r: 227, g: 0, b: 0 },
    { r: 231, g: 0, b: 0 },
    { r: 235, g: 0, b: 0 },
    { r: 238, g: 0, b: 0 },
    { r: 242, g: 0, b: 0 },
    { r: 246, g: 0, b: 0 },
    { r: 250, g: 0, b: 0 },
    { r: 253, g: 0, b: 0 },
    { r: 255, g: 27, b: 0 },
    { r: 255, g: 50, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 79, b: 0 },
    { r: 255, g: 90, b: 0 },
    { r: 255, g: 99, b: 0 },
    { r: 255, g: 108, b: 0 },
    { r: 255, g: 116, b: 0 },
    { r: 255, g: 124, b: 0 },
    { r: 255, g: 131, b: 0 },
    { r: 255, g: 138, b: 0 },
    { r: 255, g: 144, b: 0 },
    { r: 255, g: 151, b: 0 },
    { r: 255, g: 157, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 168, b: 0 },
    { r: 255, g: 173, b: 0 },
    { r: 255, g: 178, b: 0 },
    { r: 255, g: 184, b: 0 },
    { r: 255, g: 188, b: 0 },
    { r: 255, g: 193, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 203, b: 0 },
    { r: 255, g: 207, b: 0 },
    { r: 255, g: 211, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 224, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 232, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 240, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 251, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 255, b: 54 },
    { r: 255, g: 255, b: 81 },
    { r: 255, g: 255, b: 101 },
    { r: 255, g: 255, b: 118 },
    { r: 255, g: 255, b: 132 },
    { r: 255, g: 255, b: 146 },
    { r: 255, g: 255, b: 158 },
    { r: 255, g: 255, b: 169 },
    { r: 255, g: 255, b: 179 },
    { r: 255, g: 255, b: 189 },
    { r: 255, g: 255, b: 199 },
    { r: 255, g: 255, b: 208 },
    { r: 255, g: 255, b: 217 },
    { r: 255, g: 255, b: 225 },
    { r: 255, g: 255, b: 233 },
    { r: 255, g: 255, b: 241 },
    { r: 255, g: 255, b: 248 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 255, b: 255 },
    { r: 27, g: 254, b: 255 },
    { r: 38, g: 253, b: 255 },
    { r: 47, g: 251, b: 255 },
    { r: 54, g: 250, b: 255 },
    { r: 60, g: 248, b: 255 },
    { r: 66, g: 247, b: 255 },
    { r: 71, g: 245, b: 255 },
    { r: 76, g: 244, b: 255 },
    { r: 81, g: 242, b: 255 },
    { r: 85, g: 241, b: 255 },
    { r: 90, g: 239, b: 255 },
    { r: 94, g: 238, b: 255 },
    { r: 97, g: 236, b: 255 },
    { r: 101, g: 235, b: 255 },
    { r: 105, g: 233, b: 255 },
    { r: 108, g: 231, b: 255 },
    { r: 111, g: 230, b: 255 },
    { r: 115, g: 228, b: 255 },
    { r: 118, g: 227, b: 255 },
    { r: 121, g: 225, b: 255 },
    { r: 124, g: 223, b: 255 },
    { r: 127, g: 222, b: 255 },
    { r: 130, g: 220, b: 255 },
    { r: 132, g: 218, b: 255 },
    { r: 135, g: 217, b: 255 },
    { r: 138, g: 215, b: 255 },
    { r: 141, g: 213, b: 255 },
    { r: 143, g: 211, b: 255 },
    { r: 146, g: 210, b: 255 },
    { r: 148, g: 208, b: 255 },
    { r: 151, g: 206, b: 255 },
    { r: 153, g: 204, b: 255 },
    { r: 155, g: 203, b: 255 },
    { r: 158, g: 201, b: 255 },
    { r: 160, g: 199, b: 255 },
    { r: 162, g: 197, b: 255 },
    { r: 165, g: 195, b: 255 },
    { r: 167, g: 193, b: 255 },
    { r: 169, g: 191, b: 255 },
    { r: 171, g: 189, b: 255 },
    { r: 173, g: 188, b: 255 },
    { r: 175, g: 186, b: 255 },
    { r: 177, g: 184, b: 255 },
    { r: 179, g: 182, b: 255 },
    { r: 182, g: 179, b: 255 },
    { r: 184, g: 177, b: 255 },
    { r: 186, g: 175, b: 255 },
    { r: 188, g: 173, b: 255 },
    { r: 189, g: 171, b: 255 },
    { r: 191, g: 169, b: 255 },
    { r: 193, g: 167, b: 255 },
    { r: 195, g: 165, b: 255 },
    { r: 197, g: 162, b: 255 },
    { r: 199, g: 160, b: 255 },
    { r: 201, g: 158, b: 255 },
    { r: 203, g: 155, b: 255 },
    { r: 204, g: 153, b: 255 },
    { r: 206, g: 151, b: 255 },
    { r: 208, g: 148, b: 255 },
    { r: 210, g: 146, b: 255 },
    { r: 211, g: 143, b: 255 },
    { r: 213, g: 141, b: 255 },
    { r: 215, g: 138, b: 255 },
    { r: 217, g: 135, b: 255 },
    { r: 218, g: 132, b: 255 },
    { r: 220, g: 130, b: 255 },
    { r: 222, g: 127, b: 255 },
    { r: 223, g: 124, b: 255 },
    { r: 225, g: 121, b: 255 },
    { r: 227, g: 118, b: 255 },
    { r: 228, g: 115, b: 255 },
    { r: 230, g: 111, b: 255 },
    { r: 231, g: 108, b: 255 },
    { r: 233, g: 105, b: 255 },
    { r: 235, g: 101, b: 255 },
    { r: 236, g: 97, b: 255 },
    { r: 238, g: 94, b: 255 },
    { r: 239, g: 90, b: 255 },
    { r: 241, g: 85, b: 255 },
    { r: 242, g: 81, b: 255 },
    { r: 244, g: 76, b: 255 },
    { r: 245, g: 71, b: 255 },
    { r: 247, g: 66, b: 255 },
    { r: 248, g: 60, b: 255 },
    { r: 250, g: 54, b: 255 },
    { r: 251, g: 47, b: 255 },
    { r: 253, g: 38, b: 255 },
    { r: 254, g: 27, b: 255 },
    { r: 255, g: 0, b: 255 },
  ],
  [
    { r: 255, g: 0, b: 255 },
    { r: 255, g: 27, b: 254 },
    { r: 255, g: 38, b: 253 },
    { r: 255, g: 47, b: 251 },
    { r: 255, g: 54, b: 250 },
    { r: 255, g: 60, b: 248 },
    { r: 255, g: 66, b: 247 },
    { r: 255, g: 71, b: 245 },
    { r: 255, g: 76, b: 244 },
    { r: 255, g: 81, b: 242 },
    { r: 255, g: 85, b: 241 },
    { r: 255, g: 90, b: 239 },
    { r: 255, g: 94, b: 238 },
    { r: 255, g: 97, b: 236 },
    { r: 255, g: 101, b: 235 },
    { r: 255, g: 105, b: 233 },
    { r: 255, g: 108, b: 231 },
    { r: 255, g: 111, b: 230 },
    { r: 255, g: 115, b: 228 },
    { r: 255, g: 118, b: 227 },
    { r: 255, g: 121, b: 225 },
    { r: 255, g: 124, b: 223 },
    { r: 255, g: 127, b: 222 },
    { r: 255, g: 130, b: 220 },
    { r: 255, g: 132, b: 218 },
    { r: 255, g: 135, b: 217 },
    { r: 255, g: 138, b: 215 },
    { r: 255, g: 141, b: 213 },
    { r: 255, g: 143, b: 211 },
    { r: 255, g: 146, b: 210 },
    { r: 255, g: 148, b: 208 },
    { r: 255, g: 151, b: 206 },
    { r: 255, g: 153, b: 204 },
    { r: 255, g: 155, b: 203 },
    { r: 255, g: 158, b: 201 },
    { r: 255, g: 160, b: 199 },
    { r: 255, g: 162, b: 197 },
    { r: 255, g: 165, b: 195 },
    { r: 255, g: 167, b: 193 },
    { r: 255, g: 169, b: 191 },
    { r: 255, g: 171, b: 189 },
    { r: 255, g: 173, b: 188 },
    { r: 255, g: 175, b: 186 },
    { r: 255, g: 177, b: 184 },
    { r: 255, g: 179, b: 182 },
    { r: 255, g: 182, b: 179 },
    { r: 255, g: 184, b: 177 },
    { r: 255, g: 186, b: 175 },
    { r: 255, g: 188, b: 173 },
    { r: 255, g: 189, b: 171 },
    { r: 255, g: 191, b: 169 },
    { r: 255, g: 193, b: 167 },
    { r: 255, g: 195, b: 165 },
    { r: 255, g: 197, b: 162 },
    { r: 255, g: 199, b: 160 },
    { r: 255, g: 201, b: 158 },
    { r: 255, g: 203, b: 155 },
    { r: 255, g: 204, b: 153 },
    { r: 255, g: 206, b: 151 },
    { r: 255, g: 208, b: 148 },
    { r: 255, g: 210, b: 146 },
    { r: 255, g: 211, b: 143 },
    { r: 255, g: 213, b: 141 },
    { r: 255, g: 215, b: 138 },
    { r: 255, g: 217, b: 135 },
    { r: 255, g: 218, b: 132 },
    { r: 255, g: 220, b: 130 },
    { r: 255, g: 222, b: 127 },
    { r: 255, g: 223, b: 124 },
    { r: 255, g: 225, b: 121 },
    { r: 255, g: 227, b: 118 },
    { r: 255, g: 228, b: 115 },
    { r: 255, g: 230, b: 111 },
    { r: 255, g: 231, b: 108 },
    { r: 255, g: 233, b: 105 },
    { r: 255, g: 235, b: 101 },
    { r: 255, g: 236, b: 97 },
    { r: 255, g: 238, b: 94 },
    { r: 255, g: 239, b: 90 },
    { r: 255, g: 241, b: 85 },
    { r: 255, g: 242, b: 81 },
    { r: 255, g: 244, b: 76 },
    { r: 255, g: 245, b: 71 },
    { r: 255, g: 247, b: 66 },
    { r: 255, g: 248, b: 60 },
    { r: 255, g: 250, b: 54 },
    { r: 255, g: 251, b: 47 },
    { r: 255, g: 253, b: 38 },
    { r: 255, g: 254, b: 27 },
    { r: 255, g: 255, b: 0 },
  ],
  [
    { r: 0, g: 181, b: 161 },
    { r: 27, g: 182, b: 161 },
    { r: 38, g: 183, b: 161 },
    { r: 47, g: 184, b: 161 },
    { r: 54, g: 185, b: 161 },
    { r: 60, g: 186, b: 161 },
    { r: 66, g: 187, b: 161 },
    { r: 71, g: 188, b: 161 },
    { r: 76, g: 188, b: 161 },
    { r: 81, g: 189, b: 161 },
    { r: 85, g: 190, b: 161 },
    { r: 90, g: 191, b: 161 },
    { r: 94, g: 192, b: 161 },
    { r: 97, g: 193, b: 161 },
    { r: 101, g: 194, b: 161 },
    { r: 105, g: 195, b: 161 },
    { r: 108, g: 196, b: 161 },
    { r: 111, g: 197, b: 161 },
    { r: 115, g: 198, b: 161 },
    { r: 118, g: 199, b: 161 },
    { r: 121, g: 200, b: 161 },
    { r: 124, g: 201, b: 161 },
    { r: 127, g: 202, b: 161 },
    { r: 130, g: 203, b: 161 },
    { r: 132, g: 203, b: 161 },
    { r: 135, g: 204, b: 161 },
    { r: 138, g: 205, b: 161 },
    { r: 141, g: 206, b: 161 },
    { r: 143, g: 207, b: 161 },
    { r: 146, g: 208, b: 161 },
    { r: 148, g: 209, b: 161 },
    { r: 151, g: 210, b: 161 },
    { r: 153, g: 211, b: 161 },
    { r: 155, g: 211, b: 161 },
    { r: 158, g: 212, b: 161 },
    { r: 160, g: 213, b: 161 },
    { r: 162, g: 214, b: 161 },
    { r: 165, g: 215, b: 161 },
    { r: 167, g: 216, b: 161 },
    { r: 169, g: 217, b: 161 },
    { r: 171, g: 217, b: 161 },
    { r: 173, g: 218, b: 161 },
    { r: 175, g: 219, b: 161 },
    { r: 177, g: 220, b: 161 },
    { r: 179, g: 221, b: 161 },
    { r: 182, g: 222, b: 161 },
    { r: 184, g: 222, b: 161 },
    { r: 186, g: 223, b: 161 },
    { r: 188, g: 224, b: 161 },
    { r: 189, g: 225, b: 161 },
    { r: 191, g: 226, b: 161 },
    { r: 193, g: 227, b: 161 },
    { r: 195, g: 227, b: 161 },
    { r: 197, g: 228, b: 161 },
    { r: 199, g: 229, b: 161 },
    { r: 201, g: 230, b: 161 },
    { r: 203, g: 231, b: 161 },
    { r: 204, g: 231, b: 161 },
    { r: 206, g: 232, b: 161 },
    { r: 208, g: 233, b: 161 },
    { r: 210, g: 234, b: 161 },
    { r: 211, g: 235, b: 161 },
    { r: 213, g: 235, b: 161 },
    { r: 215, g: 236, b: 161 },
    { r: 217, g: 237, b: 161 },
    { r: 218, g: 238, b: 161 },
    { r: 220, g: 238, b: 161 },
    { r: 222, g: 239, b: 161 },
    { r: 223, g: 240, b: 161 },
    { r: 225, g: 241, b: 161 },
    { r: 227, g: 241, b: 161 },
    { r: 228, g: 242, b: 161 },
    { r: 230, g: 243, b: 161 },
    { r: 231, g: 244, b: 161 },
    { r: 233, g: 244, b: 161 },
    { r: 235, g: 245, b: 161 },
    { r: 236, g: 246, b: 161 },
    { r: 238, g: 247, b: 161 },
    { r: 239, g: 247, b: 161 },
    { r: 241, g: 248, b: 161 },
    { r: 242, g: 249, b: 161 },
    { r: 244, g: 250, b: 161 },
    { r: 245, g: 250, b: 161 },
    { r: 247, g: 251, b: 161 },
    { r: 248, g: 252, b: 161 },
    { r: 250, g: 253, b: 161 },
    { r: 251, g: 253, b: 161 },
    { r: 253, g: 254, b: 161 },
    { r: 254, g: 255, b: 161 },
    { r: 255, g: 255, b: 161 },
  ],
  [
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 27, b: 0 },
    { r: 255, g: 38, b: 0 },
    { r: 255, g: 47, b: 0 },
    { r: 255, g: 54, b: 0 },
    { r: 255, g: 60, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 71, b: 0 },
    { r: 255, g: 76, b: 0 },
    { r: 255, g: 81, b: 0 },
    { r: 255, g: 85, b: 0 },
    { r: 255, g: 90, b: 0 },
    { r: 255, g: 94, b: 0 },
    { r: 255, g: 97, b: 0 },
    { r: 255, g: 101, b: 0 },
    { r: 255, g: 105, b: 0 },
    { r: 255, g: 108, b: 0 },
    { r: 255, g: 111, b: 0 },
    { r: 255, g: 115, b: 0 },
    { r: 255, g: 118, b: 0 },
    { r: 255, g: 121, b: 0 },
    { r: 255, g: 124, b: 0 },
    { r: 255, g: 127, b: 0 },
    { r: 255, g: 130, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 135, b: 0 },
    { r: 255, g: 138, b: 0 },
    { r: 255, g: 141, b: 0 },
    { r: 255, g: 143, b: 0 },
    { r: 255, g: 146, b: 0 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 151, b: 0 },
    { r: 255, g: 153, b: 0 },
    { r: 255, g: 155, b: 0 },
    { r: 255, g: 158, b: 0 },
    { r: 255, g: 160, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 165, b: 0 },
    { r: 255, g: 167, b: 0 },
    { r: 255, g: 169, b: 0 },
    { r: 255, g: 171, b: 0 },
    { r: 255, g: 173, b: 0 },
    { r: 255, g: 175, b: 0 },
    { r: 255, g: 177, b: 0 },
    { r: 255, g: 179, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 184, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 188, b: 0 },
    { r: 255, g: 189, b: 0 },
    { r: 255, g: 191, b: 0 },
    { r: 255, g: 193, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 197, b: 0 },
    { r: 255, g: 199, b: 0 },
    { r: 255, g: 201, b: 0 },
    { r: 255, g: 203, b: 0 },
    { r: 255, g: 204, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 208, b: 0 },
    { r: 255, g: 210, b: 0 },
    { r: 255, g: 211, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 215, b: 0 },
    { r: 255, g: 217, b: 0 },
    { r: 255, g: 218, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 225, b: 0 },
    { r: 255, g: 227, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 230, b: 0 },
    { r: 255, g: 231, b: 0 },
    { r: 255, g: 233, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 242, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 245, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 251, b: 0 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 254, b: 0 },
    { r: 255, g: 255, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 27, b: 255 },
    { r: 0, g: 38, b: 254 },
    { r: 0, g: 47, b: 253 },
    { r: 0, g: 54, b: 253 },
    { r: 0, g: 60, b: 252 },
    { r: 0, g: 66, b: 251 },
    { r: 0, g: 71, b: 250 },
    { r: 0, g: 76, b: 250 },
    { r: 0, g: 81, b: 249 },
    { r: 0, g: 85, b: 248 },
    { r: 0, g: 90, b: 247 },
    { r: 0, g: 94, b: 247 },
    { r: 0, g: 97, b: 246 },
    { r: 0, g: 101, b: 245 },
    { r: 0, g: 105, b: 244 },
    { r: 0, g: 108, b: 244 },
    { r: 0, g: 111, b: 243 },
    { r: 0, g: 115, b: 242 },
    { r: 0, g: 118, b: 241 },
    { r: 0, g: 121, b: 241 },
    { r: 0, g: 124, b: 240 },
    { r: 0, g: 127, b: 239 },
    { r: 0, g: 130, b: 238 },
    { r: 0, g: 132, b: 238 },
    { r: 0, g: 135, b: 237 },
    { r: 0, g: 138, b: 236 },
    { r: 0, g: 141, b: 235 },
    { r: 0, g: 143, b: 235 },
    { r: 0, g: 146, b: 234 },
    { r: 0, g: 148, b: 233 },
    { r: 0, g: 151, b: 232 },
    { r: 0, g: 153, b: 231 },
    { r: 0, g: 155, b: 231 },
    { r: 0, g: 158, b: 230 },
    { r: 0, g: 160, b: 229 },
    { r: 0, g: 162, b: 228 },
    { r: 0, g: 165, b: 227 },
    { r: 0, g: 167, b: 227 },
    { r: 0, g: 169, b: 226 },
    { r: 0, g: 171, b: 225 },
    { r: 0, g: 173, b: 224 },
    { r: 0, g: 175, b: 223 },
    { r: 0, g: 177, b: 222 },
    { r: 0, g: 179, b: 222 },
    { r: 0, g: 182, b: 221 },
    { r: 0, g: 184, b: 220 },
    { r: 0, g: 186, b: 219 },
    { r: 0, g: 188, b: 218 },
    { r: 0, g: 189, b: 217 },
    { r: 0, g: 191, b: 217 },
    { r: 0, g: 193, b: 216 },
    { r: 0, g: 195, b: 215 },
    { r: 0, g: 197, b: 214 },
    { r: 0, g: 199, b: 213 },
    { r: 0, g: 201, b: 212 },
    { r: 0, g: 203, b: 211 },
    { r: 0, g: 204, b: 211 },
    { r: 0, g: 206, b: 210 },
    { r: 0, g: 208, b: 209 },
    { r: 0, g: 210, b: 208 },
    { r: 0, g: 211, b: 207 },
    { r: 0, g: 213, b: 206 },
    { r: 0, g: 215, b: 205 },
    { r: 0, g: 217, b: 204 },
    { r: 0, g: 218, b: 203 },
    { r: 0, g: 220, b: 203 },
    { r: 0, g: 222, b: 202 },
    { r: 0, g: 223, b: 201 },
    { r: 0, g: 225, b: 200 },
    { r: 0, g: 227, b: 199 },
    { r: 0, g: 228, b: 198 },
    { r: 0, g: 230, b: 197 },
    { r: 0, g: 231, b: 196 },
    { r: 0, g: 233, b: 195 },
    { r: 0, g: 235, b: 194 },
    { r: 0, g: 236, b: 193 },
    { r: 0, g: 238, b: 192 },
    { r: 0, g: 239, b: 191 },
    { r: 0, g: 241, b: 190 },
    { r: 0, g: 242, b: 189 },
    { r: 0, g: 244, b: 188 },
    { r: 0, g: 245, b: 188 },
    { r: 0, g: 247, b: 187 },
    { r: 0, g: 248, b: 186 },
    { r: 0, g: 250, b: 185 },
    { r: 0, g: 251, b: 184 },
    { r: 0, g: 253, b: 183 },
    { r: 0, g: 254, b: 182 },
    { r: 0, g: 255, b: 181 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 27, g: 27, b: 27 },
    { r: 38, g: 38, b: 38 },
    { r: 47, g: 47, b: 47 },
    { r: 54, g: 54, b: 54 },
    { r: 60, g: 60, b: 60 },
    { r: 66, g: 66, b: 66 },
    { r: 71, g: 71, b: 71 },
    { r: 76, g: 76, b: 76 },
    { r: 81, g: 81, b: 81 },
    { r: 85, g: 85, b: 85 },
    { r: 90, g: 90, b: 90 },
    { r: 94, g: 94, b: 94 },
    { r: 97, g: 97, b: 97 },
    { r: 101, g: 101, b: 101 },
    { r: 105, g: 105, b: 105 },
    { r: 108, g: 108, b: 108 },
    { r: 111, g: 111, b: 111 },
    { r: 115, g: 115, b: 115 },
    { r: 118, g: 118, b: 118 },
    { r: 121, g: 121, b: 121 },
    { r: 124, g: 124, b: 124 },
    { r: 127, g: 127, b: 127 },
    { r: 130, g: 130, b: 130 },
    { r: 132, g: 132, b: 132 },
    { r: 135, g: 135, b: 135 },
    { r: 138, g: 138, b: 138 },
    { r: 141, g: 141, b: 141 },
    { r: 143, g: 143, b: 143 },
    { r: 146, g: 146, b: 146 },
    { r: 148, g: 148, b: 148 },
    { r: 151, g: 151, b: 151 },
    { r: 153, g: 153, b: 153 },
    { r: 155, g: 155, b: 155 },
    { r: 158, g: 158, b: 158 },
    { r: 160, g: 160, b: 160 },
    { r: 162, g: 162, b: 162 },
    { r: 165, g: 165, b: 165 },
    { r: 167, g: 167, b: 167 },
    { r: 169, g: 169, b: 169 },
    { r: 171, g: 171, b: 171 },
    { r: 173, g: 173, b: 173 },
    { r: 175, g: 175, b: 175 },
    { r: 177, g: 177, b: 177 },
    { r: 179, g: 179, b: 179 },
    { r: 182, g: 182, b: 182 },
    { r: 184, g: 184, b: 184 },
    { r: 186, g: 186, b: 186 },
    { r: 188, g: 188, b: 188 },
    { r: 189, g: 189, b: 189 },
    { r: 191, g: 191, b: 191 },
    { r: 193, g: 193, b: 193 },
    { r: 195, g: 195, b: 195 },
    { r: 197, g: 197, b: 197 },
    { r: 199, g: 199, b: 199 },
    { r: 201, g: 201, b: 201 },
    { r: 203, g: 203, b: 203 },
    { r: 204, g: 204, b: 204 },
    { r: 206, g: 206, b: 206 },
    { r: 208, g: 208, b: 208 },
    { r: 210, g: 210, b: 210 },
    { r: 211, g: 211, b: 211 },
    { r: 213, g: 213, b: 213 },
    { r: 215, g: 215, b: 215 },
    { r: 217, g: 217, b: 217 },
    { r: 218, g: 218, b: 218 },
    { r: 220, g: 220, b: 220 },
    { r: 222, g: 222, b: 222 },
    { r: 223, g: 223, b: 223 },
    { r: 225, g: 225, b: 225 },
    { r: 227, g: 227, b: 227 },
    { r: 228, g: 228, b: 228 },
    { r: 230, g: 230, b: 230 },
    { r: 231, g: 231, b: 231 },
    { r: 233, g: 233, b: 233 },
    { r: 235, g: 235, b: 235 },
    { r: 236, g: 236, b: 236 },
    { r: 238, g: 238, b: 238 },
    { r: 239, g: 239, b: 239 },
    { r: 241, g: 241, b: 241 },
    { r: 242, g: 242, b: 242 },
    { r: 244, g: 244, b: 244 },
    { r: 245, g: 245, b: 245 },
    { r: 247, g: 247, b: 247 },
    { r: 248, g: 248, b: 248 },
    { r: 250, g: 250, b: 250 },
    { r: 251, g: 251, b: 251 },
    { r: 253, g: 253, b: 253 },
    { r: 254, g: 254, b: 254 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 25, g: 25, b: 29 },
    { r: 35, g: 35, b: 42 },
    { r: 43, g: 43, b: 51 },
    { r: 50, g: 50, b: 59 },
    { r: 56, g: 56, b: 66 },
    { r: 62, g: 62, b: 73 },
    { r: 67, g: 67, b: 78 },
    { r: 71, g: 71, b: 84 },
    { r: 76, g: 76, b: 89 },
    { r: 80, g: 80, b: 94 },
    { r: 84, g: 84, b: 98 },
    { r: 87, g: 87, b: 103 },
    { r: 91, g: 91, b: 107 },
    { r: 94, g: 94, b: 111 },
    { r: 98, g: 98, b: 115 },
    { r: 101, g: 101, b: 119 },
    { r: 104, g: 104, b: 122 },
    { r: 107, g: 107, b: 126 },
    { r: 110, g: 110, b: 130 },
    { r: 113, g: 113, b: 133 },
    { r: 116, g: 116, b: 136 },
    { r: 119, g: 119, b: 139 },
    { r: 121, g: 121, b: 143 },
    { r: 124, g: 124, b: 146 },
    { r: 126, g: 126, b: 149 },
    { r: 129, g: 129, b: 152 },
    { r: 131, g: 131, b: 154 },
    { r: 134, g: 134, b: 157 },
    { r: 136, g: 136, b: 160 },
    { r: 139, g: 139, b: 163 },
    { r: 141, g: 141, b: 166 },
    { r: 143, g: 143, b: 168 },
    { r: 145, g: 145, b: 171 },
    { r: 148, g: 148, b: 173 },
    { r: 150, g: 151, b: 175 },
    { r: 152, g: 154, b: 177 },
    { r: 154, g: 157, b: 178 },
    { r: 156, g: 160, b: 180 },
    { r: 158, g: 162, b: 182 },
    { r: 160, g: 165, b: 184 },
    { r: 162, g: 168, b: 186 },
    { r: 164, g: 170, b: 187 },
    { r: 166, g: 173, b: 189 },
    { r: 168, g: 175, b: 191 },
    { r: 170, g: 178, b: 192 },
    { r: 172, g: 180, b: 194 },
    { r: 174, g: 183, b: 196 },
    { r: 175, g: 185, b: 197 },
    { r: 177, g: 188, b: 199 },
    { r: 179, g: 190, b: 201 },
    { r: 181, g: 192, b: 202 },
    { r: 183, g: 195, b: 204 },
    { r: 184, g: 197, b: 205 },
    { r: 186, g: 199, b: 207 },
    { r: 188, g: 201, b: 208 },
    { r: 189, g: 204, b: 210 },
    { r: 191, g: 206, b: 211 },
    { r: 193, g: 208, b: 213 },
    { r: 194, g: 210, b: 214 },
    { r: 196, g: 212, b: 216 },
    { r: 198, g: 214, b: 217 },
    { r: 199, g: 216, b: 219 },
    { r: 201, g: 218, b: 220 },
    { r: 203, g: 220, b: 222 },
    { r: 204, g: 222, b: 223 },
    { r: 206, g: 224, b: 225 },
    { r: 207, g: 226, b: 226 },
    { r: 210, g: 228, b: 228 },
    { r: 212, g: 229, b: 229 },
    { r: 215, g: 230, b: 230 },
    { r: 217, g: 232, b: 232 },
    { r: 219, g: 233, b: 233 },
    { r: 222, g: 235, b: 235 },
    { r: 224, g: 236, b: 236 },
    { r: 226, g: 237, b: 237 },
    { r: 228, g: 239, b: 239 },
    { r: 231, g: 240, b: 240 },
    { r: 233, g: 241, b: 241 },
    { r: 235, g: 243, b: 243 },
    { r: 237, g: 244, b: 244 },
    { r: 239, g: 245, b: 245 },
    { r: 241, g: 247, b: 247 },
    { r: 243, g: 248, b: 248 },
    { r: 245, g: 249, b: 249 },
    { r: 247, g: 250, b: 250 },
    { r: 249, g: 252, b: 252 },
    { r: 252, g: 253, b: 253 },
    { r: 254, g: 254, b: 254 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 30, g: 24, b: 19 },
    { r: 42, g: 34, b: 27 },
    { r: 52, g: 42, b: 33 },
    { r: 60, g: 48, b: 38 },
    { r: 67, g: 54, b: 42 },
    { r: 74, g: 59, b: 47 },
    { r: 80, g: 64, b: 50 },
    { r: 85, g: 68, b: 54 },
    { r: 91, g: 72, b: 57 },
    { r: 95, g: 76, b: 60 },
    { r: 100, g: 80, b: 63 },
    { r: 105, g: 84, b: 66 },
    { r: 109, g: 87, b: 69 },
    { r: 113, g: 90, b: 71 },
    { r: 117, g: 94, b: 74 },
    { r: 121, g: 97, b: 76 },
    { r: 125, g: 100, b: 79 },
    { r: 128, g: 102, b: 81 },
    { r: 132, g: 105, b: 83 },
    { r: 135, g: 108, b: 85 },
    { r: 139, g: 111, b: 87 },
    { r: 142, g: 113, b: 90 },
    { r: 145, g: 116, b: 92 },
    { r: 148, g: 118, b: 94 },
    { r: 151, g: 121, b: 95 },
    { r: 154, g: 123, b: 97 },
    { r: 157, g: 126, b: 99 },
    { r: 160, g: 128, b: 101 },
    { r: 163, g: 130, b: 103 },
    { r: 166, g: 132, b: 105 },
    { r: 168, g: 135, b: 106 },
    { r: 171, g: 137, b: 108 },
    { r: 174, g: 139, b: 110 },
    { r: 176, g: 141, b: 111 },
    { r: 179, g: 143, b: 113 },
    { r: 182, g: 145, b: 115 },
    { r: 184, g: 147, b: 116 },
    { r: 187, g: 149, b: 118 },
    { r: 189, g: 151, b: 119 },
    { r: 191, g: 153, b: 121 },
    { r: 194, g: 155, b: 122 },
    { r: 196, g: 157, b: 124 },
    { r: 198, g: 159, b: 125 },
    { r: 201, g: 160, b: 127 },
    { r: 203, g: 162, b: 128 },
    { r: 205, g: 164, b: 130 },
    { r: 207, g: 166, b: 131 },
    { r: 210, g: 168, b: 132 },
    { r: 212, g: 169, b: 134 },
    { r: 214, g: 171, b: 135 },
    { r: 216, g: 173, b: 137 },
    { r: 218, g: 175, b: 138 },
    { r: 220, g: 176, b: 139 },
    { r: 222, g: 178, b: 141 },
    { r: 224, g: 179, b: 142 },
    { r: 227, g: 181, b: 143 },
    { r: 229, g: 183, b: 144 },
    { r: 231, g: 184, b: 146 },
    { r: 233, g: 186, b: 147 },
    { r: 235, g: 188, b: 148 },
    { r: 236, g: 189, b: 149 },
    { r: 238, g: 191, b: 151 },
    { r: 240, g: 192, b: 152 },
    { r: 242, g: 194, b: 153 },
    { r: 244, g: 195, b: 154 },
    { r: 246, g: 197, b: 155 },
    { r: 248, g: 198, b: 157 },
    { r: 250, g: 200, b: 158 },
    { r: 252, g: 201, b: 159 },
    { r: 253, g: 203, b: 160 },
    { r: 255, g: 204, b: 161 },
    { r: 255, g: 205, b: 162 },
    { r: 255, g: 207, b: 163 },
    { r: 255, g: 208, b: 165 },
    { r: 255, g: 210, b: 166 },
    { r: 255, g: 211, b: 167 },
    { r: 255, g: 212, b: 168 },
    { r: 255, g: 214, b: 169 },
    { r: 255, g: 215, b: 170 },
    { r: 255, g: 217, b: 171 },
    { r: 255, g: 218, b: 172 },
    { r: 255, g: 219, b: 173 },
    { r: 255, g: 221, b: 174 },
    { r: 255, g: 222, b: 175 },
    { r: 255, g: 223, b: 176 },
    { r: 255, g: 225, b: 177 },
    { r: 255, g: 226, b: 178 },
    { r: 255, g: 227, b: 179 },
    { r: 255, g: 228, b: 181 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 93, g: 75, b: 75 },
    { r: 110, g: 89, b: 89 },
    { r: 122, g: 99, b: 99 },
    { r: 131, g: 106, b: 106 },
    { r: 139, g: 112, b: 112 },
    { r: 145, g: 117, b: 117 },
    { r: 151, g: 122, b: 122 },
    { r: 156, g: 126, b: 126 },
    { r: 161, g: 130, b: 130 },
    { r: 165, g: 133, b: 133 },
    { r: 169, g: 137, b: 137 },
    { r: 173, g: 140, b: 140 },
    { r: 176, g: 143, b: 143 },
    { r: 180, g: 145, b: 145 },
    { r: 183, g: 148, b: 148 },
    { r: 186, g: 150, b: 150 },
    { r: 189, g: 152, b: 152 },
    { r: 191, g: 155, b: 155 },
    { r: 194, g: 157, b: 157 },
    { r: 196, g: 159, b: 159 },
    { r: 199, g: 161, b: 161 },
    { r: 201, g: 163, b: 163 },
    { r: 203, g: 164, b: 164 },
    { r: 206, g: 166, b: 166 },
    { r: 208, g: 168, b: 168 },
    { r: 210, g: 170, b: 170 },
    { r: 212, g: 171, b: 171 },
    { r: 214, g: 173, b: 173 },
    { r: 216, g: 174, b: 174 },
    { r: 217, g: 176, b: 176 },
    { r: 219, g: 177, b: 177 },
    { r: 221, g: 179, b: 179 },
    { r: 223, g: 180, b: 180 },
    { r: 224, g: 182, b: 181 },
    { r: 224, g: 185, b: 183 },
    { r: 225, g: 188, b: 184 },
    { r: 226, g: 191, b: 185 },
    { r: 226, g: 194, b: 186 },
    { r: 227, g: 196, b: 188 },
    { r: 228, g: 199, b: 189 },
    { r: 229, g: 201, b: 190 },
    { r: 229, g: 203, b: 191 },
    { r: 230, g: 205, b: 192 },
    { r: 230, g: 207, b: 193 },
    { r: 231, g: 210, b: 195 },
    { r: 232, g: 212, b: 196 },
    { r: 232, g: 213, b: 197 },
    { r: 233, g: 215, b: 198 },
    { r: 234, g: 217, b: 199 },
    { r: 234, g: 219, b: 200 },
    { r: 235, g: 221, b: 201 },
    { r: 236, g: 222, b: 202 },
    { r: 236, g: 224, b: 203 },
    { r: 237, g: 226, b: 204 },
    { r: 237, g: 227, b: 205 },
    { r: 238, g: 229, b: 206 },
    { r: 239, g: 230, b: 206 },
    { r: 239, g: 232, b: 207 },
    { r: 240, g: 233, b: 208 },
    { r: 240, g: 235, b: 209 },
    { r: 241, g: 236, b: 210 },
    { r: 241, g: 238, b: 211 },
    { r: 242, g: 239, b: 212 },
    { r: 243, g: 240, b: 213 },
    { r: 243, g: 242, b: 213 },
    { r: 244, g: 243, b: 214 },
    { r: 244, g: 244, b: 215 },
    { r: 245, g: 245, b: 218 },
    { r: 245, g: 245, b: 220 },
    { r: 246, g: 246, b: 222 },
    { r: 246, g: 246, b: 224 },
    { r: 247, g: 247, b: 226 },
    { r: 247, g: 247, b: 229 },
    { r: 248, g: 248, b: 230 },
    { r: 249, g: 249, b: 232 },
    { r: 249, g: 249, b: 234 },
    { r: 250, g: 250, b: 236 },
    { r: 250, g: 250, b: 238 },
    { r: 251, g: 251, b: 240 },
    { r: 251, g: 251, b: 241 },
    { r: 252, g: 252, b: 243 },
    { r: 252, g: 252, b: 245 },
    { r: 253, g: 253, b: 246 },
    { r: 253, g: 253, b: 248 },
    { r: 254, g: 254, b: 250 },
    { r: 254, g: 254, b: 251 },
    { r: 255, g: 255, b: 253 },
    { r: 255, g: 255, b: 254 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 254, g: 254, b: 255 },
    { r: 253, g: 253, b: 255 },
    { r: 251, g: 251, b: 255 },
    { r: 250, g: 250, b: 255 },
    { r: 248, g: 248, b: 255 },
    { r: 247, g: 247, b: 255 },
    { r: 245, g: 245, b: 255 },
    { r: 243, g: 243, b: 255 },
    { r: 242, g: 242, b: 255 },
    { r: 240, g: 240, b: 255 },
    { r: 239, g: 239, b: 255 },
    { r: 237, g: 237, b: 255 },
    { r: 236, g: 236, b: 255 },
    { r: 234, g: 234, b: 255 },
    { r: 233, g: 233, b: 255 },
    { r: 231, g: 231, b: 255 },
    { r: 230, g: 230, b: 255 },
    { r: 228, g: 228, b: 255 },
    { r: 226, g: 226, b: 255 },
    { r: 225, g: 225, b: 255 },
    { r: 223, g: 223, b: 255 },
    { r: 221, g: 221, b: 255 },
    { r: 220, g: 220, b: 255 },
    { r: 218, g: 218, b: 255 },
    { r: 216, g: 216, b: 255 },
    { r: 214, g: 214, b: 255 },
    { r: 213, g: 213, b: 255 },
    { r: 211, g: 211, b: 255 },
    { r: 209, g: 209, b: 255 },
    { r: 208, g: 208, b: 255 },
    { r: 206, g: 206, b: 255 },
    { r: 204, g: 204, b: 255 },
    { r: 202, g: 202, b: 255 },
    { r: 201, g: 201, b: 255 },
    { r: 199, g: 199, b: 255 },
    { r: 197, g: 197, b: 255 },
    { r: 195, g: 195, b: 255 },
    { r: 193, g: 193, b: 255 },
    { r: 191, g: 191, b: 255 },
    { r: 189, g: 189, b: 255 },
    { r: 187, g: 187, b: 255 },
    { r: 185, g: 185, b: 255 },
    { r: 183, g: 183, b: 255 },
    { r: 181, g: 181, b: 255 },
    { r: 179, g: 179, b: 255 },
    { r: 177, g: 177, b: 255 },
    { r: 175, g: 175, b: 255 },
    { r: 173, g: 173, b: 255 },
    { r: 171, g: 171, b: 255 },
    { r: 169, g: 169, b: 255 },
    { r: 166, g: 166, b: 255 },
    { r: 164, g: 164, b: 255 },
    { r: 162, g: 162, b: 255 },
    { r: 160, g: 160, b: 255 },
    { r: 157, g: 157, b: 255 },
    { r: 155, g: 155, b: 255 },
    { r: 152, g: 152, b: 255 },
    { r: 150, g: 150, b: 255 },
    { r: 147, g: 147, b: 255 },
    { r: 145, g: 145, b: 255 },
    { r: 143, g: 143, b: 255 },
    { r: 140, g: 140, b: 255 },
    { r: 138, g: 138, b: 255 },
    { r: 135, g: 135, b: 255 },
    { r: 132, g: 132, b: 255 },
    { r: 129, g: 129, b: 255 },
    { r: 126, g: 126, b: 255 },
    { r: 123, g: 123, b: 255 },
    { r: 120, g: 120, b: 255 },
    { r: 117, g: 117, b: 255 },
    { r: 114, g: 114, b: 255 },
    { r: 110, g: 110, b: 255 },
    { r: 107, g: 107, b: 255 },
    { r: 104, g: 104, b: 255 },
    { r: 100, g: 100, b: 255 },
    { r: 97, g: 97, b: 255 },
    { r: 93, g: 93, b: 255 },
    { r: 89, g: 89, b: 255 },
    { r: 85, g: 85, b: 255 },
    { r: 80, g: 80, b: 255 },
    { r: 75, g: 75, b: 255 },
    { r: 70, g: 70, b: 255 },
    { r: 65, g: 65, b: 255 },
    { r: 59, g: 59, b: 255 },
    { r: 52, g: 52, b: 255 },
    { r: 44, g: 44, b: 255 },
    { r: 36, g: 36, b: 255 },
    { r: 25, g: 25, b: 255 },
    { r: 0, g: 0, b: 255 },
  ],
  [
    { r: 255, g: 0, b: 240 },
    { r: 241, g: 0, b: 246 },
    { r: 226, g: 0, b: 253 },
    { r: 216, g: 0, b: 255 },
    { r: 210, g: 0, b: 255 },
    { r: 204, g: 0, b: 255 },
    { r: 196, g: 0, b: 255 },
    { r: 187, g: 0, b: 255 },
    { r: 180, g: 0, b: 255 },
    { r: 173, g: 0, b: 255 },
    { r: 165, g: 0, b: 255 },
    { r: 155, g: 0, b: 255 },
    { r: 144, g: 0, b: 255 },
    { r: 135, g: 0, b: 255 },
    { r: 126, g: 0, b: 255 },
    { r: 114, g: 0, b: 255 },
    { r: 99, g: 0, b: 255 },
    { r: 81, g: 5, b: 255 },
    { r: 60, g: 15, b: 255 },
    { r: 26, g: 21, b: 255 },
    { r: 0, g: 54, b: 255 },
    { r: 0, g: 79, b: 255 },
    { r: 0, g: 96, b: 255 },
    { r: 0, g: 108, b: 255 },
    { r: 0, g: 119, b: 255 },
    { r: 0, g: 128, b: 255 },
    { r: 0, g: 137, b: 255 },
    { r: 0, g: 153, b: 255 },
    { r: 0, g: 173, b: 255 },
    { r: 0, g: 190, b: 255 },
    { r: 0, g: 198, b: 255 },
    { r: 0, g: 206, b: 255 },
    { r: 0, g: 215, b: 255 },
    { r: 0, g: 225, b: 255 },
    { r: 0, g: 232, b: 255 },
    { r: 0, g: 238, b: 255 },
    { r: 0, g: 243, b: 255 },
    { r: 0, g: 248, b: 251 },
    { r: 0, g: 253, b: 246 },
    { r: 0, g: 255, b: 240 },
    { r: 0, g: 255, b: 233 },
    { r: 0, g: 255, b: 225 },
    { r: 0, g: 255, b: 218 },
    { r: 0, g: 255, b: 210 },
    { r: 0, g: 255, b: 202 },
    { r: 0, g: 255, b: 194 },
    { r: 0, g: 255, b: 186 },
    { r: 0, g: 255, b: 177 },
    { r: 0, g: 255, b: 168 },
    { r: 0, g: 255, b: 158 },
    { r: 0, g: 255, b: 148 },
    { r: 0, g: 255, b: 134 },
    { r: 0, g: 255, b: 118 },
    { r: 0, g: 255, b: 98 },
    { r: 0, g: 255, b: 74 },
    { r: 0, g: 255, b: 36 },
    { r: 55, g: 255, b: 0 },
    { r: 86, g: 255, b: 0 },
    { r: 110, g: 255, b: 0 },
    { r: 130, g: 255, b: 0 },
    { r: 148, g: 255, b: 0 },
    { r: 161, g: 255, b: 0 },
    { r: 173, g: 255, b: 0 },
    { r: 185, g: 255, b: 0 },
    { r: 195, g: 255, b: 0 },
    { r: 205, g: 255, b: 0 },
    { r: 213, g: 255, b: 0 },
    { r: 221, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 235, g: 255, b: 0 },
    { r: 242, g: 254, b: 0 },
    { r: 248, g: 252, b: 0 },
    { r: 254, g: 250, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 231, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 215, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 199, b: 0 },
    { r: 255, g: 190, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 172, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 149, b: 0 },
    { r: 255, g: 135, b: 0 },
    { r: 255, g: 119, b: 0 },
    { r: 255, g: 100, b: 0 },
    { r: 255, g: 80, b: 0 },
    { r: 255, g: 56, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 41, g: 60, b: 255 },
    { r: 61, g: 86, b: 255 },
    { r: 75, g: 106, b: 255 },
    { r: 86, g: 123, b: 255 },
    { r: 97, g: 138, b: 255 },
    { r: 106, g: 151, b: 255 },
    { r: 115, g: 163, b: 255 },
    { r: 123, g: 174, b: 255 },
    { r: 131, g: 185, b: 255 },
    { r: 137, g: 195, b: 255 },
    { r: 144, g: 205, b: 255 },
    { r: 151, g: 214, b: 255 },
    { r: 157, g: 222, b: 255 },
    { r: 163, g: 231, b: 255 },
    { r: 169, g: 239, b: 255 },
    { r: 174, g: 247, b: 255 },
    { r: 180, g: 255, b: 255 },
    { r: 180, g: 255, b: 242 },
    { r: 182, g: 254, b: 238 },
    { r: 183, g: 253, b: 234 },
    { r: 184, g: 252, b: 230 },
    { r: 185, g: 251, b: 226 },
    { r: 187, g: 250, b: 222 },
    { r: 188, g: 249, b: 218 },
    { r: 189, g: 248, b: 214 },
    { r: 190, g: 246, b: 209 },
    { r: 191, g: 245, b: 205 },
    { r: 193, g: 244, b: 200 },
    { r: 194, g: 243, b: 196 },
    { r: 195, g: 242, b: 191 },
    { r: 196, g: 241, b: 186 },
    { r: 197, g: 240, b: 181 },
    { r: 198, g: 239, b: 176 },
    { r: 200, g: 237, b: 171 },
    { r: 201, g: 236, b: 165 },
    { r: 202, g: 235, b: 160 },
    { r: 203, g: 234, b: 153 },
    { r: 204, g: 233, b: 148 },
    { r: 205, g: 231, b: 141 },
    { r: 206, g: 230, b: 135 },
    { r: 207, g: 229, b: 128 },
    { r: 209, g: 228, b: 120 },
    { r: 210, g: 226, b: 112 },
    { r: 211, g: 225, b: 104 },
    { r: 212, g: 224, b: 94 },
    { r: 213, g: 223, b: 84 },
    { r: 214, g: 222, b: 72 },
    { r: 215, g: 221, b: 59 },
    { r: 216, g: 219, b: 40 },
    { r: 217, g: 218, b: 17 },
    { r: 218, g: 217, b: 0 },
    { r: 219, g: 215, b: 0 },
    { r: 220, g: 214, b: 0 },
    { r: 222, g: 213, b: 0 },
    { r: 222, g: 211, b: 0 },
    { r: 224, g: 210, b: 0 },
    { r: 224, g: 209, b: 0 },
    { r: 226, g: 207, b: 0 },
    { r: 227, g: 206, b: 0 },
    { r: 228, g: 204, b: 0 },
    { r: 228, g: 204, b: 0 },
    { r: 230, g: 202, b: 0 },
    { r: 231, g: 201, b: 0 },
    { r: 231, g: 199, b: 0 },
    { r: 233, g: 198, b: 0 },
    { r: 234, g: 197, b: 0 },
    { r: 235, g: 195, b: 0 },
    { r: 235, g: 194, b: 0 },
    { r: 236, g: 192, b: 0 },
    { r: 238, g: 191, b: 0 },
    { r: 238, g: 189, b: 0 },
    { r: 239, g: 188, b: 0 },
    { r: 240, g: 186, b: 0 },
    { r: 241, g: 185, b: 0 },
    { r: 242, g: 183, b: 0 },
    { r: 243, g: 182, b: 0 },
    { r: 244, g: 181, b: 0 },
    { r: 245, g: 179, b: 0 },
    { r: 246, g: 177, b: 0 },
    { r: 247, g: 176, b: 0 },
    { r: 248, g: 174, b: 0 },
    { r: 249, g: 173, b: 0 },
    { r: 250, g: 171, b: 0 },
    { r: 251, g: 169, b: 0 },
    { r: 252, g: 168, b: 0 },
    { r: 253, g: 166, b: 0 },
    { r: 253, g: 164, b: 0 },
    { r: 254, g: 162, b: 0 },
    { r: 255, g: 161, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 0 },
    { r: 253, g: 255, b: 0 },
    { r: 250, g: 255, b: 0 },
    { r: 247, g: 255, b: 0 },
    { r: 244, g: 255, b: 0 },
    { r: 241, g: 255, b: 0 },
    { r: 238, g: 255, b: 0 },
    { r: 234, g: 255, b: 0 },
    { r: 231, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 225, g: 255, b: 0 },
    { r: 221, g: 255, b: 0 },
    { r: 218, g: 255, b: 0 },
    { r: 215, g: 255, b: 0 },
    { r: 211, g: 255, b: 0 },
    { r: 208, g: 255, b: 0 },
    { r: 204, g: 255, b: 0 },
    { r: 200, g: 255, b: 0 },
    { r: 197, g: 255, b: 0 },
    { r: 193, g: 255, b: 0 },
    { r: 189, g: 255, b: 0 },
    { r: 185, g: 255, b: 0 },
    { r: 181, g: 255, b: 0 },
    { r: 177, g: 255, b: 0 },
    { r: 173, g: 255, b: 0 },
    { r: 168, g: 255, b: 0 },
    { r: 164, g: 255, b: 0 },
    { r: 159, g: 255, b: 0 },
    { r: 155, g: 255, b: 0 },
    { r: 150, g: 255, b: 0 },
    { r: 145, g: 255, b: 0 },
    { r: 140, g: 255, b: 0 },
    { r: 135, g: 255, b: 0 },
    { r: 130, g: 255, b: 0 },
    { r: 124, g: 255, b: 0 },
    { r: 118, g: 255, b: 0 },
    { r: 111, g: 255, b: 0 },
    { r: 104, g: 255, b: 0 },
    { r: 97, g: 255, b: 0 },
    { r: 89, g: 255, b: 0 },
    { r: 80, g: 255, b: 0 },
    { r: 71, g: 255, b: 0 },
    { r: 59, g: 255, b: 0 },
    { r: 52, g: 255, b: 24 },
    { r: 45, g: 254, b: 36 },
    { r: 36, g: 253, b: 45 },
    { r: 24, g: 253, b: 52 },
    { r: 0, g: 252, b: 59 },
    { r: 0, g: 250, b: 71 },
    { r: 0, g: 249, b: 80 },
    { r: 0, g: 247, b: 89 },
    { r: 0, g: 246, b: 97 },
    { r: 0, g: 244, b: 104 },
    { r: 0, g: 243, b: 111 },
    { r: 0, g: 241, b: 118 },
    { r: 0, g: 240, b: 124 },
    { r: 0, g: 238, b: 130 },
    { r: 0, g: 237, b: 135 },
    { r: 0, g: 235, b: 140 },
    { r: 0, g: 234, b: 145 },
    { r: 0, g: 232, b: 150 },
    { r: 0, g: 231, b: 155 },
    { r: 0, g: 229, b: 159 },
    { r: 0, g: 227, b: 164 },
    { r: 0, g: 225, b: 168 },
    { r: 0, g: 224, b: 173 },
    { r: 0, g: 222, b: 177 },
    { r: 0, g: 221, b: 181 },
    { r: 0, g: 219, b: 185 },
    { r: 0, g: 217, b: 189 },
    { r: 0, g: 216, b: 193 },
    { r: 0, g: 214, b: 197 },
    { r: 0, g: 212, b: 200 },
    { r: 0, g: 210, b: 204 },
    { r: 0, g: 208, b: 208 },
    { r: 0, g: 207, b: 211 },
    { r: 0, g: 205, b: 215 },
    { r: 0, g: 203, b: 218 },
    { r: 0, g: 201, b: 221 },
    { r: 0, g: 200, b: 225 },
    { r: 0, g: 198, b: 228 },
    { r: 0, g: 196, b: 231 },
    { r: 0, g: 194, b: 234 },
    { r: 0, g: 192, b: 238 },
    { r: 0, g: 190, b: 241 },
    { r: 0, g: 188, b: 244 },
    { r: 0, g: 186, b: 247 },
    { r: 0, g: 184, b: 250 },
    { r: 0, g: 182, b: 253 },
    { r: 0, g: 180, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 253 },
    { r: 0, g: 30, b: 253 },
    { r: 0, g: 45, b: 253 },
    { r: 0, g: 56, b: 253 },
    { r: 0, g: 64, b: 253 },
    { r: 0, g: 72, b: 253 },
    { r: 0, g: 80, b: 253 },
    { r: 0, g: 86, b: 253 },
    { r: 0, g: 92, b: 253 },
    { r: 0, g: 98, b: 253 },
    { r: 0, g: 104, b: 253 },
    { r: 0, g: 108, b: 253 },
    { r: 0, g: 113, b: 253 },
    { r: 0, g: 118, b: 253 },
    { r: 0, g: 122, b: 253 },
    { r: 0, g: 126, b: 253 },
    { r: 0, g: 131, b: 253 },
    { r: 0, g: 135, b: 253 },
    { r: 0, g: 139, b: 253 },
    { r: 0, g: 143, b: 253 },
    { r: 0, g: 147, b: 253 },
    { r: 0, g: 150, b: 253 },
    { r: 0, g: 154, b: 253 },
    { r: 0, g: 157, b: 253 },
    { r: 0, g: 161, b: 253 },
    { r: 0, g: 164, b: 253 },
    { r: 0, g: 167, b: 253 },
    { r: 0, g: 171, b: 253 },
    { r: 0, g: 173, b: 253 },
    { r: 0, g: 177, b: 253 },
    { r: 0, g: 180, b: 253 },
    { r: 0, g: 183, b: 253 },
    { r: 0, g: 185, b: 253 },
    { r: 0, g: 189, b: 253 },
    { r: 0, g: 192, b: 253 },
    { r: 0, g: 194, b: 253 },
    { r: 0, g: 197, b: 253 },
    { r: 0, g: 200, b: 253 },
    { r: 0, g: 203, b: 253 },
    { r: 0, g: 205, b: 253 },
    { r: 0, g: 208, b: 253 },
    { r: 0, g: 210, b: 253 },
    { r: 0, g: 213, b: 253 },
    { r: 0, g: 215, b: 253 },
    { r: 0, g: 218, b: 253 },
    { r: 0, g: 221, b: 253 },
    { r: 0, g: 223, b: 253 },
    { r: 0, g: 225, b: 253 },
    { r: 0, g: 228, b: 253 },
    { r: 0, g: 230, b: 253 },
    { r: 0, g: 232, b: 253 },
    { r: 0, g: 235, b: 253 },
    { r: 0, g: 237, b: 253 },
    { r: 0, g: 239, b: 253 },
    { r: 0, g: 242, b: 253 },
    { r: 0, g: 244, b: 253 },
    { r: 0, g: 246, b: 253 },
    { r: 0, g: 248, b: 253 },
    { r: 0, g: 250, b: 253 },
    { r: 0, g: 255, b: 255 },
    { r: 0, g: 255, b: 218 },
    { r: 0, g: 255, b: 167 },
    { r: 0, g: 255, b: 91 },
    { r: 69, g: 255, b: 0 },
    { r: 117, g: 255, b: 0 },
    { r: 150, g: 255, b: 0 },
    { r: 176, g: 255, b: 0 },
    { r: 200, g: 255, b: 0 },
    { r: 221, g: 255, b: 0 },
    { r: 253, g: 255, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 232, b: 0 },
    { r: 255, g: 226, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 191, b: 0 },
    { r: 255, g: 183, b: 0 },
    { r: 255, g: 175, b: 0 },
    { r: 255, g: 167, b: 0 },
    { r: 255, g: 158, b: 0 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 138, b: 0 },
    { r: 255, g: 127, b: 0 },
    { r: 255, g: 115, b: 0 },
    { r: 255, g: 102, b: 0 },
    { r: 255, g: 87, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 25, g: 37, b: 254 },
    { r: 36, g: 53, b: 253 },
    { r: 44, g: 65, b: 251 },
    { r: 52, g: 75, b: 250 },
    { r: 59, g: 85, b: 248 },
    { r: 65, g: 93, b: 247 },
    { r: 70, g: 101, b: 245 },
    { r: 75, g: 107, b: 243 },
    { r: 80, g: 114, b: 242 },
    { r: 85, g: 120, b: 240 },
    { r: 89, g: 126, b: 239 },
    { r: 93, g: 132, b: 237 },
    { r: 97, g: 137, b: 236 },
    { r: 100, g: 142, b: 234 },
    { r: 104, g: 147, b: 233 },
    { r: 107, g: 152, b: 231 },
    { r: 110, g: 157, b: 230 },
    { r: 114, g: 162, b: 228 },
    { r: 117, g: 166, b: 226 },
    { r: 120, g: 171, b: 225 },
    { r: 123, g: 175, b: 223 },
    { r: 126, g: 179, b: 221 },
    { r: 129, g: 183, b: 220 },
    { r: 132, g: 187, b: 218 },
    { r: 135, g: 191, b: 216 },
    { r: 138, g: 195, b: 214 },
    { r: 140, g: 199, b: 213 },
    { r: 143, g: 202, b: 211 },
    { r: 145, g: 206, b: 209 },
    { r: 147, g: 209, b: 208 },
    { r: 150, g: 213, b: 206 },
    { r: 152, g: 216, b: 204 },
    { r: 155, g: 220, b: 202 },
    { r: 157, g: 223, b: 201 },
    { r: 160, g: 226, b: 199 },
    { r: 162, g: 230, b: 197 },
    { r: 164, g: 233, b: 195 },
    { r: 166, g: 236, b: 193 },
    { r: 169, g: 239, b: 191 },
    { r: 171, g: 242, b: 189 },
    { r: 173, g: 245, b: 187 },
    { r: 175, g: 248, b: 185 },
    { r: 177, g: 251, b: 183 },
    { r: 179, g: 251, b: 181 },
    { r: 181, g: 251, b: 179 },
    { r: 183, g: 251, b: 177 },
    { r: 185, g: 248, b: 175 },
    { r: 187, g: 245, b: 173 },
    { r: 189, g: 242, b: 171 },
    { r: 191, g: 239, b: 169 },
    { r: 193, g: 236, b: 166 },
    { r: 195, g: 233, b: 164 },
    { r: 197, g: 230, b: 162 },
    { r: 199, g: 226, b: 160 },
    { r: 201, g: 223, b: 157 },
    { r: 202, g: 220, b: 155 },
    { r: 204, g: 216, b: 152 },
    { r: 206, g: 213, b: 150 },
    { r: 208, g: 209, b: 147 },
    { r: 209, g: 206, b: 145 },
    { r: 211, g: 202, b: 143 },
    { r: 213, g: 199, b: 140 },
    { r: 214, g: 195, b: 138 },
    { r: 216, g: 191, b: 135 },
    { r: 218, g: 187, b: 132 },
    { r: 220, g: 183, b: 129 },
    { r: 221, g: 179, b: 126 },
    { r: 223, g: 175, b: 123 },
    { r: 225, g: 171, b: 120 },
    { r: 226, g: 166, b: 117 },
    { r: 228, g: 162, b: 114 },
    { r: 230, g: 157, b: 110 },
    { r: 231, g: 152, b: 107 },
    { r: 233, g: 147, b: 104 },
    { r: 234, g: 142, b: 100 },
    { r: 236, g: 137, b: 97 },
    { r: 237, g: 132, b: 93 },
    { r: 239, g: 126, b: 89 },
    { r: 240, g: 120, b: 85 },
    { r: 242, g: 114, b: 80 },
    { r: 243, g: 107, b: 75 },
    { r: 245, g: 101, b: 70 },
    { r: 247, g: 93, b: 65 },
    { r: 248, g: 85, b: 59 },
    { r: 250, g: 75, b: 52 },
    { r: 251, g: 65, b: 44 },
    { r: 253, g: 53, b: 36 },
    { r: 254, g: 37, b: 25 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 214 },
    { r: 23, g: 36, b: 215 },
    { r: 33, g: 51, b: 216 },
    { r: 40, g: 62, b: 217 },
    { r: 46, g: 72, b: 218 },
    { r: 52, g: 81, b: 219 },
    { r: 57, g: 88, b: 220 },
    { r: 61, g: 96, b: 221 },
    { r: 66, g: 102, b: 222 },
    { r: 70, g: 108, b: 222 },
    { r: 74, g: 114, b: 223 },
    { r: 78, g: 120, b: 224 },
    { r: 82, g: 126, b: 225 },
    { r: 85, g: 131, b: 226 },
    { r: 89, g: 136, b: 227 },
    { r: 92, g: 141, b: 228 },
    { r: 95, g: 146, b: 229 },
    { r: 97, g: 150, b: 230 },
    { r: 100, g: 154, b: 231 },
    { r: 103, g: 159, b: 232 },
    { r: 106, g: 163, b: 233 },
    { r: 109, g: 167, b: 234 },
    { r: 111, g: 171, b: 235 },
    { r: 114, g: 175, b: 236 },
    { r: 116, g: 179, b: 237 },
    { r: 119, g: 182, b: 238 },
    { r: 121, g: 186, b: 239 },
    { r: 123, g: 189, b: 239 },
    { r: 126, g: 193, b: 240 },
    { r: 128, g: 196, b: 241 },
    { r: 130, g: 200, b: 242 },
    { r: 132, g: 203, b: 243 },
    { r: 135, g: 206, b: 244 },
    { r: 137, g: 209, b: 245 },
    { r: 139, g: 212, b: 246 },
    { r: 141, g: 216, b: 247 },
    { r: 143, g: 219, b: 248 },
    { r: 145, g: 222, b: 248 },
    { r: 147, g: 225, b: 249 },
    { r: 149, g: 228, b: 250 },
    { r: 151, g: 231, b: 251 },
    { r: 153, g: 234, b: 252 },
    { r: 155, g: 236, b: 252 },
    { r: 150, g: 236, b: 252 },
    { r: 145, g: 234, b: 251 },
    { r: 139, g: 233, b: 251 },
    { r: 133, g: 231, b: 250 },
    { r: 128, g: 230, b: 249 },
    { r: 128, g: 231, b: 247 },
    { r: 128, g: 231, b: 244 },
    { r: 128, g: 232, b: 242 },
    { r: 128, g: 233, b: 240 },
    { r: 128, g: 233, b: 237 },
    { r: 128, g: 234, b: 235 },
    { r: 128, g: 235, b: 232 },
    { r: 128, g: 235, b: 230 },
    { r: 128, g: 236, b: 227 },
    { r: 128, g: 237, b: 225 },
    { r: 128, g: 237, b: 222 },
    { r: 128, g: 238, b: 220 },
    { r: 128, g: 238, b: 217 },
    { r: 128, g: 239, b: 214 },
    { r: 128, g: 240, b: 212 },
    { r: 128, g: 240, b: 209 },
    { r: 128, g: 241, b: 206 },
    { r: 128, g: 241, b: 203 },
    { r: 128, g: 242, b: 200 },
    { r: 128, g: 242, b: 198 },
    { r: 128, g: 243, b: 195 },
    { r: 128, g: 243, b: 192 },
    { r: 128, g: 244, b: 189 },
    { r: 128, g: 245, b: 186 },
    { r: 128, g: 245, b: 182 },
    { r: 128, g: 246, b: 179 },
    { r: 128, g: 246, b: 176 },
    { r: 128, g: 247, b: 173 },
    { r: 128, g: 247, b: 169 },
    { r: 128, g: 248, b: 166 },
    { r: 128, g: 249, b: 162 },
    { r: 128, g: 249, b: 159 },
    { r: 128, g: 250, b: 155 },
    { r: 128, g: 250, b: 151 },
    { r: 128, g: 251, b: 148 },
    { r: 128, g: 251, b: 144 },
    { r: 128, g: 252, b: 140 },
    { r: 128, g: 252, b: 135 },
    { r: 128, g: 253, b: 131 },
    { r: 128, g: 254, b: 127 },
    { r: 128, g: 254, b: 122 },
    { r: 128, g: 255, b: 117 },
  ],
  [
    { r: 0, g: 0, b: 253 },
    { r: 0, g: 48, b: 253 },
    { r: 0, g: 70, b: 253 },
    { r: 0, g: 86, b: 253 },
    { r: 0, g: 99, b: 253 },
    { r: 0, g: 111, b: 253 },
    { r: 0, g: 121, b: 253 },
    { r: 0, g: 131, b: 253 },
    { r: 0, g: 141, b: 253 },
    { r: 0, g: 149, b: 253 },
    { r: 0, g: 157, b: 253 },
    { r: 0, g: 165, b: 253 },
    { r: 0, g: 172, b: 253 },
    { r: 0, g: 179, b: 253 },
    { r: 0, g: 186, b: 253 },
    { r: 0, g: 193, b: 253 },
    { r: 0, g: 199, b: 253 },
    { r: 0, g: 205, b: 253 },
    { r: 0, g: 211, b: 253 },
    { r: 0, g: 217, b: 253 },
    { r: 0, g: 223, b: 253 },
    { r: 0, g: 228, b: 253 },
    { r: 0, g: 233, b: 253 },
    { r: 0, g: 239, b: 253 },
    { r: 0, g: 244, b: 253 },
    { r: 0, g: 250, b: 254 },
    { r: 0, g: 255, b: 254 },
    { r: 0, g: 255, b: 245 },
    { r: 0, g: 255, b: 237 },
    { r: 0, g: 255, b: 228 },
    { r: 0, g: 255, b: 219 },
    { r: 0, g: 255, b: 210 },
    { r: 0, g: 255, b: 200 },
    { r: 0, g: 255, b: 189 },
    { r: 0, g: 255, b: 178 },
    { r: 0, g: 255, b: 166 },
    { r: 0, g: 255, b: 154 },
    { r: 0, g: 255, b: 140 },
    { r: 0, g: 255, b: 98 },
    { r: 34, g: 255, b: 0 },
    { r: 72, g: 255, b: 0 },
    { r: 96, g: 255, b: 0 },
    { r: 115, g: 255, b: 0 },
    { r: 132, g: 255, b: 0 },
    { r: 147, g: 255, b: 0 },
    { r: 160, g: 255, b: 0 },
    { r: 172, g: 255, b: 0 },
    { r: 184, g: 255, b: 0 },
    { r: 194, g: 255, b: 0 },
    { r: 205, g: 255, b: 0 },
    { r: 214, g: 255, b: 0 },
    { r: 239, g: 255, b: 0 },
    { r: 255, g: 254, b: 0 },
    { r: 255, g: 251, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 245, b: 0 },
    { r: 255, g: 242, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 233, b: 0 },
    { r: 255, g: 230, b: 0 },
    { r: 255, g: 226, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 202, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 194, b: 0 },
    { r: 255, g: 190, b: 0 },
    { r: 255, g: 187, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 178, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 170, b: 0 },
    { r: 255, g: 165, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 156, b: 0 },
    { r: 255, g: 151, b: 0 },
    { r: 255, g: 146, b: 0 },
    { r: 255, g: 141, b: 0 },
    { r: 255, g: 136, b: 0 },
    { r: 255, g: 130, b: 0 },
    { r: 255, g: 124, b: 0 },
    { r: 255, g: 118, b: 0 },
    { r: 255, g: 112, b: 0 },
    { r: 255, g: 105, b: 0 },
    { r: 255, g: 97, b: 0 },
  ],
  [
    { r: 253, g: 0, b: 251 },
    { r: 245, g: 0, b: 251 },
    { r: 236, g: 0, b: 251 },
    { r: 226, g: 0, b: 251 },
    { r: 217, g: 0, b: 251 },
    { r: 207, g: 0, b: 252 },
    { r: 195, g: 0, b: 252 },
    { r: 184, g: 0, b: 252 },
    { r: 173, g: 0, b: 252 },
    { r: 159, g: 0, b: 252 },
    { r: 145, g: 0, b: 252 },
    { r: 130, g: 0, b: 252 },
    { r: 111, g: 0, b: 252 },
    { r: 91, g: 0, b: 252 },
    { r: 63, g: 0, b: 252 },
    { r: 0, g: 10, b: 253 },
    { r: 0, g: 41, b: 253 },
    { r: 0, g: 59, b: 253 },
    { r: 0, g: 71, b: 253 },
    { r: 0, g: 83, b: 253 },
    { r: 0, g: 91, b: 253 },
    { r: 0, g: 101, b: 253 },
    { r: 0, g: 108, b: 253 },
    { r: 0, g: 116, b: 253 },
    { r: 0, g: 123, b: 253 },
    { r: 0, g: 130, b: 253 },
    { r: 0, g: 137, b: 253 },
    { r: 0, g: 142, b: 253 },
    { r: 0, g: 148, b: 253 },
    { r: 0, g: 154, b: 253 },
    { r: 0, g: 159, b: 253 },
    { r: 0, g: 164, b: 253 },
    { r: 0, g: 169, b: 253 },
    { r: 0, g: 174, b: 253 },
    { r: 0, g: 179, b: 253 },
    { r: 0, g: 184, b: 253 },
    { r: 0, g: 188, b: 253 },
    { r: 0, g: 193, b: 253 },
    { r: 0, g: 197, b: 253 },
    { r: 0, g: 201, b: 253 },
    { r: 0, g: 205, b: 253 },
    { r: 0, g: 210, b: 253 },
    { r: 0, g: 213, b: 253 },
    { r: 0, g: 218, b: 253 },
    { r: 0, g: 221, b: 253 },
    { r: 0, g: 225, b: 253 },
    { r: 0, g: 229, b: 253 },
    { r: 0, g: 232, b: 253 },
    { r: 0, g: 236, b: 253 },
    { r: 0, g: 240, b: 253 },
    { r: 0, g: 243, b: 253 },
    { r: 0, g: 246, b: 253 },
    { r: 0, g: 250, b: 253 },
    { r: 0, g: 255, b: 243 },
    { r: 0, g: 255, b: 168 },
    { r: 29, g: 255, b: 0 },
    { r: 120, g: 255, b: 0 },
    { r: 167, g: 255, b: 0 },
    { r: 205, g: 255, b: 0 },
    { r: 244, g: 255, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 229, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 208, b: 0 },
    { r: 255, g: 197, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 173, b: 0 },
    { r: 255, g: 159, b: 0 },
    { r: 255, g: 144, b: 0 },
    { r: 255, g: 128, b: 0 },
    { r: 255, g: 109, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 85, b: 69 },
    { r: 255, g: 79, b: 104 },
    { r: 255, g: 75, b: 129 },
    { r: 255, g: 70, b: 152 },
    { r: 255, g: 65, b: 170 },
    { r: 255, g: 58, b: 187 },
    { r: 255, g: 50, b: 203 },
    { r: 255, g: 44, b: 218 },
    { r: 255, g: 33, b: 231 },
    { r: 255, g: 22, b: 244 },
  ],
  [
    { r: 246, g: 27, b: 247 },
    { r: 240, g: 52, b: 247 },
    { r: 234, g: 66, b: 247 },
    { r: 227, g: 79, b: 247 },
    { r: 219, g: 90, b: 246 },
    { r: 211, g: 99, b: 246 },
    { r: 202, g: 109, b: 245 },
    { r: 194, g: 117, b: 244 },
    { r: 185, g: 125, b: 243 },
    { r: 175, g: 133, b: 243 },
    { r: 165, g: 140, b: 242 },
    { r: 155, g: 147, b: 241 },
    { r: 144, g: 154, b: 240 },
    { r: 132, g: 161, b: 240 },
    { r: 119, g: 167, b: 238 },
    { r: 105, g: 174, b: 238 },
    { r: 88, g: 180, b: 237 },
    { r: 69, g: 186, b: 236 },
    { r: 42, g: 192, b: 235 },
    { r: 31, g: 199, b: 230 },
    { r: 27, g: 206, b: 222 },
    { r: 22, g: 212, b: 213 },
    { r: 22, g: 217, b: 203 },
    { r: 22, g: 221, b: 193 },
    { r: 16, g: 226, b: 182 },
    { r: 16, g: 231, b: 170 },
    { r: 14, g: 235, b: 157 },
    { r: 0, g: 237, b: 143 },
    { r: 0, g: 239, b: 128 },
    { r: 0, g: 238, b: 111 },
    { r: 0, g: 236, b: 95 },
    { r: 0, g: 232, b: 79 },
    { r: 0, g: 227, b: 64 },
    { r: 0, g: 223, b: 50 },
    { r: 0, g: 217, b: 38 },
    { r: 0, g: 212, b: 31 },
    { r: 11, g: 208, b: 27 },
    { r: 31, g: 204, b: 35 },
    { r: 51, g: 200, b: 52 },
    { r: 78, g: 199, b: 80 },
    { r: 110, g: 200, b: 110 },
    { r: 139, g: 202, b: 139 },
    { r: 166, g: 204, b: 166 },
    { r: 190, g: 207, b: 190 },
    { r: 226, g: 226, b: 226 },
    { r: 212, g: 203, b: 194 },
    { r: 213, g: 198, b: 181 },
    { r: 214, g: 194, b: 169 },
    { r: 216, g: 190, b: 159 },
    { r: 217, g: 188, b: 151 },
    { r: 219, g: 187, b: 147 },
    { r: 221, g: 187, b: 144 },
    { r: 223, g: 188, b: 142 },
    { r: 225, g: 189, b: 139 },
    { r: 229, g: 190, b: 135 },
    { r: 232, g: 190, b: 130 },
    { r: 235, g: 191, b: 123 },
    { r: 238, g: 191, b: 113 },
    { r: 242, g: 191, b: 103 },
    { r: 244, g: 192, b: 92 },
    { r: 247, g: 193, b: 82 },
    { r: 248, g: 195, b: 71 },
    { r: 250, g: 197, b: 63 },
    { r: 251, g: 200, b: 52 },
    { r: 252, g: 203, b: 49 },
    { r: 252, g: 206, b: 48 },
    { r: 252, g: 208, b: 55 },
    { r: 252, g: 211, b: 63 },
    { r: 252, g: 212, b: 75 },
    { r: 251, g: 213, b: 86 },
    { r: 251, g: 213, b: 99 },
    { r: 251, g: 212, b: 112 },
    { r: 252, g: 210, b: 124 },
    { r: 252, g: 207, b: 136 },
    { r: 252, g: 204, b: 144 },
    { r: 252, g: 198, b: 152 },
    { r: 253, g: 193, b: 157 },
    { r: 253, g: 186, b: 159 },
    { r: 254, g: 178, b: 159 },
    { r: 254, g: 170, b: 156 },
    { r: 254, g: 161, b: 151 },
    { r: 254, g: 150, b: 144 },
    { r: 254, g: 139, b: 135 },
    { r: 254, g: 126, b: 124 },
    { r: 254, g: 112, b: 111 },
    { r: 254, g: 97, b: 96 },
    { r: 254, g: 80, b: 80 },
    { r: 254, g: 60, b: 60 },
    { r: 254, g: 32, b: 32 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 104, g: 104, b: 104 },
    { r: 124, g: 124, b: 124 },
    { r: 116, g: 125, b: 116 },
    { r: 50, g: 131, b: 50 },
    { r: 0, g: 133, b: 0 },
    { r: 0, g: 140, b: 25 },
    { r: 0, g: 159, b: 49 },
    { r: 0, g: 161, b: 50 },
    { r: 0, g: 177, b: 66 },
    { r: 0, g: 201, b: 87 },
    { r: 0, g: 201, b: 87 },
    { r: 0, g: 207, b: 106 },
    { r: 0, g: 213, b: 123 },
    { r: 0, g: 213, b: 123 },
    { r: 0, g: 223, b: 155 },
    { r: 0, g: 229, b: 172 },
    { r: 0, g: 229, b: 173 },
    { r: 0, g: 223, b: 190 },
    { r: 0, g: 221, b: 196 },
    { r: 0, g: 218, b: 202 },
    { r: 0, g: 204, b: 225 },
    { r: 0, g: 202, b: 230 },
    { r: 24, g: 196, b: 232 },
    { r: 45, g: 180, b: 237 },
    { r: 45, g: 180, b: 237 },
    { r: 64, g: 163, b: 240 },
    { r: 84, g: 135, b: 244 },
    { r: 84, g: 135, b: 244 },
    { r: 104, g: 122, b: 241 },
    { r: 119, g: 111, b: 238 },
    { r: 119, g: 111, b: 238 },
    { r: 153, g: 80, b: 230 },
    { r: 168, g: 57, b: 225 },
    { r: 169, g: 57, b: 224 },
    { r: 187, g: 56, b: 214 },
    { r: 192, g: 55, b: 211 },
    { r: 199, g: 56, b: 206 },
    { r: 223, g: 61, b: 188 },
    { r: 226, g: 62, b: 185 },
    { r: 226, g: 89, b: 185 },
    { r: 224, g: 131, b: 185 },
    { r: 224, g: 131, b: 185 },
    { r: 223, g: 144, b: 185 },
    { r: 222, g: 160, b: 186 },
    { r: 222, g: 160, b: 186 },
    { r: 221, g: 162, b: 172 },
    { r: 221, g: 163, b: 161 },
    { r: 221, g: 163, b: 161 },
    { r: 221, g: 165, b: 141 },
    { r: 220, g: 166, b: 132 },
    { r: 222, g: 168, b: 131 },
    { r: 230, g: 183, b: 124 },
    { r: 232, g: 186, b: 123 },
    { r: 237, g: 196, b: 120 },
    { r: 252, g: 222, b: 112 },
    { r: 253, g: 224, b: 112 },
    { r: 254, g: 228, b: 105 },
    { r: 255, g: 236, b: 92 },
    { r: 255, g: 236, b: 92 },
    { r: 255, g: 244, b: 69 },
    { r: 255, g: 253, b: 27 },
    { r: 255, g: 253, b: 27 },
    { r: 255, g: 244, b: 67 },
    { r: 255, g: 238, b: 84 },
    { r: 255, g: 237, b: 85 },
    { r: 255, g: 210, b: 135 },
    { r: 255, g: 198, b: 150 },
    { r: 255, g: 196, b: 147 },
    { r: 255, g: 185, b: 131 },
    { r: 255, g: 182, b: 128 },
    { r: 255, g: 176, b: 111 },
    { r: 255, g: 157, b: 42 },
    { r: 255, g: 156, b: 35 },
    { r: 254, g: 151, b: 39 },
    { r: 252, g: 142, b: 45 },
    { r: 252, g: 142, b: 45 },
    { r: 253, g: 129, b: 68 },
    { r: 254, g: 115, b: 84 },
    { r: 254, g: 115, b: 84 },
    { r: 237, g: 154, b: 139 },
    { r: 226, g: 173, b: 163 },
    { r: 227, g: 175, b: 166 },
    { r: 233, g: 206, b: 204 },
    { r: 235, g: 216, b: 215 },
    { r: 234, g: 219, b: 218 },
    { r: 232, g: 230, b: 230 },
    { r: 232, g: 232, b: 232 },
    { r: 195, g: 195, b: 195 },
    { r: 0, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 214, g: 0, b: 214 },
    { r: 255, g: 0, b: 255 },
    { r: 255, g: 0, b: 255 },
    { r: 255, g: 0, b: 255 },
    { r: 254, g: 0, b: 255 },
    { r: 254, g: 0, b: 255 },
    { r: 252, g: 0, b: 255 },
    { r: 252, g: 0, b: 255 },
    { r: 247, g: 0, b: 255 },
    { r: 238, g: 0, b: 255 },
    { r: 238, g: 0, b: 255 },
    { r: 229, g: 0, b: 255 },
    { r: 218, g: 0, b: 255 },
    { r: 218, g: 0, b: 255 },
    { r: 214, g: 0, b: 255 },
    { r: 212, g: 0, b: 254 },
    { r: 211, g: 0, b: 254 },
    { r: 207, g: 0, b: 254 },
    { r: 205, g: 0, b: 254 },
    { r: 203, g: 0, b: 254 },
    { r: 191, g: 0, b: 252 },
    { r: 189, g: 0, b: 252 },
    { r: 183, g: 8, b: 251 },
    { r: 170, g: 15, b: 249 },
    { r: 170, g: 16, b: 249 },
    { r: 157, g: 46, b: 248 },
    { r: 135, g: 69, b: 246 },
    { r: 135, g: 69, b: 246 },
    { r: 105, g: 98, b: 244 },
    { r: 69, g: 118, b: 242 },
    { r: 69, g: 118, b: 242 },
    { r: 41, g: 152, b: 242 },
    { r: 0, g: 168, b: 242 },
    { r: 0, g: 171, b: 242 },
    { r: 0, g: 206, b: 242 },
    { r: 0, g: 216, b: 242 },
    { r: 14, g: 221, b: 234 },
    { r: 30, g: 241, b: 202 },
    { r: 32, g: 244, b: 197 },
    { r: 29, g: 224, b: 181 },
    { r: 22, g: 172, b: 139 },
    { r: 22, g: 172, b: 139 },
    { r: 119, g: 175, b: 158 },
    { r: 179, g: 179, b: 179 },
    { r: 179, g: 179, b: 179 },
    { r: 216, g: 204, b: 182 },
    { r: 241, g: 222, b: 184 },
    { r: 241, g: 222, b: 184 },
    { r: 237, g: 204, b: 139 },
    { r: 236, g: 195, b: 112 },
    { r: 237, g: 197, b: 110 },
    { r: 245, g: 213, b: 94 },
    { r: 247, g: 217, b: 89 },
    { r: 248, g: 221, b: 84 },
    { r: 254, g: 235, b: 69 },
    { r: 254, g: 236, b: 68 },
    { r: 254, g: 236, b: 77 },
    { r: 255, g: 236, b: 92 },
    { r: 255, g: 236, b: 92 },
    { r: 255, g: 225, b: 128 },
    { r: 255, g: 213, b: 160 },
    { r: 255, g: 213, b: 160 },
    { r: 255, g: 201, b: 182 },
    { r: 255, g: 193, b: 196 },
    { r: 255, g: 192, b: 196 },
    { r: 255, g: 186, b: 187 },
    { r: 255, g: 183, b: 183 },
    { r: 255, g: 181, b: 176 },
    { r: 255, g: 169, b: 133 },
    { r: 255, g: 166, b: 122 },
    { r: 255, g: 163, b: 107 },
    { r: 255, g: 155, b: 47 },
    { r: 255, g: 154, b: 42 },
    { r: 255, g: 152, b: 33 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 148, b: 0 },
    { r: 255, g: 134, b: 0 },
    { r: 255, g: 118, b: 0 },
    { r: 255, g: 118, b: 0 },
    { r: 255, g: 85, b: 0 },
    { r: 255, g: 57, b: 0 },
    { r: 255, g: 56, b: 0 },
    { r: 255, g: 28, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 215, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
  ],
  [
    { r: 168, g: 168, b: 168 },
    { r: 162, g: 162, b: 171 },
    { r: 157, g: 157, b: 174 },
    { r: 151, g: 151, b: 177 },
    { r: 151, g: 151, b: 182 },
    { r: 163, g: 163, b: 192 },
    { r: 174, g: 174, b: 201 },
    { r: 185, g: 185, b: 210 },
    { r: 170, g: 170, b: 207 },
    { r: 139, g: 139, b: 198 },
    { r: 99, g: 99, b: 189 },
    { r: 16, g: 16, b: 179 },
    { r: 16, g: 42, b: 187 },
    { r: 16, g: 57, b: 196 },
    { r: 16, g: 69, b: 203 },
    { r: 16, g: 73, b: 211 },
    { r: 16, g: 62, b: 217 },
    { r: 16, g: 48, b: 224 },
    { r: 16, g: 27, b: 230 },
    { r: 19, g: 78, b: 236 },
    { r: 24, g: 122, b: 243 },
    { r: 28, g: 154, b: 249 },
    { r: 32, g: 181, b: 255 },
    { r: 32, g: 193, b: 255 },
    { r: 32, g: 204, b: 255 },
    { r: 32, g: 215, b: 255 },
    { r: 32, g: 225, b: 244 },
    { r: 32, g: 234, b: 208 },
    { r: 32, g: 243, b: 163 },
    { r: 32, g: 251, b: 99 },
    { r: 32, g: 246, b: 66 },
    { r: 32, g: 234, b: 66 },
    { r: 32, g: 221, b: 66 },
    { r: 32, g: 208, b: 66 },
    { r: 32, g: 202, b: 66 },
    { r: 32, g: 195, b: 66 },
    { r: 32, g: 188, b: 66 },
    { r: 32, g: 181, b: 66 },
    { r: 32, g: 171, b: 66 },
    { r: 32, g: 160, b: 66 },
    { r: 32, g: 149, b: 66 },
    { r: 100, g: 163, b: 113 },
    { r: 154, g: 187, b: 160 },
    { r: 194, g: 207, b: 196 },
    { r: 226, g: 226, b: 226 },
    { r: 221, g: 205, b: 193 },
    { r: 216, g: 182, b: 154 },
    { r: 210, g: 156, b: 99 },
    { r: 209, g: 142, b: 54 },
    { r: 218, g: 160, b: 97 },
    { r: 226, g: 176, b: 126 },
    { r: 233, g: 191, b: 149 },
    { r: 239, g: 199, b: 147 },
    { r: 245, g: 205, b: 130 },
    { r: 250, g: 211, b: 111 },
    { r: 255, g: 217, b: 89 },
    { r: 255, g: 228, b: 77 },
    { r: 255, g: 239, b: 63 },
    { r: 255, g: 249, b: 45 },
    { r: 255, g: 253, b: 71 },
    { r: 255, g: 243, b: 135 },
    { r: 255, g: 234, b: 177 },
    { r: 255, g: 224, b: 210 },
    { r: 255, g: 215, b: 215 },
    { r: 255, g: 206, b: 206 },
    { r: 255, g: 196, b: 196 },
    { r: 255, g: 186, b: 186 },
    { r: 255, g: 177, b: 177 },
    { r: 255, g: 167, b: 167 },
    { r: 255, g: 157, b: 157 },
    { r: 255, g: 152, b: 142 },
    { r: 255, g: 159, b: 119 },
    { r: 255, g: 166, b: 90 },
    { r: 255, g: 172, b: 45 },
    { r: 252, g: 169, b: 0 },
    { r: 248, g: 161, b: 0 },
    { r: 243, g: 152, b: 0 },
    { r: 239, g: 143, b: 0 },
    { r: 226, g: 122, b: 0 },
    { r: 213, g: 96, b: 0 },
    { r: 198, g: 61, b: 0 },
    { r: 192, g: 0, b: 0 },
    { r: 205, g: 0, b: 0 },
    { r: 217, g: 0, b: 0 },
    { r: 228, g: 0, b: 0 },
    { r: 236, g: 0, b: 0 },
    { r: 243, g: 0, b: 0 },
    { r: 249, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 16, g: 121, b: 255 },
    { r: 14, g: 135, b: 255 },
    { r: 13, g: 147, b: 255 },
    { r: 11, g: 159, b: 255 },
    { r: 9, g: 170, b: 255 },
    { r: 6, g: 180, b: 255 },
    { r: 1, g: 190, b: 255 },
    { r: 6, g: 197, b: 255 },
    { r: 9, g: 205, b: 255 },
    { r: 11, g: 212, b: 255 },
    { r: 13, g: 219, b: 255 },
    { r: 14, g: 226, b: 255 },
    { r: 16, g: 232, b: 255 },
    { r: 16, g: 236, b: 253 },
    { r: 16, g: 240, b: 251 },
    { r: 16, g: 244, b: 249 },
    { r: 16, g: 248, b: 247 },
    { r: 16, g: 252, b: 245 },
    { r: 16, g: 255, b: 243 },
    { r: 16, g: 255, b: 237 },
    { r: 16, g: 255, b: 231 },
    { r: 16, g: 255, b: 226 },
    { r: 16, g: 255, b: 220 },
    { r: 16, g: 255, b: 214 },
    { r: 16, g: 255, b: 207 },
    { r: 16, g: 255, b: 197 },
    { r: 16, g: 255, b: 186 },
    { r: 16, g: 255, b: 175 },
    { r: 16, g: 255, b: 164 },
    { r: 16, g: 255, b: 151 },
    { r: 29, g: 255, b: 138 },
    { r: 51, g: 255, b: 125 },
    { r: 67, g: 255, b: 110 },
    { r: 79, g: 255, b: 94 },
    { r: 90, g: 255, b: 73 },
    { r: 99, g: 255, b: 45 },
    { r: 114, g: 255, b: 5 },
    { r: 136, g: 255, b: 11 },
    { r: 154, g: 255, b: 14 },
    { r: 170, g: 255, b: 17 },
    { r: 185, g: 255, b: 19 },
    { r: 199, g: 255, b: 21 },
    { r: 209, g: 255, b: 22 },
    { r: 215, g: 255, b: 21 },
    { r: 221, g: 255, b: 20 },
    { r: 227, g: 255, b: 19 },
    { r: 233, g: 255, b: 17 },
    { r: 238, g: 255, b: 16 },
    { r: 242, g: 253, b: 15 },
    { r: 245, g: 248, b: 13 },
    { r: 247, g: 243, b: 12 },
    { r: 250, g: 238, b: 10 },
    { r: 252, g: 233, b: 7 },
    { r: 255, g: 227, b: 4 },
    { r: 255, g: 223, b: 5 },
    { r: 255, g: 220, b: 8 },
    { r: 255, g: 217, b: 10 },
    { r: 255, g: 214, b: 12 },
    { r: 255, g: 211, b: 14 },
    { r: 255, g: 208, b: 15 },
    { r: 255, g: 206, b: 16 },
    { r: 255, g: 203, b: 16 },
    { r: 255, g: 201, b: 16 },
    { r: 255, g: 198, b: 16 },
    { r: 255, g: 196, b: 16 },
    { r: 255, g: 193, b: 16 },
    { r: 255, g: 191, b: 16 },
    { r: 255, g: 190, b: 16 },
    { r: 255, g: 188, b: 16 },
    { r: 255, g: 186, b: 16 },
    { r: 255, g: 184, b: 16 },
    { r: 255, g: 183, b: 16 },
    { r: 255, g: 180, b: 14 },
    { r: 255, g: 177, b: 13 },
    { r: 255, g: 174, b: 11 },
    { r: 255, g: 172, b: 9 },
    { r: 255, g: 169, b: 7 },
    { r: 255, g: 166, b: 2 },
    { r: 255, g: 163, b: 0 },
    { r: 255, g: 160, b: 0 },
    { r: 255, g: 156, b: 0 },
    { r: 255, g: 153, b: 0 },
    { r: 255, g: 150, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 135, b: 0 },
    { r: 255, g: 123, b: 0 },
    { r: 255, g: 108, b: 0 },
    { r: 255, g: 92, b: 0 },
    { r: 255, g: 71, b: 0 },
    { r: 255, g: 42, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 105, b: 105 },
    { r: 0, g: 148, b: 148 },
    { r: 0, g: 182, b: 182 },
    { r: 0, g: 210, b: 210 },
    { r: 0, g: 235, b: 235 },
    { r: 19, g: 255, b: 255 },
    { r: 78, g: 251, b: 254 },
    { r: 109, g: 247, b: 252 },
    { r: 133, g: 242, b: 250 },
    { r: 154, g: 238, b: 249 },
    { r: 172, g: 234, b: 247 },
    { r: 184, g: 227, b: 245 },
    { r: 167, g: 206, b: 247 },
    { r: 149, g: 184, b: 249 },
    { r: 127, g: 158, b: 251 },
    { r: 102, g: 126, b: 252 },
    { r: 68, g: 84, b: 254 },
    { r: 0, g: 47, b: 251 },
    { r: 0, g: 115, b: 228 },
    { r: 0, g: 155, b: 203 },
    { r: 0, g: 188, b: 173 },
    { r: 0, g: 215, b: 138 },
    { r: 0, g: 239, b: 90 },
    { r: 24, g: 254, b: 24 },
    { r: 52, g: 250, b: 52 },
    { r: 70, g: 246, b: 70 },
    { r: 84, g: 241, b: 84 },
    { r: 96, g: 237, b: 96 },
    { r: 106, g: 232, b: 106 },
    { r: 112, g: 227, b: 112 },
    { r: 109, g: 221, b: 109 },
    { r: 105, g: 214, b: 105 },
    { r: 102, g: 207, b: 102 },
    { r: 99, g: 200, b: 99 },
    { r: 95, g: 193, b: 95 },
    { r: 110, g: 193, b: 90 },
    { r: 145, g: 204, b: 81 },
    { r: 173, g: 214, b: 72 },
    { r: 196, g: 224, b: 61 },
    { r: 218, g: 233, b: 47 },
    { r: 237, g: 242, b: 28 },
    { r: 247, g: 246, b: 51 },
    { r: 247, g: 244, b: 90 },
    { r: 247, g: 243, b: 117 },
    { r: 247, g: 241, b: 139 },
    { r: 247, g: 240, b: 158 },
    { r: 247, g: 238, b: 175 },
    { r: 247, g: 232, b: 176 },
    { r: 247, g: 223, b: 164 },
    { r: 247, g: 212, b: 150 },
    { r: 247, g: 202, b: 136 },
    { r: 247, g: 191, b: 119 },
    { r: 247, g: 179, b: 100 },
    { r: 248, g: 168, b: 94 },
    { r: 249, g: 159, b: 97 },
    { r: 251, g: 149, b: 100 },
    { r: 252, g: 139, b: 104 },
    { r: 254, g: 127, b: 107 },
    { r: 255, g: 115, b: 110 },
    { r: 251, g: 111, b: 117 },
    { r: 244, g: 111, b: 125 },
    { r: 237, g: 111, b: 133 },
    { r: 230, g: 111, b: 141 },
    { r: 222, g: 111, b: 148 },
    { r: 214, g: 111, b: 155 },
    { r: 212, g: 111, b: 157 },
    { r: 212, g: 111, b: 157 },
    { r: 212, g: 111, b: 157 },
    { r: 212, g: 111, b: 157 },
    { r: 212, g: 111, b: 157 },
    { r: 212, g: 111, b: 157 },
    { r: 213, g: 116, b: 169 },
    { r: 214, g: 123, b: 183 },
    { r: 215, g: 129, b: 196 },
    { r: 216, g: 135, b: 208 },
    { r: 217, g: 141, b: 220 },
    { r: 218, g: 147, b: 231 },
    { r: 224, g: 136, b: 236 },
    { r: 231, g: 122, b: 240 },
    { r: 237, g: 106, b: 244 },
    { r: 243, g: 87, b: 248 },
    { r: 249, g: 62, b: 251 },
    { r: 255, g: 15, b: 255 },
    { r: 255, g: 101, b: 255 },
    { r: 255, g: 146, b: 255 },
    { r: 255, g: 179, b: 255 },
    { r: 255, g: 208, b: 255 },
    { r: 255, g: 233, b: 255 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 21 },
    { r: 0, g: 0, b: 38 },
    { r: 0, g: 0, b: 51 },
    { r: 0, g: 0, b: 60 },
    { r: 0, g: 0, b: 70 },
    { r: 0, g: 0, b: 77 },
    { r: 0, g: 0, b: 84 },
    { r: 0, g: 0, b: 90 },
    { r: 0, g: 0, b: 96 },
    { r: 0, g: 0, b: 103 },
    { r: 0, g: 0, b: 108 },
    { r: 0, g: 0, b: 113 },
    { r: 0, g: 0, b: 118 },
    { r: 0, g: 0, b: 123 },
    { r: 0, g: 0, b: 127 },
    { r: 0, g: 0, b: 131 },
    { r: 0, g: 0, b: 135 },
    { r: 0, g: 0, b: 140 },
    { r: 0, g: 0, b: 144 },
    { r: 0, g: 0, b: 148 },
    { r: 0, g: 0, b: 151 },
    { r: 0, g: 0, b: 155 },
    { r: 0, g: 0, b: 159 },
    { r: 0, g: 0, b: 162 },
    { r: 0, g: 0, b: 166 },
    { r: 0, g: 0, b: 169 },
    { r: 0, g: 0, b: 172 },
    { r: 0, g: 0, b: 175 },
    { r: 0, g: 0, b: 179 },
    { r: 0, g: 248, b: 248 },
    { r: 0, g: 248, b: 248 },
    { r: 0, g: 248, b: 248 },
    { r: 0, g: 248, b: 248 },
    { r: 0, g: 192, b: 192 },
    { r: 0, g: 192, b: 192 },
    { r: 0, g: 192, b: 192 },
    { r: 0, g: 192, b: 192 },
    { r: 181, g: 239, b: 143 },
    { r: 181, g: 239, b: 143 },
    { r: 181, g: 239, b: 143 },
    { r: 181, g: 239, b: 143 },
    { r: 160, g: 217, b: 128 },
    { r: 160, g: 217, b: 128 },
    { r: 160, g: 217, b: 128 },
    { r: 160, g: 217, b: 128 },
    { r: 136, g: 192, b: 111 },
    { r: 136, g: 192, b: 111 },
    { r: 136, g: 192, b: 111 },
    { r: 136, g: 192, b: 111 },
    { r: 106, g: 163, b: 90 },
    { r: 106, g: 163, b: 90 },
    { r: 106, g: 163, b: 90 },
    { r: 106, g: 163, b: 90 },
    { r: 64, g: 128, b: 64 },
    { r: 64, g: 128, b: 64 },
    { r: 64, g: 128, b: 64 },
    { r: 64, g: 128, b: 64 },
    { r: 248, g: 222, b: 64 },
    { r: 248, g: 222, b: 64 },
    { r: 248, g: 222, b: 64 },
    { r: 248, g: 222, b: 64 },
    { r: 248, g: 181, b: 90 },
    { r: 248, g: 181, b: 90 },
    { r: 248, g: 181, b: 90 },
    { r: 248, g: 181, b: 90 },
    { r: 248, g: 64, b: 90 },
    { r: 248, g: 64, b: 90 },
    { r: 248, g: 64, b: 90 },
    { r: 248, g: 64, b: 90 },
    { r: 192, g: 0, b: 0 },
    { r: 192, g: 0, b: 0 },
    { r: 192, g: 0, b: 0 },
    { r: 192, g: 0, b: 0 },
    { r: 212, g: 90, b: 181 },
    { r: 212, g: 90, b: 181 },
    { r: 212, g: 90, b: 181 },
    { r: 212, g: 90, b: 181 },
    { r: 227, g: 128, b: 202 },
    { r: 227, g: 128, b: 202 },
    { r: 227, g: 128, b: 202 },
    { r: 227, g: 128, b: 202 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 181, b: 239 },
    { r: 255, g: 181, b: 239 },
    { r: 255, g: 181, b: 239 },
    { r: 255, g: 181, b: 239 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 27, g: 27, b: 27 },
    { r: 38, g: 38, b: 38 },
    { r: 47, g: 47, b: 47 },
    { r: 54, g: 54, b: 54 },
    { r: 60, g: 60, b: 60 },
    { r: 66, g: 66, b: 66 },
    { r: 71, g: 71, b: 71 },
    { r: 21, g: 21, b: 93 },
    { r: 26, g: 31, b: 101 },
    { r: 38, g: 46, b: 107 },
    { r: 46, g: 56, b: 113 },
    { r: 54, g: 65, b: 118 },
    { r: 60, g: 73, b: 124 },
    { r: 66, g: 80, b: 129 },
    { r: 71, g: 87, b: 134 },
    { r: 78, g: 93, b: 139 },
    { r: 82, g: 98, b: 143 },
    { r: 87, g: 104, b: 147 },
    { r: 91, g: 109, b: 152 },
    { r: 95, g: 114, b: 156 },
    { r: 99, g: 119, b: 160 },
    { r: 102, g: 123, b: 164 },
    { r: 106, g: 128, b: 168 },
    { r: 109, g: 131, b: 172 },
    { r: 112, g: 135, b: 176 },
    { r: 116, g: 139, b: 179 },
    { r: 119, g: 143, b: 183 },
    { r: 122, g: 147, b: 186 },
    { r: 125, g: 151, b: 190 },
    { r: 128, g: 154, b: 193 },
    { r: 131, g: 158, b: 196 },
    { r: 134, g: 161, b: 200 },
    { r: 137, g: 164, b: 203 },
    { r: 140, g: 168, b: 206 },
    { r: 142, g: 171, b: 209 },
    { r: 145, g: 174, b: 212 },
    { r: 147, g: 177, b: 215 },
    { r: 150, g: 180, b: 218 },
    { r: 124, g: 152, b: 131 },
    { r: 139, g: 152, b: 92 },
    { r: 164, g: 165, b: 106 },
    { r: 185, g: 177, b: 119 },
    { r: 204, g: 189, b: 130 },
    { r: 221, g: 199, b: 140 },
    { r: 222, g: 199, b: 140 },
    { r: 217, g: 193, b: 136 },
    { r: 211, g: 187, b: 131 },
    { r: 204, g: 180, b: 127 },
    { r: 198, g: 174, b: 122 },
    { r: 191, g: 166, b: 117 },
    { r: 184, g: 159, b: 111 },
    { r: 177, g: 152, b: 106 },
    { r: 169, g: 143, b: 100 },
    { r: 161, g: 135, b: 93 },
    { r: 153, g: 126, b: 86 },
    { r: 144, g: 116, b: 79 },
    { r: 135, g: 105, b: 70 },
    { r: 124, g: 93, b: 61 },
    { r: 114, g: 79, b: 50 },
    { r: 101, g: 62, b: 36 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 0, g: 168, b: 0 },
    { r: 0, g: 168, b: 0 },
    { r: 0, g: 168, b: 0 },
    { r: 0, g: 216, b: 0 },
    { r: 0, g: 216, b: 0 },
    { r: 0, g: 216, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 0, b: 255 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 248, b: 248 },
    { r: 0, g: 192, b: 192 },
    { r: 181, g: 239, b: 143 },
    { r: 160, g: 217, b: 128 },
    { r: 136, g: 192, b: 111 },
    { r: 90, g: 163, b: 90 },
    { r: 64, g: 128, b: 64 },
    { r: 64, g: 222, b: 64 },
    { r: 248, g: 181, b: 90 },
    { r: 248, g: 64, b: 90 },
    { r: 192, g: 0, b: 0 },
    { r: 212, g: 90, b: 181 },
    { r: 227, g: 128, b: 202 },
    { r: 242, g: 157, b: 222 },
    { r: 255, g: 181, b: 239 },
    { r: 0, g: 0, b: 0 },
    { r: 224, g: 224, b: 224 },
    { r: 174, g: 174, b: 174 },
    { r: 255, g: 209, b: 209 },
    { r: 247, g: 189, b: 189 },
    { r: 227, g: 169, b: 169 },
    { r: 0, g: 253, b: 192 },
    { r: 0, g: 219, b: 0 },
    { r: 255, g: 255, b: 169 },
    { r: 231, g: 231, b: 157 },
    { r: 255, g: 157, b: 157 },
    { r: 236, g: 0, b: 0 },
    { r: 211, g: 0, b: 0 },
    { r: 0, g: 0, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 243, g: 0, b: 255 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 157 },
    { r: 0, g: 169, b: 202 },
    { r: 0, g: 222, b: 222 },
    { r: 0, g: 181, b: 0 },
    { r: 0, g: 222, b: 0 },
    { r: 128, g: 157, b: 128 },
    { r: 212, g: 212, b: 212 },
    { r: 143, g: 143, b: 90 },
    { r: 222, g: 222, b: 0 },
    { r: 222, g: 197, b: 0 },
    { r: 222, g: 169, b: 0 },
    { r: 202, g: 119, b: 0 },
    { r: 181, g: 0, b: 0 },
    { r: 157, g: 0, b: 0 },
    { r: 222, g: 128, b: 222 },
    { r: 0, g: 0, b: 0 },
    { r: 128, g: 248, b: 248 },
    { r: 103, g: 202, b: 227 },
    { r: 73, g: 143, b: 205 },
    { r: 0, g: 0, b: 181 },
    { r: 157, g: 222, b: 111 },
    { r: 64, g: 169, b: 64 },
    { r: 255, g: 239, b: 90 },
    { r: 239, g: 212, b: 64 },
    { r: 255, g: 181, b: 64 },
    { r: 231, g: 143, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 181, g: 0, b: 0 },
    { r: 255, g: 0, b: 255 },
    { r: 255, g: 222, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 248, b: 248 },
    { r: 0, g: 192, b: 192 },
    { r: 181, g: 239, b: 143 },
    { r: 160, g: 217, b: 128 },
    { r: 136, g: 192, b: 111 },
    { r: 106, g: 163, b: 90 },
    { r: 64, g: 128, b: 64 },
    { r: 248, g: 222, b: 64 },
    { r: 248, g: 181, b: 90 },
    { r: 248, g: 64, b: 90 },
    { r: 192, g: 0, b: 0 },
    { r: 212, g: 90, b: 181 },
    { r: 227, g: 128, b: 202 },
    { r: 242, g: 157, b: 222 },
    { r: 255, g: 181, b: 239 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 168, b: 0 },
    { r: 0, g: 216, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 0, b: 255 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
    { r: 0, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 126, g: 126, b: 126 },
    { r: 128, g: 128, b: 128 },
    { r: 130, g: 130, b: 130 },
    { r: 133, g: 133, b: 133 },
    { r: 134, g: 134, b: 134 },
    { r: 136, g: 136, b: 136 },
    { r: 138, g: 138, b: 138 },
    { r: 140, g: 140, b: 140 },
    { r: 142, g: 142, b: 142 },
    { r: 144, g: 144, b: 144 },
    { r: 146, g: 146, b: 146 },
    { r: 148, g: 148, b: 148 },
    { r: 149, g: 149, b: 149 },
    { r: 151, g: 151, b: 151 },
    { r: 153, g: 153, b: 153 },
    { r: 155, g: 155, b: 155 },
    { r: 157, g: 157, b: 157 },
    { r: 159, g: 159, b: 159 },
    { r: 161, g: 161, b: 161 },
    { r: 162, g: 162, b: 162 },
    { r: 164, g: 164, b: 164 },
    { r: 165, g: 165, b: 165 },
    { r: 167, g: 167, b: 167 },
    { r: 169, g: 169, b: 169 },
    { r: 170, g: 170, b: 170 },
    { r: 172, g: 172, b: 172 },
    { r: 174, g: 174, b: 174 },
    { r: 175, g: 175, b: 175 },
    { r: 176, g: 176, b: 176 },
    { r: 178, g: 178, b: 178 },
    { r: 180, g: 180, b: 180 },
    { r: 181, g: 181, b: 181 },
    { r: 183, g: 183, b: 183 },
    { r: 185, g: 185, b: 185 },
    { r: 186, g: 186, b: 186 },
    { r: 187, g: 187, b: 187 },
    { r: 189, g: 189, b: 189 },
    { r: 190, g: 190, b: 190 },
    { r: 192, g: 192, b: 192 },
    { r: 193, g: 193, b: 193 },
    { r: 195, g: 195, b: 195 },
    { r: 196, g: 196, b: 196 },
    { r: 198, g: 198, b: 198 },
    { r: 199, g: 199, b: 199 },
    { r: 200, g: 200, b: 200 },
    { r: 202, g: 202, b: 202 },
    { r: 203, g: 203, b: 203 },
    { r: 204, g: 204, b: 204 },
    { r: 206, g: 206, b: 206 },
    { r: 207, g: 207, b: 207 },
    { r: 208, g: 208, b: 208 },
    { r: 210, g: 210, b: 210 },
    { r: 211, g: 211, b: 211 },
    { r: 212, g: 212, b: 212 },
    { r: 213, g: 213, b: 213 },
    { r: 215, g: 215, b: 215 },
    { r: 216, g: 216, b: 216 },
    { r: 218, g: 218, b: 218 },
    { r: 219, g: 219, b: 219 },
    { r: 220, g: 220, b: 220 },
    { r: 221, g: 221, b: 221 },
    { r: 222, g: 222, b: 222 },
    { r: 224, g: 224, b: 224 },
    { r: 225, g: 225, b: 225 },
    { r: 226, g: 226, b: 226 },
    { r: 227, g: 227, b: 227 },
    { r: 228, g: 228, b: 228 },
    { r: 230, g: 230, b: 230 },
    { r: 231, g: 231, b: 231 },
    { r: 232, g: 232, b: 232 },
    { r: 233, g: 233, b: 233 },
    { r: 235, g: 235, b: 235 },
    { r: 236, g: 236, b: 236 },
    { r: 237, g: 237, b: 237 },
    { r: 238, g: 238, b: 238 },
    { r: 239, g: 239, b: 239 },
    { r: 240, g: 240, b: 240 },
    { r: 241, g: 241, b: 241 },
    { r: 243, g: 243, b: 243 },
    { r: 244, g: 244, b: 244 },
    { r: 245, g: 245, b: 245 },
    { r: 246, g: 246, b: 246 },
    { r: 247, g: 247, b: 247 },
    { r: 248, g: 248, b: 248 },
    { r: 249, g: 249, b: 249 },
    { r: 250, g: 250, b: 250 },
    { r: 252, g: 252, b: 252 },
    { r: 253, g: 253, b: 253 },
    { r: 254, g: 254, b: 254 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 17, g: 18, b: 97 },
    { r: 32, g: 36, b: 103 },
    { r: 42, g: 50, b: 108 },
    { r: 48, g: 59, b: 114 },
    { r: 54, g: 66, b: 119 },
    { r: 61, g: 74, b: 124 },
    { r: 66, g: 80, b: 129 },
    { r: 72, g: 86, b: 133 },
    { r: 77, g: 92, b: 138 },
    { r: 81, g: 97, b: 142 },
    { r: 84, g: 102, b: 146 },
    { r: 88, g: 107, b: 150 },
    { r: 92, g: 111, b: 154 },
    { r: 96, g: 116, b: 158 },
    { r: 100, g: 120, b: 162 },
    { r: 104, g: 124, b: 165 },
    { r: 107, g: 128, b: 169 },
    { r: 109, g: 132, b: 173 },
    { r: 113, g: 136, b: 176 },
    { r: 116, g: 139, b: 179 },
    { r: 119, g: 143, b: 183 },
    { r: 122, g: 146, b: 186 },
    { r: 125, g: 150, b: 189 },
    { r: 127, g: 153, b: 192 },
    { r: 130, g: 157, b: 195 },
    { r: 132, g: 160, b: 198 },
    { r: 135, g: 163, b: 201 },
    { r: 138, g: 166, b: 204 },
    { r: 141, g: 169, b: 207 },
    { r: 143, g: 172, b: 210 },
    { r: 145, g: 174, b: 213 },
    { r: 147, g: 177, b: 216 },
    { r: 150, g: 180, b: 219 },
    { r: 113, g: 139, b: 78 },
    { r: 139, g: 152, b: 92 },
    { r: 164, g: 165, b: 106 },
    { r: 185, g: 177, b: 119 },
    { r: 204, g: 189, b: 130 },
    { r: 221, g: 199, b: 140 },
    { r: 222, g: 199, b: 140 },
    { r: 217, g: 193, b: 136 },
    { r: 211, g: 187, b: 131 },
    { r: 204, g: 180, b: 127 },
    { r: 198, g: 174, b: 122 },
    { r: 191, g: 166, b: 117 },
    { r: 184, g: 159, b: 111 },
    { r: 177, g: 152, b: 106 },
    { r: 169, g: 143, b: 100 },
    { r: 161, g: 135, b: 93 },
    { r: 153, g: 126, b: 86 },
    { r: 144, g: 116, b: 79 },
    { r: 135, g: 105, b: 70 },
    { r: 124, g: 93, b: 61 },
    { r: 114, g: 79, b: 50 },
    { r: 101, g: 62, b: 36 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 101, g: 62, b: 35 },
    { r: 183, g: 183, b: 183 },
    { r: 187, g: 187, b: 187 },
    { r: 191, g: 191, b: 191 },
    { r: 195, g: 195, b: 195 },
    { r: 198, g: 198, b: 198 },
    { r: 202, g: 202, b: 202 },
    { r: 205, g: 205, b: 205 },
    { r: 209, g: 209, b: 209 },
    { r: 212, g: 212, b: 212 },
    { r: 215, g: 215, b: 215 },
    { r: 219, g: 219, b: 219 },
    { r: 222, g: 222, b: 222 },
    { r: 225, g: 225, b: 225 },
    { r: 229, g: 229, b: 229 },
    { r: 232, g: 232, b: 232 },
    { r: 235, g: 235, b: 235 },
    { r: 238, g: 238, b: 238 },
    { r: 241, g: 241, b: 241 },
    { r: 244, g: 244, b: 244 },
    { r: 247, g: 247, b: 247 },
    { r: 250, g: 250, b: 250 },
    { r: 253, g: 253, b: 253 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 36, g: 36, b: 36 },
    { r: 54, g: 54, b: 54 },
    { r: 67, g: 67, b: 67 },
    { r: 77, g: 77, b: 77 },
    { r: 88, g: 88, b: 88 },
    { r: 97, g: 97, b: 97 },
    { r: 104, g: 104, b: 104 },
    { r: 112, g: 112, b: 112 },
    { r: 118, g: 118, b: 118 },
    { r: 126, g: 126, b: 126 },
    { r: 131, g: 131, b: 131 },
    { r: 138, g: 138, b: 138 },
    { r: 143, g: 143, b: 143 },
    { r: 149, g: 149, b: 149 },
    { r: 154, g: 154, b: 154 },
    { r: 159, g: 159, b: 159 },
    { r: 164, g: 164, b: 164 },
    { r: 169, g: 169, b: 169 },
    { r: 173, g: 173, b: 173 },
    { r: 178, g: 178, b: 178 },
    { r: 182, g: 182, b: 182 },
    { r: 187, g: 187, b: 187 },
    { r: 191, g: 191, b: 191 },
    { r: 195, g: 195, b: 195 },
    { r: 199, g: 199, b: 199 },
    { r: 203, g: 203, b: 203 },
    { r: 207, g: 207, b: 207 },
    { r: 211, g: 211, b: 211 },
    { r: 214, g: 214, b: 214 },
    { r: 218, g: 218, b: 218 },
    { r: 222, g: 222, b: 222 },
    { r: 226, g: 226, b: 226 },
    { r: 229, g: 229, b: 229 },
    { r: 233, g: 233, b: 233 },
    { r: 236, g: 236, b: 236 },
    { r: 239, g: 239, b: 239 },
    { r: 243, g: 243, b: 243 },
    { r: 246, g: 246, b: 246 },
    { r: 249, g: 249, b: 249 },
    { r: 252, g: 252, b: 252 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 25, g: 29, b: 29 },
    { r: 57, g: 60, b: 61 },
    { r: 75, g: 80, b: 83 },
    { r: 90, g: 97, b: 99 },
    { r: 103, g: 110, b: 113 },
    { r: 115, g: 122, b: 127 },
    { r: 125, g: 134, b: 138 },
    { r: 134, g: 144, b: 148 },
    { r: 143, g: 153, b: 158 },
    { r: 152, g: 163, b: 167 },
    { r: 160, g: 171, b: 176 },
    { r: 167, g: 179, b: 185 },
    { r: 175, g: 186, b: 193 },
    { r: 182, g: 194, b: 200 },
    { r: 188, g: 202, b: 208 },
    { r: 195, g: 208, b: 215 },
    { r: 201, g: 215, b: 222 },
    { r: 207, g: 222, b: 229 },
    { r: 213, g: 228, b: 235 },
    { r: 219, g: 234, b: 241 },
    { r: 224, g: 240, b: 248 },
    { r: 230, g: 246, b: 254 },
    { r: 15, g: 15, b: 15 },
    { r: 34, g: 34, b: 34 },
    { r: 47, g: 47, b: 47 },
    { r: 56, g: 56, b: 56 },
    { r: 64, g: 64, b: 64 },
    { r: 72, g: 72, b: 72 },
    { r: 78, g: 78, b: 78 },
    { r: 84, g: 84, b: 84 },
    { r: 90, g: 90, b: 90 },
    { r: 95, g: 95, b: 95 },
    { r: 100, g: 100, b: 100 },
    { r: 105, g: 105, b: 105 },
    { r: 110, g: 110, b: 110 },
    { r: 114, g: 114, b: 114 },
    { r: 118, g: 118, b: 118 },
    { r: 123, g: 123, b: 123 },
    { r: 126, g: 126, b: 126 },
    { r: 130, g: 130, b: 130 },
    { r: 134, g: 134, b: 134 },
    { r: 138, g: 138, b: 138 },
    { r: 141, g: 141, b: 141 },
    { r: 145, g: 145, b: 145 },
    { r: 148, g: 148, b: 148 },
    { r: 151, g: 151, b: 151 },
    { r: 155, g: 155, b: 155 },
    { r: 158, g: 158, b: 158 },
    { r: 161, g: 161, b: 161 },
    { r: 164, g: 164, b: 164 },
    { r: 167, g: 167, b: 167 },
    { r: 170, g: 170, b: 170 },
    { r: 173, g: 173, b: 173 },
    { r: 175, g: 175, b: 175 },
    { r: 178, g: 178, b: 178 },
    { r: 181, g: 181, b: 181 },
    { r: 184, g: 184, b: 184 },
    { r: 187, g: 187, b: 187 },
    { r: 189, g: 189, b: 189 },
    { r: 192, g: 192, b: 192 },
    { r: 194, g: 194, b: 194 },
    { r: 197, g: 197, b: 197 },
    { r: 199, g: 199, b: 199 },
    { r: 201, g: 201, b: 201 },
    { r: 204, g: 204, b: 204 },
    { r: 206, g: 206, b: 206 },
    { r: 209, g: 209, b: 209 },
    { r: 211, g: 211, b: 211 },
    { r: 213, g: 213, b: 213 },
    { r: 216, g: 216, b: 216 },
    { r: 218, g: 218, b: 218 },
    { r: 220, g: 220, b: 220 },
    { r: 222, g: 222, b: 222 },
    { r: 225, g: 225, b: 225 },
    { r: 227, g: 227, b: 227 },
    { r: 229, g: 229, b: 229 },
    { r: 231, g: 231, b: 231 },
    { r: 233, g: 233, b: 233 },
    { r: 235, g: 235, b: 235 },
    { r: 237, g: 237, b: 237 },
    { r: 239, g: 239, b: 239 },
    { r: 242, g: 242, b: 242 },
    { r: 244, g: 244, b: 244 },
    { r: 246, g: 246, b: 246 },
    { r: 248, g: 248, b: 248 },
    { r: 250, g: 250, b: 250 },
    { r: 252, g: 252, b: 252 },
    { r: 254, g: 254, b: 254 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 19, g: 19, b: 97 },
    { r: 29, g: 33, b: 102 },
    { r: 36, g: 46, b: 106 },
    { r: 45, g: 53, b: 111 },
    { r: 50, g: 59, b: 115 },
    { r: 55, g: 65, b: 119 },
    { r: 59, g: 72, b: 123 },
    { r: 65, g: 77, b: 127 },
    { r: 68, g: 82, b: 131 },
    { r: 72, g: 87, b: 134 },
    { r: 76, g: 92, b: 138 },
    { r: 80, g: 96, b: 141 },
    { r: 83, g: 99, b: 145 },
    { r: 86, g: 104, b: 148 },
    { r: 90, g: 108, b: 151 },
    { r: 92, g: 111, b: 154 },
    { r: 96, g: 114, b: 157 },
    { r: 99, g: 119, b: 160 },
    { r: 101, g: 122, b: 163 },
    { r: 103, g: 125, b: 166 },
    { r: 107, g: 128, b: 169 },
    { r: 109, g: 131, b: 172 },
    { r: 111, g: 134, b: 174 },
    { r: 114, g: 137, b: 177 },
    { r: 117, g: 140, b: 180 },
    { r: 119, g: 143, b: 182 },
    { r: 121, g: 146, b: 185 },
    { r: 124, g: 148, b: 188 },
    { r: 125, g: 151, b: 190 },
    { r: 128, g: 154, b: 193 },
    { r: 130, g: 156, b: 195 },
    { r: 132, g: 158, b: 198 },
    { r: 134, g: 162, b: 200 },
    { r: 136, g: 164, b: 202 },
    { r: 138, g: 166, b: 205 },
    { r: 140, g: 168, b: 207 },
    { r: 142, g: 171, b: 209 },
    { r: 144, g: 173, b: 212 },
    { r: 146, g: 175, b: 214 },
    { r: 148, g: 178, b: 216 },
    { r: 150, g: 180, b: 218 },
    { r: 152, g: 182, b: 220 },
    { r: 118, g: 141, b: 81 },
    { r: 141, g: 153, b: 93 },
    { r: 160, g: 163, b: 104 },
    { r: 178, g: 173, b: 114 },
    { r: 194, g: 182, b: 124 },
    { r: 208, g: 191, b: 132 },
    { r: 222, g: 200, b: 140 },
    { r: 223, g: 200, b: 141 },
    { r: 219, g: 195, b: 138 },
    { r: 214, g: 190, b: 134 },
    { r: 209, g: 186, b: 131 },
    { r: 205, g: 181, b: 127 },
    { r: 200, g: 176, b: 123 },
    { r: 195, g: 171, b: 119 },
    { r: 190, g: 165, b: 116 },
    { r: 185, g: 160, b: 112 },
    { r: 179, g: 155, b: 107 },
    { r: 174, g: 148, b: 103 },
    { r: 168, g: 142, b: 99 },
    { r: 162, g: 136, b: 94 },
    { r: 156, g: 129, b: 89 },
    { r: 150, g: 123, b: 84 },
    { r: 143, g: 114, b: 78 },
    { r: 136, g: 107, b: 72 },
    { r: 129, g: 98, b: 65 },
    { r: 121, g: 89, b: 58 },
    { r: 113, g: 78, b: 49 },
    { r: 104, g: 65, b: 38 },
    { r: 113, g: 81, b: 63 },
    { r: 139, g: 118, b: 109 },
    { r: 161, g: 146, b: 139 },
    { r: 180, g: 169, b: 164 },
    { r: 197, g: 189, b: 186 },
    { r: 213, g: 208, b: 206 },
    { r: 228, g: 225, b: 224 },
    { r: 242, g: 241, b: 240 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 80, g: 80, b: 255 },
    { r: 81, g: 211, b: 96 },
    { r: 112, g: 219, b: 65 },
    { r: 132, g: 226, b: 14 },
    { r: 136, g: 225, b: 16 },
    { r: 139, g: 225, b: 24 },
    { r: 142, g: 224, b: 32 },
    { r: 145, g: 223, b: 36 },
    { r: 148, g: 223, b: 42 },
    { r: 151, g: 222, b: 45 },
    { r: 154, g: 221, b: 49 },
    { r: 156, g: 220, b: 53 },
    { r: 159, g: 220, b: 56 },
    { r: 162, g: 219, b: 59 },
    { r: 164, g: 218, b: 62 },
    { r: 167, g: 218, b: 66 },
    { r: 169, g: 217, b: 68 },
    { r: 172, g: 216, b: 71 },
    { r: 174, g: 215, b: 74 },
    { r: 177, g: 215, b: 76 },
    { r: 179, g: 214, b: 79 },
    { r: 182, g: 213, b: 81 },
    { r: 184, g: 212, b: 83 },
    { r: 186, g: 212, b: 86 },
    { r: 188, g: 211, b: 87 },
    { r: 191, g: 210, b: 90 },
    { r: 193, g: 209, b: 92 },
    { r: 195, g: 209, b: 93 },
    { r: 197, g: 208, b: 96 },
    { r: 199, g: 207, b: 97 },
    { r: 202, g: 207, b: 100 },
    { r: 203, g: 206, b: 101 },
    { r: 205, g: 205, b: 103 },
    { r: 205, g: 205, b: 107 },
    { r: 206, g: 206, b: 110 },
    { r: 206, g: 206, b: 113 },
    { r: 207, g: 207, b: 116 },
    { r: 207, g: 207, b: 119 },
    { r: 207, g: 207, b: 123 },
    { r: 208, g: 208, b: 125 },
    { r: 208, g: 208, b: 128 },
    { r: 209, g: 209, b: 131 },
    { r: 209, g: 209, b: 133 },
    { r: 209, g: 209, b: 136 },
    { r: 210, g: 210, b: 138 },
    { r: 210, g: 210, b: 141 },
    { r: 210, g: 210, b: 144 },
    { r: 211, g: 211, b: 146 },
    { r: 211, g: 211, b: 149 },
    { r: 212, g: 212, b: 151 },
    { r: 212, g: 212, b: 153 },
    { r: 212, g: 212, b: 156 },
    { r: 213, g: 213, b: 157 },
    { r: 213, g: 213, b: 160 },
    { r: 213, g: 213, b: 162 },
    { r: 214, g: 214, b: 164 },
    { r: 214, g: 214, b: 166 },
    { r: 215, g: 215, b: 169 },
    { r: 215, g: 215, b: 171 },
    { r: 215, g: 215, b: 173 },
    { r: 216, g: 216, b: 175 },
    { r: 216, g: 216, b: 176 },
    { r: 216, g: 216, b: 178 },
    { r: 217, g: 217, b: 181 },
    { r: 217, g: 217, b: 183 },
    { r: 218, g: 218, b: 185 },
    { r: 218, g: 218, b: 187 },
    { r: 218, g: 218, b: 188 },
    { r: 219, g: 219, b: 190 },
    { r: 219, g: 219, b: 192 },
    { r: 219, g: 219, b: 194 },
    { r: 220, g: 220, b: 195 },
    { r: 220, g: 220, b: 197 },
    { r: 220, g: 220, b: 199 },
    { r: 220, g: 220, b: 201 },
    { r: 221, g: 221, b: 203 },
    { r: 221, g: 221, b: 205 },
    { r: 222, g: 222, b: 206 },
    { r: 222, g: 222, b: 208 },
    { r: 222, g: 222, b: 210 },
    { r: 223, g: 223, b: 212 },
    { r: 223, g: 223, b: 213 },
    { r: 223, g: 223, b: 215 },
    { r: 224, g: 224, b: 216 },
    { r: 224, g: 224, b: 218 },
    { r: 225, g: 225, b: 220 },
    { r: 225, g: 225, b: 221 },
    { r: 225, g: 225, b: 223 },
    { r: 226, g: 226, b: 225 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 55, g: 55, b: 182 },
    { r: 55, g: 63, b: 184 },
    { r: 55, g: 71, b: 185 },
    { r: 55, g: 77, b: 186 },
    { r: 57, g: 84, b: 187 },
    { r: 57, g: 90, b: 189 },
    { r: 57, g: 96, b: 191 },
    { r: 57, g: 101, b: 192 },
    { r: 59, g: 106, b: 193 },
    { r: 59, g: 110, b: 194 },
    { r: 59, g: 115, b: 196 },
    { r: 59, g: 120, b: 197 },
    { r: 62, g: 124, b: 199 },
    { r: 62, g: 128, b: 200 },
    { r: 62, g: 132, b: 201 },
    { r: 62, g: 136, b: 202 },
    { r: 64, g: 139, b: 204 },
    { r: 64, g: 143, b: 205 },
    { r: 64, g: 147, b: 206 },
    { r: 65, g: 150, b: 208 },
    { r: 66, g: 154, b: 209 },
    { r: 66, g: 157, b: 210 },
    { r: 66, g: 161, b: 212 },
    { r: 67, g: 164, b: 213 },
    { r: 68, g: 167, b: 214 },
    { r: 68, g: 170, b: 215 },
    { r: 68, g: 173, b: 216 },
    { r: 69, g: 176, b: 218 },
    { r: 69, g: 179, b: 219 },
    { r: 69, g: 182, b: 220 },
    { r: 69, g: 184, b: 221 },
    { r: 71, g: 187, b: 222 },
    { r: 71, g: 190, b: 223 },
    { r: 71, g: 193, b: 225 },
    { r: 71, g: 195, b: 226 },
    { r: 73, g: 198, b: 227 },
    { r: 73, g: 200, b: 228 },
    { r: 73, g: 203, b: 229 },
    { r: 42, g: 227, b: 87 },
    { r: 44, g: 222, b: 39 },
    { r: 54, g: 214, b: 44 },
    { r: 61, g: 206, b: 48 },
    { r: 68, g: 198, b: 51 },
    { r: 75, g: 188, b: 55 },
    { r: 80, g: 179, b: 57 },
    { r: 86, g: 169, b: 61 },
    { r: 90, g: 159, b: 64 },
    { r: 95, g: 148, b: 67 },
    { r: 113, g: 147, b: 76 },
    { r: 139, g: 158, b: 90 },
    { r: 161, g: 168, b: 103 },
    { r: 180, g: 177, b: 115 },
    { r: 197, g: 186, b: 126 },
    { r: 213, g: 195, b: 135 },
    { r: 219, g: 199, b: 138 },
    { r: 219, g: 201, b: 138 },
    { r: 219, g: 203, b: 138 },
    { r: 219, g: 206, b: 138 },
    { r: 219, g: 208, b: 138 },
    { r: 219, g: 210, b: 138 },
    { r: 219, g: 212, b: 138 },
    { r: 219, g: 214, b: 138 },
    { r: 199, g: 215, b: 137 },
    { r: 190, g: 211, b: 135 },
    { r: 180, g: 207, b: 132 },
    { r: 171, g: 202, b: 130 },
    { r: 159, g: 198, b: 128 },
    { r: 148, g: 193, b: 125 },
    { r: 135, g: 189, b: 123 },
    { r: 122, g: 184, b: 120 },
    { r: 106, g: 179, b: 117 },
    { r: 89, g: 174, b: 115 },
    { r: 65, g: 169, b: 111 },
    { r: 54, g: 168, b: 112 },
    { r: 76, g: 173, b: 120 },
    { r: 92, g: 178, b: 127 },
    { r: 106, g: 183, b: 133 },
    { r: 118, g: 188, b: 140 },
    { r: 130, g: 193, b: 146 },
    { r: 140, g: 198, b: 152 },
    { r: 149, g: 202, b: 158 },
    { r: 158, g: 206, b: 163 },
    { r: 167, g: 211, b: 168 },
    { r: 175, g: 215, b: 173 },
    { r: 184, g: 220, b: 181 },
    { r: 199, g: 227, b: 197 },
    { r: 214, g: 233, b: 212 },
    { r: 227, g: 240, b: 226 },
    { r: 239, g: 247, b: 239 },
    { r: 251, g: 253, b: 251 },
  ],
  [
    { r: 16, g: 35, b: 134 },
    { r: 75, g: 130, b: 200 },
    { r: 107, g: 166, b: 249 },
    { r: 130, g: 194, b: 251 },
    { r: 147, g: 214, b: 236 },
    { r: 162, g: 228, b: 216 },
    { r: 174, g: 238, b: 192 },
    { r: 185, g: 245, b: 169 },
    { r: 194, g: 250, b: 147 },
    { r: 202, g: 253, b: 127 },
    { r: 210, g: 254, b: 109 },
    { r: 216, g: 254, b: 93 },
    { r: 222, g: 253, b: 78 },
    { r: 227, g: 251, b: 67 },
    { r: 231, g: 248, b: 55 },
    { r: 235, g: 245, b: 48 },
    { r: 238, g: 241, b: 39 },
    { r: 241, g: 237, b: 32 },
    { r: 244, g: 233, b: 27 },
    { r: 246, g: 228, b: 22 },
    { r: 248, g: 223, b: 16 },
    { r: 249, g: 218, b: 14 },
    { r: 251, g: 213, b: 0 },
    { r: 252, g: 207, b: 0 },
    { r: 253, g: 201, b: 0 },
    { r: 253, g: 196, b: 0 },
    { r: 254, g: 191, b: 0 },
    { r: 254, g: 185, b: 0 },
    { r: 254, g: 180, b: 0 },
    { r: 254, g: 174, b: 0 },
    { r: 254, g: 169, b: 0 },
    { r: 254, g: 164, b: 0 },
    { r: 253, g: 159, b: 0 },
    { r: 252, g: 153, b: 0 },
    { r: 252, g: 148, b: 0 },
    { r: 251, g: 143, b: 0 },
    { r: 250, g: 139, b: 0 },
    { r: 249, g: 135, b: 0 },
    { r: 248, g: 130, b: 0 },
    { r: 246, g: 125, b: 0 },
    { r: 245, g: 121, b: 0 },
    { r: 244, g: 117, b: 0 },
    { r: 242, g: 112, b: 0 },
    { r: 241, g: 108, b: 0 },
    { r: 239, g: 105, b: 0 },
    { r: 238, g: 101, b: 0 },
    { r: 236, g: 97, b: 0 },
    { r: 234, g: 93, b: 0 },
    { r: 233, g: 89, b: 0 },
    { r: 231, g: 87, b: 0 },
    { r: 229, g: 83, b: 0 },
    { r: 227, g: 80, b: 0 },
    { r: 226, g: 76, b: 0 },
    { r: 223, g: 73, b: 0 },
    { r: 222, g: 71, b: 0 },
    { r: 220, g: 68, b: 0 },
    { r: 218, g: 66, b: 0 },
    { r: 216, g: 63, b: 0 },
    { r: 214, g: 61, b: 0 },
    { r: 212, g: 57, b: 0 },
    { r: 210, g: 55, b: 0 },
    { r: 208, g: 53, b: 0 },
    { r: 206, g: 51, b: 0 },
    { r: 204, g: 49, b: 0 },
    { r: 201, g: 48, b: 0 },
    { r: 200, g: 45, b: 0 },
    { r: 198, g: 42, b: 0 },
    { r: 195, g: 42, b: 0 },
    { r: 193, g: 39, b: 0 },
    { r: 191, g: 39, b: 0 },
    { r: 189, g: 35, b: 0 },
    { r: 188, g: 35, b: 0 },
    { r: 185, g: 32, b: 0 },
    { r: 183, g: 32, b: 0 },
    { r: 181, g: 32, b: 0 },
    { r: 179, g: 27, b: 0 },
    { r: 177, g: 27, b: 0 },
    { r: 175, g: 27, b: 0 },
    { r: 173, g: 25, b: 0 },
    { r: 171, g: 22, b: 0 },
    { r: 169, g: 22, b: 0 },
    { r: 167, g: 22, b: 0 },
    { r: 165, g: 22, b: 0 },
    { r: 163, g: 17, b: 0 },
    { r: 161, g: 16, b: 0 },
    { r: 159, g: 16, b: 0 },
    { r: 157, g: 16, b: 0 },
    { r: 156, g: 16, b: 0 },
    { r: 153, g: 16, b: 0 },
    { r: 152, g: 0, b: 0 },
  ],
  [
    { r: 183, g: 0, b: 255 },
    { r: 177, g: 0, b: 255 },
    { r: 171, g: 13, b: 254 },
    { r: 165, g: 27, b: 254 },
    { r: 160, g: 37, b: 253 },
    { r: 154, g: 46, b: 252 },
    { r: 149, g: 55, b: 251 },
    { r: 144, g: 64, b: 250 },
    { r: 138, g: 73, b: 249 },
    { r: 132, g: 81, b: 247 },
    { r: 126, g: 89, b: 246 },
    { r: 120, g: 98, b: 244 },
    { r: 114, g: 107, b: 242 },
    { r: 109, g: 115, b: 240 },
    { r: 102, g: 123, b: 238 },
    { r: 96, g: 131, b: 235 },
    { r: 89, g: 138, b: 232 },
    { r: 83, g: 146, b: 229 },
    { r: 77, g: 153, b: 227 },
    { r: 70, g: 160, b: 223 },
    { r: 63, g: 167, b: 220 },
    { r: 57, g: 173, b: 217 },
    { r: 50, g: 180, b: 213 },
    { r: 42, g: 186, b: 209 },
    { r: 36, g: 192, b: 205 },
    { r: 29, g: 198, b: 201 },
    { r: 22, g: 203, b: 197 },
    { r: 16, g: 209, b: 193 },
    { r: 0, g: 214, b: 188 },
    { r: 0, g: 218, b: 184 },
    { r: 0, g: 223, b: 179 },
    { r: 0, g: 227, b: 174 },
    { r: 0, g: 231, b: 169 },
    { r: 19, g: 235, b: 164 },
    { r: 24, g: 238, b: 158 },
    { r: 33, g: 241, b: 153 },
    { r: 39, g: 244, b: 148 },
    { r: 48, g: 246, b: 142 },
    { r: 52, g: 248, b: 137 },
    { r: 59, g: 250, b: 131 },
    { r: 67, g: 252, b: 126 },
    { r: 73, g: 253, b: 120 },
    { r: 79, g: 254, b: 113 },
    { r: 86, g: 254, b: 107 },
    { r: 92, g: 255, b: 101 },
    { r: 98, g: 254, b: 95 },
    { r: 105, g: 254, b: 88 },
    { r: 111, g: 254, b: 82 },
    { r: 117, g: 253, b: 75 },
    { r: 123, g: 251, b: 69 },
    { r: 129, g: 250, b: 63 },
    { r: 135, g: 248, b: 55 },
    { r: 140, g: 245, b: 48 },
    { r: 146, g: 243, b: 42 },
    { r: 151, g: 240, b: 35 },
    { r: 157, g: 237, b: 27 },
    { r: 162, g: 234, b: 22 },
    { r: 167, g: 230, b: 13 },
    { r: 172, g: 226, b: 0 },
    { r: 177, g: 222, b: 0 },
    { r: 182, g: 217, b: 0 },
    { r: 187, g: 212, b: 0 },
    { r: 191, g: 207, b: 12 },
    { r: 196, g: 201, b: 19 },
    { r: 200, g: 196, b: 27 },
    { r: 204, g: 190, b: 35 },
    { r: 208, g: 184, b: 42 },
    { r: 212, g: 177, b: 48 },
    { r: 215, g: 171, b: 55 },
    { r: 219, g: 164, b: 61 },
    { r: 222, g: 158, b: 69 },
    { r: 226, g: 151, b: 74 },
    { r: 228, g: 143, b: 81 },
    { r: 231, g: 135, b: 88 },
    { r: 234, g: 128, b: 94 },
    { r: 237, g: 120, b: 99 },
    { r: 239, g: 111, b: 106 },
    { r: 241, g: 104, b: 112 },
    { r: 243, g: 96, b: 118 },
    { r: 245, g: 86, b: 124 },
    { r: 247, g: 78, b: 130 },
    { r: 248, g: 69, b: 136 },
    { r: 250, g: 60, b: 142 },
    { r: 251, g: 51, b: 147 },
    { r: 252, g: 43, b: 153 },
    { r: 253, g: 33, b: 158 },
    { r: 254, g: 25, b: 163 },
    { r: 254, g: 13, b: 168 },
    { r: 254, g: 0, b: 173 },
    { r: 255, g: 0, b: 179 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 55, g: 0, b: 48 },
    { r: 78, g: 0, b: 72 },
    { r: 96, g: 0, b: 91 },
    { r: 112, g: 0, b: 109 },
    { r: 130, g: 0, b: 134 },
    { r: 136, g: 0, b: 145 },
    { r: 142, g: 0, b: 155 },
    { r: 145, g: 0, b: 165 },
    { r: 149, g: 0, b: 179 },
    { r: 149, g: 0, b: 190 },
    { r: 148, g: 0, b: 198 },
    { r: 146, g: 0, b: 205 },
    { r: 141, g: 0, b: 216 },
    { r: 135, g: 0, b: 225 },
    { r: 127, g: 0, b: 233 },
    { r: 116, g: 0, b: 240 },
    { r: 101, g: 0, b: 248 },
    { r: 84, g: 0, b: 255 },
    { r: 49, g: 0, b: 255 },
    { r: 0, g: 33, b: 255 },
    { r: 0, g: 78, b: 255 },
    { r: 0, g: 101, b: 255 },
    { r: 0, g: 116, b: 255 },
    { r: 0, g: 135, b: 255 },
    { r: 0, g: 153, b: 255 },
    { r: 0, g: 165, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 188, b: 255 },
    { r: 0, g: 201, b: 255 },
    { r: 0, g: 211, b: 255 },
    { r: 0, g: 218, b: 255 },
    { r: 0, g: 230, b: 255 },
    { r: 0, g: 240, b: 255 },
    { r: 0, g: 248, b: 255 },
    { r: 0, g: 254, b: 255 },
    { r: 0, g: 255, b: 248 },
    { r: 0, g: 255, b: 235 },
    { r: 0, g: 255, b: 226 },
    { r: 0, g: 255, b: 220 },
    { r: 0, g: 255, b: 211 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 185 },
    { r: 0, g: 255, b: 176 },
    { r: 0, g: 255, b: 165 },
    { r: 0, g: 255, b: 148 },
    { r: 0, g: 255, b: 136 },
    { r: 0, g: 255, b: 119 },
    { r: 0, g: 255, b: 100 },
    { r: 0, g: 255, b: 70 },
    { r: 0, g: 255, b: 44 },
    { r: 53, g: 255, b: 0 },
    { r: 83, g: 255, b: 0 },
    { r: 109, g: 255, b: 0 },
    { r: 126, g: 255, b: 0 },
    { r: 138, g: 255, b: 0 },
    { r: 155, g: 255, b: 0 },
    { r: 171, g: 255, b: 0 },
    { r: 181, g: 255, b: 0 },
    { r: 190, g: 255, b: 0 },
    { r: 203, g: 255, b: 0 },
    { r: 215, g: 255, b: 0 },
    { r: 223, g: 255, b: 0 },
    { r: 231, g: 255, b: 0 },
    { r: 240, g: 255, b: 0 },
    { r: 252, g: 255, b: 0 },
    { r: 255, g: 252, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 224, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 196, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 168, b: 0 },
    { r: 255, g: 159, b: 0 },
    { r: 255, g: 146, b: 0 },
    { r: 255, g: 127, b: 0 },
    { r: 255, g: 115, b: 0 },
    { r: 255, g: 90, b: 0 },
    { r: 255, g: 65, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
    { r: 232, g: 232, b: 232 },
  ],
  [
    { r: 146, g: 0, b: 202 },
    { r: 146, g: 0, b: 208 },
    { r: 141, g: 0, b: 216 },
    { r: 138, g: 0, b: 223 },
    { r: 131, g: 0, b: 230 },
    { r: 123, g: 0, b: 235 },
    { r: 112, g: 0, b: 242 },
    { r: 102, g: 0, b: 248 },
    { r: 92, g: 0, b: 252 },
    { r: 65, g: 0, b: 255 },
    { r: 31, g: 0, b: 255 },
    { r: 0, g: 37, b: 255 },
    { r: 0, g: 74, b: 255 },
    { r: 0, g: 90, b: 255 },
    { r: 0, g: 105, b: 255 },
    { r: 0, g: 121, b: 255 },
    { r: 0, g: 134, b: 255 },
    { r: 0, g: 150, b: 255 },
    { r: 0, g: 157, b: 255 },
    { r: 0, g: 167, b: 255 },
    { r: 0, g: 175, b: 255 },
    { r: 0, g: 186, b: 255 },
    { r: 0, g: 198, b: 255 },
    { r: 0, g: 204, b: 255 },
    { r: 0, g: 212, b: 255 },
    { r: 0, g: 217, b: 255 },
    { r: 0, g: 225, b: 255 },
    { r: 0, g: 237, b: 255 },
    { r: 0, g: 242, b: 255 },
    { r: 0, g: 249, b: 255 },
    { r: 0, g: 253, b: 255 },
    { r: 0, g: 255, b: 251 },
    { r: 0, g: 255, b: 245 },
    { r: 0, g: 255, b: 234 },
    { r: 0, g: 255, b: 227 },
    { r: 0, g: 255, b: 222 },
    { r: 0, g: 255, b: 215 },
    { r: 0, g: 255, b: 209 },
    { r: 0, g: 255, b: 198 },
    { r: 0, g: 255, b: 186 },
    { r: 0, g: 255, b: 180 },
    { r: 0, g: 255, b: 170 },
    { r: 0, g: 255, b: 164 },
    { r: 0, g: 255, b: 149 },
    { r: 0, g: 255, b: 141 },
    { r: 0, g: 255, b: 125 },
    { r: 0, g: 255, b: 112 },
    { r: 0, g: 255, b: 100 },
    { r: 0, g: 255, b: 74 },
    { r: 0, g: 255, b: 55 },
    { r: 10, g: 255, b: 0 },
    { r: 58, g: 255, b: 0 },
    { r: 81, g: 255, b: 0 },
    { r: 105, g: 255, b: 0 },
    { r: 116, g: 255, b: 0 },
    { r: 129, g: 255, b: 0 },
    { r: 138, g: 255, b: 0 },
    { r: 153, g: 255, b: 0 },
    { r: 166, g: 255, b: 0 },
    { r: 174, g: 255, b: 0 },
    { r: 183, g: 255, b: 0 },
    { r: 190, g: 255, b: 0 },
    { r: 199, g: 255, b: 0 },
    { r: 208, g: 255, b: 0 },
    { r: 217, g: 255, b: 0 },
    { r: 224, g: 255, b: 0 },
    { r: 230, g: 255, b: 0 },
    { r: 237, g: 255, b: 0 },
    { r: 248, g: 255, b: 0 },
    { r: 253, g: 255, b: 0 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 246, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 234, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 203, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 183, b: 0 },
    { r: 255, g: 174, b: 0 },
    { r: 255, g: 164, b: 0 },
    { r: 255, g: 155, b: 0 },
    { r: 255, g: 146, b: 0 },
    { r: 255, g: 133, b: 0 },
    { r: 255, g: 120, b: 0 },
    { r: 255, g: 103, b: 0 },
    { r: 255, g: 87, b: 0 },
    { r: 255, g: 66, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 250, g: 247, b: 252 },
    { r: 244, g: 238, b: 247 },
    { r: 238, g: 229, b: 243 },
    { r: 232, g: 219, b: 239 },
    { r: 226, g: 209, b: 235 },
    { r: 220, g: 198, b: 231 },
    { r: 213, g: 187, b: 226 },
    { r: 206, g: 175, b: 222 },
    { r: 199, g: 162, b: 217 },
    { r: 192, g: 149, b: 212 },
    { r: 184, g: 133, b: 208 },
    { r: 177, g: 116, b: 203 },
    { r: 168, g: 96, b: 198 },
    { r: 160, g: 69, b: 192 },
    { r: 150, g: 23, b: 187 },
    { r: 145, g: 0, b: 191 },
    { r: 139, g: 0, b: 197 },
    { r: 133, g: 0, b: 202 },
    { r: 127, g: 0, b: 208 },
    { r: 121, g: 0, b: 213 },
    { r: 114, g: 0, b: 218 },
    { r: 108, g: 0, b: 223 },
    { r: 100, g: 0, b: 227 },
    { r: 92, g: 0, b: 232 },
    { r: 82, g: 0, b: 237 },
    { r: 72, g: 0, b: 241 },
    { r: 60, g: 0, b: 245 },
    { r: 46, g: 0, b: 250 },
    { r: 23, g: 0, b: 254 },
    { r: 0, g: 44, b: 250 },
    { r: 14, g: 70, b: 242 },
    { r: 16, g: 89, b: 234 },
    { r: 22, g: 104, b: 225 },
    { r: 23, g: 118, b: 215 },
    { r: 27, g: 130, b: 206 },
    { r: 29, g: 142, b: 196 },
    { r: 32, g: 152, b: 185 },
    { r: 34, g: 161, b: 174 },
    { r: 35, g: 171, b: 162 },
    { r: 39, g: 179, b: 149 },
    { r: 39, g: 188, b: 134 },
    { r: 42, g: 195, b: 118 },
    { r: 43, g: 203, b: 99 },
    { r: 45, g: 210, b: 76 },
    { r: 64, g: 215, b: 60 },
    { r: 91, g: 218, b: 58 },
    { r: 112, g: 221, b: 55 },
    { r: 129, g: 224, b: 53 },
    { r: 144, g: 227, b: 53 },
    { r: 158, g: 230, b: 50 },
    { r: 171, g: 233, b: 47 },
    { r: 183, g: 235, b: 44 },
    { r: 194, g: 238, b: 40 },
    { r: 204, g: 241, b: 37 },
    { r: 214, g: 244, b: 32 },
    { r: 224, g: 246, b: 32 },
    { r: 233, g: 249, b: 27 },
    { r: 242, g: 251, b: 21 },
    { r: 250, g: 254, b: 13 },
    { r: 255, g: 254, b: 0 },
    { r: 255, g: 251, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 245, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 238, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 231, b: 0 },
    { r: 255, g: 227, b: 0 },
    { r: 255, g: 224, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 217, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 205, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 191, b: 0 },
    { r: 255, g: 183, b: 0 },
    { r: 255, g: 175, b: 0 },
    { r: 255, g: 167, b: 0 },
    { r: 255, g: 158, b: 0 },
    { r: 255, g: 149, b: 0 },
    { r: 255, g: 140, b: 0 },
    { r: 255, g: 129, b: 0 },
    { r: 255, g: 118, b: 0 },
    { r: 255, g: 106, b: 0 },
    { r: 255, g: 91, b: 0 },
    { r: 255, g: 74, b: 0 },
    { r: 255, g: 53, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 149, g: 0, b: 186 },
    { r: 143, g: 0, b: 193 },
    { r: 137, g: 0, b: 198 },
    { r: 131, g: 0, b: 204 },
    { r: 125, g: 0, b: 210 },
    { r: 118, g: 0, b: 215 },
    { r: 110, g: 0, b: 220 },
    { r: 103, g: 0, b: 225 },
    { r: 94, g: 0, b: 230 },
    { r: 85, g: 0, b: 235 },
    { r: 74, g: 0, b: 240 },
    { r: 62, g: 0, b: 245 },
    { r: 48, g: 0, b: 249 },
    { r: 24, g: 0, b: 254 },
    { r: 13, g: 45, b: 250 },
    { r: 16, g: 72, b: 241 },
    { r: 22, g: 93, b: 232 },
    { r: 23, g: 108, b: 222 },
    { r: 27, g: 122, b: 213 },
    { r: 29, g: 134, b: 202 },
    { r: 32, g: 146, b: 191 },
    { r: 34, g: 157, b: 179 },
    { r: 35, g: 167, b: 167 },
    { r: 38, g: 176, b: 154 },
    { r: 39, g: 185, b: 139 },
    { r: 42, g: 193, b: 122 },
    { r: 43, g: 202, b: 103 },
    { r: 45, g: 210, b: 80 },
    { r: 62, g: 215, b: 75 },
    { r: 93, g: 219, b: 101 },
    { r: 115, g: 222, b: 122 },
    { r: 135, g: 225, b: 140 },
    { r: 151, g: 228, b: 155 },
    { r: 166, g: 231, b: 169 },
    { r: 180, g: 235, b: 182 },
    { r: 193, g: 238, b: 195 },
    { r: 205, g: 241, b: 206 },
    { r: 216, g: 244, b: 217 },
    { r: 227, g: 247, b: 227 },
    { r: 237, g: 250, b: 237 },
    { r: 247, g: 253, b: 247 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 246 },
    { r: 255, g: 255, b: 236 },
    { r: 255, g: 255, b: 226 },
    { r: 255, g: 255, b: 214 },
    { r: 255, g: 255, b: 203 },
    { r: 255, g: 255, b: 190 },
    { r: 255, g: 255, b: 177 },
    { r: 255, g: 255, b: 162 },
    { r: 255, g: 255, b: 147 },
    { r: 255, g: 255, b: 129 },
    { r: 255, g: 255, b: 108 },
    { r: 255, g: 255, b: 83 },
    { r: 255, g: 255, b: 44 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 237, b: 0 },
    { r: 255, g: 234, b: 0 },
    { r: 255, g: 230, b: 0 },
    { r: 255, g: 227, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 212, b: 0 },
    { r: 255, g: 208, b: 0 },
    { r: 255, g: 203, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 186, b: 0 },
    { r: 255, g: 178, b: 0 },
    { r: 255, g: 169, b: 0 },
    { r: 255, g: 159, b: 0 },
    { r: 255, g: 149, b: 0 },
    { r: 255, g: 138, b: 0 },
    { r: 255, g: 126, b: 0 },
    { r: 255, g: 112, b: 0 },
    { r: 255, g: 97, b: 0 },
    { r: 255, g: 79, b: 0 },
    { r: 255, g: 56, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 247, g: 242, b: 249 },
    { r: 237, g: 228, b: 243 },
    { r: 228, g: 212, b: 237 },
    { r: 218, g: 196, b: 230 },
    { r: 208, g: 179, b: 223 },
    { r: 197, g: 159, b: 216 },
    { r: 186, g: 137, b: 208 },
    { r: 174, g: 109, b: 201 },
    { r: 160, g: 72, b: 193 },
    { r: 147, g: 0, b: 186 },
    { r: 139, g: 0, b: 186 },
    { r: 131, g: 0, b: 185 },
    { r: 122, g: 0, b: 185 },
    { r: 112, g: 0, b: 184 },
    { r: 102, g: 0, b: 184 },
    { r: 90, g: 0, b: 183 },
    { r: 77, g: 0, b: 182 },
    { r: 60, g: 0, b: 182 },
    { r: 36, g: 0, b: 181 },
    { r: 0, g: 0, b: 185 },
    { r: 0, g: 0, b: 194 },
    { r: 0, g: 0, b: 202 },
    { r: 0, g: 0, b: 210 },
    { r: 0, g: 0, b: 218 },
    { r: 0, g: 0, b: 225 },
    { r: 0, g: 0, b: 233 },
    { r: 0, g: 0, b: 239 },
    { r: 0, g: 0, b: 246 },
    { r: 0, g: 0, b: 253 },
    { r: 0, g: 64, b: 247 },
    { r: 0, g: 104, b: 233 },
    { r: 0, g: 133, b: 218 },
    { r: 0, g: 156, b: 202 },
    { r: 0, g: 176, b: 185 },
    { r: 0, g: 194, b: 166 },
    { r: 0, g: 210, b: 145 },
    { r: 0, g: 226, b: 120 },
    { r: 0, g: 240, b: 87 },
    { r: 0, g: 253, b: 32 },
    { r: 71, g: 255, b: 0 },
    { r: 106, g: 255, b: 0 },
    { r: 132, g: 255, b: 0 },
    { r: 153, g: 255, b: 0 },
    { r: 172, g: 255, b: 0 },
    { r: 189, g: 255, b: 0 },
    { r: 204, g: 255, b: 0 },
    { r: 219, g: 255, b: 0 },
    { r: 233, g: 255, b: 0 },
    { r: 245, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 245, b: 0 },
    { r: 255, g: 240, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 230, b: 0 },
    { r: 255, g: 225, b: 0 },
    { r: 255, g: 220, b: 0 },
    { r: 255, g: 214, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 253, g: 201, b: 0 },
    { r: 246, g: 190, b: 0 },
    { r: 239, g: 179, b: 0 },
    { r: 233, g: 166, b: 0 },
    { r: 225, g: 153, b: 0 },
    { r: 218, g: 138, b: 0 },
    { r: 210, g: 121, b: 0 },
    { r: 202, g: 102, b: 0 },
    { r: 194, g: 78, b: 0 },
    { r: 185, g: 42, b: 0 },
    { r: 186, g: 0, b: 0 },
    { r: 195, g: 0, b: 0 },
    { r: 203, g: 0, b: 0 },
    { r: 211, g: 0, b: 0 },
    { r: 219, g: 0, b: 0 },
    { r: 226, g: 0, b: 0 },
    { r: 234, g: 0, b: 0 },
    { r: 241, g: 0, b: 0 },
    { r: 247, g: 0, b: 0 },
    { r: 254, g: 0, b: 0 },
    { r: 255, g: 72, b: 72 },
    { r: 255, g: 109, b: 109 },
    { r: 255, g: 137, b: 137 },
    { r: 255, g: 159, b: 159 },
    { r: 255, g: 179, b: 179 },
    { r: 255, g: 196, b: 196 },
    { r: 255, g: 212, b: 212 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 242, b: 242 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 250, g: 250, b: 255 },
    { r: 244, g: 244, b: 255 },
    { r: 238, g: 238, b: 255 },
    { r: 232, g: 232, b: 255 },
    { r: 225, g: 225, b: 255 },
    { r: 219, g: 219, b: 255 },
    { r: 212, g: 212, b: 255 },
    { r: 205, g: 205, b: 255 },
    { r: 198, g: 198, b: 255 },
    { r: 190, g: 190, b: 255 },
    { r: 183, g: 183, b: 255 },
    { r: 174, g: 174, b: 255 },
    { r: 166, g: 166, b: 255 },
    { r: 157, g: 157, b: 255 },
    { r: 147, g: 147, b: 255 },
    { r: 137, g: 137, b: 255 },
    { r: 126, g: 126, b: 255 },
    { r: 114, g: 114, b: 255 },
    { r: 100, g: 100, b: 255 },
    { r: 85, g: 85, b: 255 },
    { r: 64, g: 64, b: 255 },
    { r: 36, g: 36, b: 255 },
    { r: 0, g: 32, b: 253 },
    { r: 13, g: 55, b: 247 },
    { r: 16, g: 71, b: 242 },
    { r: 16, g: 85, b: 236 },
    { r: 22, g: 96, b: 230 },
    { r: 22, g: 106, b: 224 },
    { r: 24, g: 115, b: 218 },
    { r: 27, g: 123, b: 211 },
    { r: 27, g: 132, b: 205 },
    { r: 30, g: 139, b: 198 },
    { r: 32, g: 146, b: 191 },
    { r: 32, g: 153, b: 184 },
    { r: 32, g: 159, b: 176 },
    { r: 35, g: 166, b: 168 },
    { r: 35, g: 172, b: 159 },
    { r: 36, g: 178, b: 151 },
    { r: 39, g: 183, b: 142 },
    { r: 39, g: 189, b: 132 },
    { r: 41, g: 194, b: 120 },
    { r: 42, g: 200, b: 109 },
    { r: 42, g: 205, b: 95 },
    { r: 45, g: 210, b: 80 },
    { r: 46, g: 214, b: 61 },
    { r: 71, g: 216, b: 59 },
    { r: 90, g: 218, b: 59 },
    { r: 105, g: 221, b: 57 },
    { r: 117, g: 223, b: 56 },
    { r: 130, g: 224, b: 54 },
    { r: 140, g: 226, b: 53 },
    { r: 150, g: 228, b: 51 },
    { r: 160, g: 231, b: 48 },
    { r: 169, g: 232, b: 47 },
    { r: 177, g: 234, b: 45 },
    { r: 186, g: 236, b: 44 },
    { r: 193, g: 238, b: 41 },
    { r: 200, g: 240, b: 39 },
    { r: 208, g: 242, b: 37 },
    { r: 215, g: 244, b: 33 },
    { r: 221, g: 246, b: 32 },
    { r: 228, g: 247, b: 27 },
    { r: 234, g: 249, b: 27 },
    { r: 240, g: 251, b: 21 },
    { r: 246, g: 253, b: 16 },
    { r: 253, g: 255, b: 12 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 229, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 208, b: 0 },
    { r: 255, g: 201, b: 0 },
    { r: 255, g: 194, b: 0 },
    { r: 255, g: 187, b: 0 },
    { r: 255, g: 178, b: 0 },
    { r: 255, g: 170, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 152, b: 0 },
    { r: 255, g: 143, b: 0 },
    { r: 255, g: 131, b: 0 },
    { r: 255, g: 120, b: 0 },
    { r: 255, g: 108, b: 0 },
    { r: 255, g: 93, b: 0 },
    { r: 255, g: 75, b: 0 },
    { r: 255, g: 53, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 77, b: 255 },
    { r: 0, g: 110, b: 255 },
    { r: 0, g: 135, b: 255 },
    { r: 0, g: 155, b: 255 },
    { r: 0, g: 174, b: 255 },
    { r: 0, g: 191, b: 255 },
    { r: 0, g: 206, b: 255 },
    { r: 0, g: 220, b: 255 },
    { r: 0, g: 234, b: 255 },
    { r: 0, g: 246, b: 255 },
    { r: 26, g: 255, b: 255 },
    { r: 64, g: 255, b: 255 },
    { r: 86, g: 255, b: 255 },
    { r: 103, g: 255, b: 255 },
    { r: 119, g: 255, b: 255 },
    { r: 132, g: 255, b: 255 },
    { r: 144, g: 255, b: 255 },
    { r: 155, g: 255, b: 255 },
    { r: 166, g: 255, b: 255 },
    { r: 176, g: 255, b: 255 },
    { r: 185, g: 255, b: 255 },
    { r: 194, g: 255, b: 255 },
    { r: 193, g: 255, b: 249 },
    { r: 183, g: 255, b: 237 },
    { r: 173, g: 255, b: 223 },
    { r: 162, g: 255, b: 210 },
    { r: 150, g: 255, b: 195 },
    { r: 138, g: 255, b: 179 },
    { r: 124, g: 255, b: 160 },
    { r: 108, g: 255, b: 140 },
    { r: 90, g: 255, b: 117 },
    { r: 68, g: 255, b: 87 },
    { r: 31, g: 255, b: 39 },
    { r: 58, g: 255, b: 0 },
    { r: 87, g: 255, b: 0 },
    { r: 110, g: 255, b: 0 },
    { r: 129, g: 255, b: 0 },
    { r: 145, g: 255, b: 0 },
    { r: 160, g: 255, b: 0 },
    { r: 174, g: 255, b: 0 },
    { r: 186, g: 255, b: 0 },
    { r: 197, g: 255, b: 0 },
    { r: 209, g: 255, b: 0 },
    { r: 219, g: 255, b: 0 },
    { r: 229, g: 255, b: 0 },
    { r: 230, g: 255, b: 0 },
    { r: 232, g: 255, b: 0 },
    { r: 235, g: 255, b: 0 },
    { r: 236, g: 255, b: 0 },
    { r: 238, g: 255, b: 0 },
    { r: 240, g: 255, b: 0 },
    { r: 242, g: 255, b: 0 },
    { r: 244, g: 255, b: 0 },
    { r: 246, g: 255, b: 0 },
    { r: 248, g: 255, b: 0 },
    { r: 249, g: 255, b: 0 },
    { r: 251, g: 255, b: 0 },
    { r: 253, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 253, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 240, b: 0 },
    { r: 255, g: 235, b: 0 },
    { r: 255, g: 231, b: 0 },
    { r: 255, g: 226, b: 0 },
    { r: 255, g: 221, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 211, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 201, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 190, b: 0 },
    { r: 255, g: 184, b: 0 },
    { r: 255, g: 178, b: 0 },
    { r: 255, g: 172, b: 0 },
    { r: 255, g: 165, b: 0 },
    { r: 255, g: 158, b: 0 },
    { r: 255, g: 151, b: 0 },
    { r: 255, g: 143, b: 0 },
    { r: 255, g: 135, b: 0 },
    { r: 255, g: 126, b: 0 },
    { r: 255, g: 117, b: 0 },
    { r: 255, g: 106, b: 0 },
    { r: 255, g: 95, b: 0 },
    { r: 255, g: 82, b: 0 },
    { r: 255, g: 67, b: 0 },
    { r: 255, g: 47, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 65, b: 255 },
    { r: 0, g: 94, b: 255 },
    { r: 0, g: 115, b: 255 },
    { r: 0, g: 133, b: 255 },
    { r: 0, g: 149, b: 255 },
    { r: 0, g: 162, b: 255 },
    { r: 0, g: 176, b: 255 },
    { r: 0, g: 188, b: 255 },
    { r: 0, g: 200, b: 255 },
    { r: 0, g: 210, b: 255 },
    { r: 0, g: 220, b: 255 },
    { r: 0, g: 231, b: 255 },
    { r: 0, g: 240, b: 255 },
    { r: 0, g: 249, b: 255 },
    { r: 0, g: 255, b: 254 },
    { r: 14, g: 252, b: 245 },
    { r: 16, g: 249, b: 237 },
    { r: 22, g: 247, b: 227 },
    { r: 22, g: 244, b: 218 },
    { r: 27, g: 241, b: 209 },
    { r: 27, g: 239, b: 198 },
    { r: 32, g: 236, b: 187 },
    { r: 33, g: 233, b: 176 },
    { r: 35, g: 231, b: 164 },
    { r: 38, g: 227, b: 150 },
    { r: 39, g: 224, b: 136 },
    { r: 42, g: 222, b: 120 },
    { r: 42, g: 219, b: 101 },
    { r: 45, g: 215, b: 77 },
    { r: 63, g: 215, b: 61 },
    { r: 90, g: 219, b: 59 },
    { r: 111, g: 221, b: 56 },
    { r: 128, g: 224, b: 53 },
    { r: 144, g: 227, b: 53 },
    { r: 157, g: 230, b: 50 },
    { r: 170, g: 233, b: 46 },
    { r: 182, g: 235, b: 45 },
    { r: 193, g: 238, b: 42 },
    { r: 204, g: 241, b: 38 },
    { r: 213, g: 243, b: 34 },
    { r: 223, g: 246, b: 32 },
    { r: 232, g: 249, b: 27 },
    { r: 241, g: 251, b: 21 },
    { r: 249, g: 254, b: 12 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 252, b: 0 },
    { r: 255, g: 248, b: 0 },
    { r: 255, g: 245, b: 0 },
    { r: 255, g: 242, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 232, b: 0 },
    { r: 255, g: 229, b: 0 },
    { r: 255, g: 225, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 218, b: 0 },
    { r: 255, g: 215, b: 0 },
    { r: 255, g: 211, b: 0 },
    { r: 255, g: 207, b: 0 },
    { r: 255, g: 202, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 187, b: 0 },
    { r: 255, g: 179, b: 0 },
    { r: 255, g: 171, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 153, b: 0 },
    { r: 255, g: 144, b: 0 },
    { r: 255, g: 133, b: 0 },
    { r: 255, g: 122, b: 0 },
    { r: 255, g: 110, b: 0 },
    { r: 255, g: 96, b: 0 },
    { r: 255, g: 80, b: 0 },
    { r: 255, g: 59, b: 0 },
    { r: 255, g: 26, b: 0 },
    { r: 251, g: 0, b: 41 },
    { r: 245, g: 0, b: 63 },
    { r: 239, g: 0, b: 81 },
    { r: 233, g: 0, b: 94 },
    { r: 227, g: 0, b: 105 },
    { r: 220, g: 0, b: 117 },
    { r: 214, g: 0, b: 126 },
    { r: 206, g: 0, b: 135 },
    { r: 199, g: 0, b: 143 },
    { r: 192, g: 0, b: 152 },
    { r: 184, g: 0, b: 159 },
    { r: 176, g: 0, b: 166 },
    { r: 167, g: 0, b: 173 },
    { r: 158, g: 0, b: 180 },
    { r: 149, g: 0, b: 186 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 7, g: 50, b: 249 },
    { r: 16, g: 71, b: 242 },
    { r: 16, g: 87, b: 234 },
    { r: 22, g: 101, b: 227 },
    { r: 22, g: 113, b: 219 },
    { r: 27, g: 124, b: 211 },
    { r: 27, g: 134, b: 203 },
    { r: 31, g: 143, b: 194 },
    { r: 32, g: 152, b: 185 },
    { r: 33, g: 160, b: 176 },
    { r: 35, g: 168, b: 166 },
    { r: 35, g: 175, b: 155 },
    { r: 39, g: 182, b: 143 },
    { r: 39, g: 189, b: 131 },
    { r: 42, g: 196, b: 117 },
    { r: 42, g: 202, b: 101 },
    { r: 45, g: 209, b: 83 },
    { r: 49, g: 214, b: 62 },
    { r: 77, g: 217, b: 59 },
    { r: 97, g: 220, b: 58 },
    { r: 114, g: 222, b: 55 },
    { r: 129, g: 224, b: 55 },
    { r: 142, g: 227, b: 53 },
    { r: 153, g: 229, b: 50 },
    { r: 165, g: 231, b: 48 },
    { r: 175, g: 234, b: 45 },
    { r: 185, g: 236, b: 44 },
    { r: 194, g: 238, b: 40 },
    { r: 203, g: 241, b: 39 },
    { r: 212, g: 243, b: 35 },
    { r: 220, g: 245, b: 32 },
    { r: 228, g: 247, b: 27 },
    { r: 236, g: 250, b: 23 },
    { r: 243, g: 251, b: 22 },
    { r: 250, g: 254, b: 13 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 252, b: 0 },
    { r: 255, g: 249, b: 0 },
    { r: 255, g: 247, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 242, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 236, b: 0 },
    { r: 255, g: 233, b: 0 },
    { r: 255, g: 231, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 224, b: 0 },
    { r: 255, g: 222, b: 0 },
    { r: 255, g: 219, b: 0 },
    { r: 255, g: 215, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 206, b: 0 },
    { r: 255, g: 201, b: 0 },
    { r: 255, g: 195, b: 0 },
    { r: 255, g: 189, b: 0 },
    { r: 255, g: 182, b: 0 },
    { r: 255, g: 176, b: 0 },
    { r: 255, g: 169, b: 0 },
    { r: 255, g: 162, b: 0 },
    { r: 255, g: 154, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 138, b: 0 },
    { r: 255, g: 129, b: 0 },
    { r: 255, g: 120, b: 0 },
    { r: 255, g: 110, b: 0 },
    { r: 255, g: 98, b: 0 },
    { r: 255, g: 86, b: 0 },
    { r: 255, g: 71, b: 0 },
    { r: 255, g: 50, b: 0 },
    { r: 255, g: 15, b: 0 },
    { r: 251, g: 0, b: 42 },
    { r: 246, g: 0, b: 61 },
    { r: 242, g: 0, b: 74 },
    { r: 236, g: 0, b: 87 },
    { r: 231, g: 0, b: 97 },
    { r: 226, g: 0, b: 107 },
    { r: 221, g: 0, b: 115 },
    { r: 215, g: 0, b: 124 },
    { r: 209, g: 0, b: 131 },
    { r: 204, g: 0, b: 139 },
    { r: 198, g: 0, b: 146 },
    { r: 192, g: 0, b: 152 },
    { r: 185, g: 0, b: 158 },
    { r: 179, g: 0, b: 164 },
    { r: 171, g: 0, b: 170 },
    { r: 164, g: 0, b: 176 },
    { r: 157, g: 0, b: 181 },
    { r: 149, g: 0, b: 186 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 76 },
    { r: 0, g: 0, b: 108 },
    { r: 0, g: 0, b: 132 },
    { r: 0, g: 0, b: 153 },
    { r: 0, g: 0, b: 171 },
    { r: 0, g: 0, b: 187 },
    { r: 0, g: 0, b: 202 },
    { r: 0, g: 0, b: 216 },
    { r: 0, g: 0, b: 229 },
    { r: 0, g: 0, b: 242 },
    { r: 0, g: 0, b: 253 },
    { r: 0, g: 68, b: 255 },
    { r: 0, g: 103, b: 255 },
    { r: 0, g: 128, b: 255 },
    { r: 0, g: 149, b: 255 },
    { r: 0, g: 167, b: 255 },
    { r: 0, g: 184, b: 255 },
    { r: 0, g: 199, b: 255 },
    { r: 0, g: 214, b: 255 },
    { r: 0, g: 227, b: 255 },
    { r: 0, g: 239, b: 255 },
    { r: 0, g: 251, b: 255 },
    { r: 10, g: 253, b: 248 },
    { r: 16, g: 250, b: 237 },
    { r: 22, g: 246, b: 225 },
    { r: 27, g: 243, b: 213 },
    { r: 29, g: 239, b: 199 },
    { r: 32, g: 235, b: 185 },
    { r: 35, g: 232, b: 170 },
    { r: 36, g: 228, b: 153 },
    { r: 39, g: 224, b: 133 },
    { r: 42, g: 220, b: 111 },
    { r: 44, g: 216, b: 83 },
    { r: 68, g: 216, b: 59 },
    { r: 102, g: 220, b: 57 },
    { r: 126, g: 224, b: 54 },
    { r: 147, g: 227, b: 51 },
    { r: 165, g: 231, b: 48 },
    { r: 181, g: 235, b: 44 },
    { r: 196, g: 239, b: 41 },
    { r: 210, g: 243, b: 35 },
    { r: 223, g: 246, b: 32 },
    { r: 235, g: 250, b: 22 },
    { r: 247, g: 253, b: 16 },
    { r: 255, g: 254, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 246, b: 0 },
    { r: 255, g: 241, b: 0 },
    { r: 255, g: 237, b: 0 },
    { r: 255, g: 232, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 223, b: 0 },
    { r: 255, g: 218, b: 0 },
    { r: 255, g: 213, b: 0 },
    { r: 255, g: 208, b: 0 },
    { r: 255, g: 200, b: 0 },
    { r: 255, g: 191, b: 0 },
    { r: 255, g: 180, b: 0 },
    { r: 255, g: 170, b: 0 },
    { r: 255, g: 158, b: 0 },
    { r: 255, g: 146, b: 0 },
    { r: 255, g: 132, b: 0 },
    { r: 255, g: 117, b: 0 },
    { r: 255, g: 100, b: 0 },
    { r: 255, g: 78, b: 0 },
    { r: 255, g: 49, b: 0 },
    { r: 253, g: 0, b: 32 },
    { r: 245, g: 0, b: 64 },
    { r: 237, g: 0, b: 85 },
    { r: 229, g: 0, b: 102 },
    { r: 220, g: 0, b: 116 },
    { r: 211, g: 0, b: 129 },
    { r: 202, g: 0, b: 140 },
    { r: 192, g: 0, b: 151 },
    { r: 182, g: 0, b: 161 },
    { r: 171, g: 0, b: 170 },
    { r: 159, g: 0, b: 179 },
    { r: 151, g: 32, b: 188 },
    { r: 163, g: 83, b: 195 },
    { r: 175, g: 113, b: 202 },
    { r: 186, g: 136, b: 208 },
    { r: 195, g: 156, b: 215 },
    { r: 205, g: 174, b: 221 },
    { r: 214, g: 190, b: 227 },
    { r: 224, g: 205, b: 233 },
    { r: 232, g: 219, b: 239 },
    { r: 240, g: 231, b: 245 },
    { r: 248, g: 244, b: 250 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 54, g: 0, b: 68 },
    { r: 89, g: 0, b: 111 },
    { r: 113, g: 0, b: 142 },
    { r: 134, g: 0, b: 167 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 149, g: 0, b: 186 },
    { r: 148, g: 25, b: 185 },
    { r: 144, g: 59, b: 180 },
    { r: 139, g: 79, b: 175 },
    { r: 135, g: 95, b: 169 },
    { r: 130, g: 109, b: 163 },
    { r: 125, g: 122, b: 158 },
    { r: 120, g: 133, b: 151 },
    { r: 115, g: 143, b: 144 },
    { r: 110, g: 153, b: 138 },
    { r: 103, g: 161, b: 131 },
    { r: 97, g: 170, b: 123 },
    { r: 90, g: 178, b: 115 },
    { r: 84, g: 186, b: 107 },
    { r: 75, g: 193, b: 97 },
    { r: 67, g: 201, b: 86 },
    { r: 56, g: 207, b: 75 },
    { r: 45, g: 214, b: 62 },
    { r: 45, g: 214, b: 62 },
    { r: 45, g: 214, b: 62 },
    { r: 45, g: 214, b: 62 },
    { r: 45, g: 214, b: 62 },
    { r: 45, g: 214, b: 62 },
    { r: 68, g: 216, b: 60 },
    { r: 91, g: 218, b: 58 },
    { r: 111, g: 221, b: 57 },
    { r: 128, g: 224, b: 55 },
    { r: 142, g: 227, b: 52 },
    { r: 155, g: 229, b: 49 },
    { r: 167, g: 232, b: 47 },
    { r: 179, g: 235, b: 45 },
    { r: 189, g: 237, b: 43 },
    { r: 199, g: 239, b: 40 },
    { r: 209, g: 242, b: 36 },
    { r: 218, g: 245, b: 32 },
    { r: 227, g: 247, b: 27 },
    { r: 235, g: 249, b: 26 },
    { r: 244, g: 252, b: 20 },
    { r: 251, g: 254, b: 12 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 250, b: 0 },
    { r: 255, g: 239, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 216, b: 0 },
    { r: 255, g: 204, b: 0 },
    { r: 255, g: 191, b: 0 },
    { r: 255, g: 177, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 144, b: 0 },
    { r: 255, g: 125, b: 0 },
    { r: 255, g: 102, b: 0 },
    { r: 255, g: 72, b: 0 },
    { r: 255, g: 10, b: 0 },
    { r: 255, g: 44, b: 74 },
    { r: 254, g: 60, b: 106 },
    { r: 253, g: 75, b: 130 },
    { r: 253, g: 87, b: 150 },
    { r: 252, g: 97, b: 168 },
    { r: 251, g: 107, b: 184 },
    { r: 251, g: 115, b: 199 },
    { r: 250, g: 123, b: 213 },
    { r: 249, g: 131, b: 226 },
    { r: 248, g: 137, b: 238 },
    { r: 248, g: 147, b: 248 },
    { r: 249, g: 167, b: 249 },
    { r: 250, g: 185, b: 250 },
    { r: 252, g: 201, b: 252 },
    { r: 252, g: 216, b: 252 },
    { r: 253, g: 230, b: 253 },
    { r: 254, g: 243, b: 254 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 181 },
    { r: 0, g: 0, b: 185 },
    { r: 0, g: 0, b: 189 },
    { r: 0, g: 0, b: 193 },
    { r: 0, g: 0, b: 196 },
    { r: 0, g: 0, b: 200 },
    { r: 0, g: 0, b: 204 },
    { r: 0, g: 0, b: 208 },
    { r: 0, g: 0, b: 211 },
    { r: 0, g: 0, b: 215 },
    { r: 0, g: 0, b: 218 },
    { r: 0, g: 0, b: 221 },
    { r: 0, g: 0, b: 225 },
    { r: 0, g: 0, b: 228 },
    { r: 0, g: 0, b: 231 },
    { r: 0, g: 0, b: 234 },
    { r: 0, g: 0, b: 237 },
    { r: 0, g: 0, b: 241 },
    { r: 0, g: 0, b: 244 },
    { r: 0, g: 0, b: 247 },
    { r: 0, g: 0, b: 250 },
    { r: 0, g: 0, b: 253 },
    { r: 0, g: 0, b: 255 },
    { r: 53, g: 53, b: 255 },
    { r: 76, g: 76, b: 255 },
    { r: 94, g: 94, b: 255 },
    { r: 108, g: 108, b: 255 },
    { r: 121, g: 121, b: 255 },
    { r: 132, g: 132, b: 255 },
    { r: 144, g: 144, b: 255 },
    { r: 154, g: 154, b: 255 },
    { r: 163, g: 163, b: 255 },
    { r: 172, g: 172, b: 255 },
    { r: 180, g: 180, b: 255 },
    { r: 188, g: 188, b: 255 },
    { r: 196, g: 196, b: 255 },
    { r: 203, g: 203, b: 255 },
    { r: 210, g: 210, b: 255 },
    { r: 218, g: 218, b: 255 },
    { r: 224, g: 224, b: 255 },
    { r: 231, g: 231, b: 255 },
    { r: 237, g: 237, b: 255 },
    { r: 243, g: 243, b: 255 },
    { r: 249, g: 249, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 249, b: 249 },
    { r: 255, g: 243, b: 243 },
    { r: 255, g: 237, b: 237 },
    { r: 255, g: 231, b: 231 },
    { r: 255, g: 224, b: 224 },
    { r: 255, g: 218, b: 218 },
    { r: 255, g: 210, b: 210 },
    { r: 255, g: 203, b: 203 },
    { r: 255, g: 196, b: 196 },
    { r: 255, g: 188, b: 188 },
    { r: 255, g: 180, b: 180 },
    { r: 255, g: 172, b: 172 },
    { r: 255, g: 163, b: 163 },
    { r: 255, g: 154, b: 154 },
    { r: 255, g: 144, b: 144 },
    { r: 255, g: 132, b: 132 },
    { r: 255, g: 121, b: 121 },
    { r: 255, g: 108, b: 108 },
    { r: 255, g: 94, b: 94 },
    { r: 255, g: 76, b: 76 },
    { r: 255, g: 53, b: 53 },
    { r: 255, g: 0, b: 0 },
    { r: 253, g: 0, b: 0 },
    { r: 250, g: 0, b: 0 },
    { r: 247, g: 0, b: 0 },
    { r: 244, g: 0, b: 0 },
    { r: 241, g: 0, b: 0 },
    { r: 237, g: 0, b: 0 },
    { r: 234, g: 0, b: 0 },
    { r: 231, g: 0, b: 0 },
    { r: 228, g: 0, b: 0 },
    { r: 225, g: 0, b: 0 },
    { r: 221, g: 0, b: 0 },
    { r: 218, g: 0, b: 0 },
    { r: 215, g: 0, b: 0 },
    { r: 211, g: 0, b: 0 },
    { r: 208, g: 0, b: 0 },
    { r: 204, g: 0, b: 0 },
    { r: 200, g: 0, b: 0 },
    { r: 196, g: 0, b: 0 },
    { r: 193, g: 0, b: 0 },
    { r: 189, g: 0, b: 0 },
    { r: 185, g: 0, b: 0 },
    { r: 181, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 253, g: 253, b: 255 },
    { r: 250, g: 250, b: 255 },
    { r: 246, g: 246, b: 255 },
    { r: 244, g: 244, b: 255 },
    { r: 241, g: 241, b: 255 },
    { r: 238, g: 238, b: 255 },
    { r: 234, g: 234, b: 255 },
    { r: 231, g: 231, b: 255 },
    { r: 228, g: 228, b: 255 },
    { r: 225, g: 225, b: 255 },
    { r: 221, g: 221, b: 255 },
    { r: 218, g: 218, b: 255 },
    { r: 214, g: 214, b: 255 },
    { r: 211, g: 211, b: 255 },
    { r: 208, g: 208, b: 255 },
    { r: 204, g: 204, b: 255 },
    { r: 200, g: 200, b: 255 },
    { r: 196, g: 196, b: 255 },
    { r: 193, g: 193, b: 255 },
    { r: 189, g: 189, b: 255 },
    { r: 185, g: 185, b: 255 },
    { r: 181, g: 181, b: 255 },
    { r: 177, g: 177, b: 255 },
    { r: 172, g: 172, b: 255 },
    { r: 168, g: 168, b: 255 },
    { r: 163, g: 163, b: 255 },
    { r: 159, g: 159, b: 255 },
    { r: 154, g: 154, b: 255 },
    { r: 149, g: 149, b: 255 },
    { r: 144, g: 144, b: 255 },
    { r: 139, g: 139, b: 255 },
    { r: 134, g: 134, b: 255 },
    { r: 128, g: 128, b: 255 },
    { r: 121, g: 121, b: 255 },
    { r: 115, g: 115, b: 255 },
    { r: 109, g: 109, b: 255 },
    { r: 102, g: 102, b: 255 },
    { r: 94, g: 94, b: 255 },
    { r: 86, g: 86, b: 255 },
    { r: 77, g: 77, b: 255 },
    { r: 67, g: 67, b: 255 },
    { r: 54, g: 54, b: 255 },
    { r: 38, g: 38, b: 255 },
    { r: 8, g: 8, b: 255 },
    { r: 255, g: 8, b: 8 },
    { r: 255, g: 38, b: 38 },
    { r: 255, g: 54, b: 54 },
    { r: 255, g: 67, b: 67 },
    { r: 255, g: 77, b: 77 },
    { r: 255, g: 86, b: 86 },
    { r: 255, g: 94, b: 94 },
    { r: 255, g: 102, b: 102 },
    { r: 255, g: 109, b: 109 },
    { r: 255, g: 115, b: 115 },
    { r: 255, g: 121, b: 121 },
    { r: 255, g: 128, b: 128 },
    { r: 255, g: 134, b: 134 },
    { r: 255, g: 139, b: 139 },
    { r: 255, g: 144, b: 144 },
    { r: 255, g: 149, b: 149 },
    { r: 255, g: 154, b: 154 },
    { r: 255, g: 159, b: 159 },
    { r: 255, g: 163, b: 163 },
    { r: 255, g: 168, b: 168 },
    { r: 255, g: 172, b: 172 },
    { r: 255, g: 177, b: 177 },
    { r: 255, g: 181, b: 181 },
    { r: 255, g: 185, b: 185 },
    { r: 255, g: 189, b: 189 },
    { r: 255, g: 193, b: 193 },
    { r: 255, g: 196, b: 196 },
    { r: 255, g: 200, b: 200 },
    { r: 255, g: 204, b: 204 },
    { r: 255, g: 208, b: 208 },
    { r: 255, g: 211, b: 211 },
    { r: 255, g: 214, b: 214 },
    { r: 255, g: 218, b: 218 },
    { r: 255, g: 221, b: 221 },
    { r: 255, g: 225, b: 225 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 231, b: 231 },
    { r: 255, g: 234, b: 234 },
    { r: 255, g: 238, b: 238 },
    { r: 255, g: 241, b: 241 },
    { r: 255, g: 244, b: 244 },
    { r: 255, g: 246, b: 246 },
    { r: 255, g: 250, b: 250 },
    { r: 255, g: 253, b: 253 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 0, g: 0, b: 181 },
    { r: 0, g: 0, b: 185 },
    { r: 0, g: 0, b: 188 },
    { r: 0, g: 0, b: 192 },
    { r: 0, g: 0, b: 195 },
    { r: 0, g: 0, b: 199 },
    { r: 0, g: 0, b: 203 },
    { r: 0, g: 0, b: 206 },
    { r: 0, g: 0, b: 210 },
    { r: 0, g: 0, b: 213 },
    { r: 0, g: 0, b: 216 },
    { r: 0, g: 0, b: 219 },
    { r: 0, g: 0, b: 222 },
    { r: 0, g: 0, b: 225 },
    { r: 0, g: 0, b: 228 },
    { r: 0, g: 0, b: 231 },
    { r: 0, g: 0, b: 234 },
    { r: 0, g: 0, b: 237 },
    { r: 0, g: 0, b: 241 },
    { r: 0, g: 0, b: 243 },
    { r: 0, g: 0, b: 246 },
    { r: 0, g: 0, b: 249 },
    { r: 0, g: 0, b: 252 },
    { r: 0, g: 0, b: 254 },
    { r: 39, g: 39, b: 255 },
    { r: 65, g: 65, b: 255 },
    { r: 85, g: 85, b: 255 },
    { r: 100, g: 100, b: 255 },
    { r: 113, g: 113, b: 255 },
    { r: 124, g: 124, b: 255 },
    { r: 135, g: 135, b: 255 },
    { r: 145, g: 145, b: 255 },
    { r: 155, g: 155, b: 255 },
    { r: 163, g: 163, b: 255 },
    { r: 171, g: 171, b: 255 },
    { r: 179, g: 179, b: 255 },
    { r: 187, g: 187, b: 255 },
    { r: 194, g: 194, b: 255 },
    { r: 201, g: 201, b: 255 },
    { r: 208, g: 208, b: 255 },
    { r: 215, g: 215, b: 255 },
    { r: 221, g: 221, b: 255 },
    { r: 228, g: 228, b: 255 },
    { r: 233, g: 233, b: 255 },
    { r: 239, g: 239, b: 255 },
    { r: 255, g: 239, b: 239 },
    { r: 255, g: 233, b: 233 },
    { r: 255, g: 228, b: 228 },
    { r: 255, g: 221, b: 221 },
    { r: 255, g: 215, b: 215 },
    { r: 255, g: 208, b: 208 },
    { r: 255, g: 201, b: 201 },
    { r: 255, g: 194, b: 194 },
    { r: 255, g: 187, b: 187 },
    { r: 255, g: 179, b: 179 },
    { r: 255, g: 171, b: 171 },
    { r: 255, g: 163, b: 163 },
    { r: 255, g: 155, b: 155 },
    { r: 255, g: 145, b: 145 },
    { r: 255, g: 135, b: 135 },
    { r: 255, g: 124, b: 124 },
    { r: 255, g: 113, b: 113 },
    { r: 255, g: 100, b: 100 },
    { r: 255, g: 85, b: 85 },
    { r: 255, g: 65, b: 65 },
    { r: 255, g: 39, b: 39 },
    { r: 254, g: 0, b: 0 },
    { r: 252, g: 0, b: 0 },
    { r: 249, g: 0, b: 0 },
    { r: 246, g: 0, b: 0 },
    { r: 243, g: 0, b: 0 },
    { r: 241, g: 0, b: 0 },
    { r: 237, g: 0, b: 0 },
    { r: 234, g: 0, b: 0 },
    { r: 231, g: 0, b: 0 },
    { r: 228, g: 0, b: 0 },
    { r: 225, g: 0, b: 0 },
    { r: 222, g: 0, b: 0 },
    { r: 219, g: 0, b: 0 },
    { r: 216, g: 0, b: 0 },
    { r: 213, g: 0, b: 0 },
    { r: 210, g: 0, b: 0 },
    { r: 206, g: 0, b: 0 },
    { r: 203, g: 0, b: 0 },
    { r: 199, g: 0, b: 0 },
    { r: 195, g: 0, b: 0 },
    { r: 192, g: 0, b: 0 },
    { r: 188, g: 0, b: 0 },
    { r: 185, g: 0, b: 0 },
    { r: 181, g: 0, b: 0 },
  ],
  [
    { r: 80, g: 255, b: 145 },
    { r: 87, g: 255, b: 148 },
    { r: 92, g: 255, b: 150 },
    { r: 98, g: 255, b: 154 },
    { r: 104, g: 255, b: 156 },
    { r: 108, g: 255, b: 158 },
    { r: 114, g: 255, b: 161 },
    { r: 119, g: 255, b: 164 },
    { r: 123, g: 255, b: 167 },
    { r: 128, g: 255, b: 169 },
    { r: 132, g: 255, b: 172 },
    { r: 136, g: 255, b: 174 },
    { r: 140, g: 255, b: 176 },
    { r: 144, g: 255, b: 178 },
    { r: 148, g: 255, b: 181 },
    { r: 151, g: 255, b: 183 },
    { r: 155, g: 255, b: 185 },
    { r: 158, g: 255, b: 187 },
    { r: 162, g: 255, b: 190 },
    { r: 165, g: 255, b: 192 },
    { r: 169, g: 255, b: 194 },
    { r: 172, g: 255, b: 196 },
    { r: 175, g: 255, b: 198 },
    { r: 178, g: 255, b: 200 },
    { r: 181, g: 255, b: 202 },
    { r: 184, g: 255, b: 204 },
    { r: 187, g: 255, b: 206 },
    { r: 190, g: 255, b: 209 },
    { r: 193, g: 255, b: 210 },
    { r: 196, g: 255, b: 212 },
    { r: 199, g: 255, b: 214 },
    { r: 202, g: 255, b: 216 },
    { r: 204, g: 255, b: 218 },
    { r: 207, g: 255, b: 220 },
    { r: 210, g: 255, b: 222 },
    { r: 212, g: 255, b: 224 },
    { r: 215, g: 255, b: 225 },
    { r: 217, g: 255, b: 228 },
    { r: 220, g: 255, b: 229 },
    { r: 222, g: 255, b: 231 },
    { r: 225, g: 255, b: 233 },
    { r: 227, g: 255, b: 234 },
    { r: 230, g: 255, b: 236 },
    { r: 232, g: 255, b: 238 },
    { r: 235, g: 255, b: 240 },
    { r: 253, g: 255, b: 235 },
    { r: 252, g: 255, b: 232 },
    { r: 252, g: 255, b: 230 },
    { r: 252, g: 255, b: 227 },
    { r: 251, g: 255, b: 225 },
    { r: 251, g: 255, b: 222 },
    { r: 251, g: 255, b: 220 },
    { r: 251, g: 255, b: 217 },
    { r: 250, g: 255, b: 215 },
    { r: 250, g: 255, b: 212 },
    { r: 250, g: 255, b: 210 },
    { r: 250, g: 255, b: 207 },
    { r: 249, g: 255, b: 204 },
    { r: 249, g: 255, b: 202 },
    { r: 249, g: 255, b: 199 },
    { r: 248, g: 255, b: 196 },
    { r: 248, g: 255, b: 193 },
    { r: 248, g: 255, b: 190 },
    { r: 248, g: 255, b: 187 },
    { r: 247, g: 255, b: 184 },
    { r: 247, g: 255, b: 181 },
    { r: 247, g: 255, b: 178 },
    { r: 247, g: 255, b: 175 },
    { r: 246, g: 255, b: 172 },
    { r: 246, g: 255, b: 169 },
    { r: 246, g: 255, b: 165 },
    { r: 246, g: 255, b: 162 },
    { r: 245, g: 255, b: 158 },
    { r: 245, g: 255, b: 155 },
    { r: 245, g: 255, b: 151 },
    { r: 244, g: 255, b: 148 },
    { r: 244, g: 255, b: 144 },
    { r: 244, g: 255, b: 140 },
    { r: 244, g: 255, b: 136 },
    { r: 243, g: 255, b: 132 },
    { r: 243, g: 255, b: 128 },
    { r: 243, g: 255, b: 123 },
    { r: 242, g: 255, b: 119 },
    { r: 242, g: 255, b: 114 },
    { r: 242, g: 255, b: 108 },
    { r: 242, g: 255, b: 104 },
    { r: 241, g: 255, b: 98 },
    { r: 241, g: 255, b: 92 },
    { r: 241, g: 255, b: 87 },
    { r: 241, g: 255, b: 80 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 21, g: 16, b: 0 },
    { r: 34, g: 26, b: 0 },
    { r: 42, g: 34, b: 0 },
    { r: 49, g: 40, b: 0 },
    { r: 53, g: 48, b: 0 },
    { r: 58, g: 53, b: 0 },
    { r: 62, g: 60, b: 0 },
    { r: 64, g: 65, b: 0 },
    { r: 67, g: 71, b: 0 },
    { r: 69, g: 76, b: 0 },
    { r: 70, g: 82, b: 0 },
    { r: 71, g: 86, b: 0 },
    { r: 73, g: 92, b: 16 },
    { r: 73, g: 96, b: 22 },
    { r: 73, g: 101, b: 22 },
    { r: 73, g: 104, b: 31 },
    { r: 71, g: 109, b: 34 },
    { r: 71, g: 112, b: 41 },
    { r: 69, g: 117, b: 45 },
    { r: 68, g: 120, b: 50 },
    { r: 66, g: 123, b: 55 },
    { r: 64, g: 126, b: 62 },
    { r: 62, g: 129, b: 65 },
    { r: 58, g: 131, b: 72 },
    { r: 55, g: 134, b: 77 },
    { r: 51, g: 136, b: 83 },
    { r: 48, g: 138, b: 88 },
    { r: 44, g: 139, b: 94 },
    { r: 39, g: 141, b: 100 },
    { r: 35, g: 142, b: 105 },
    { r: 32, g: 143, b: 110 },
    { r: 24, g: 144, b: 116 },
    { r: 19, g: 144, b: 121 },
    { r: 16, g: 144, b: 126 },
    { r: 0, g: 144, b: 131 },
    { r: 0, g: 144, b: 137 },
    { r: 0, g: 143, b: 141 },
    { r: 0, g: 142, b: 146 },
    { r: 0, g: 140, b: 151 },
    { r: 12, g: 139, b: 155 },
    { r: 19, g: 137, b: 160 },
    { r: 27, g: 134, b: 164 },
    { r: 32, g: 132, b: 168 },
    { r: 39, g: 130, b: 172 },
    { r: 47, g: 126, b: 176 },
    { r: 55, g: 123, b: 179 },
    { r: 61, g: 119, b: 182 },
    { r: 69, g: 116, b: 185 },
    { r: 75, g: 111, b: 188 },
    { r: 82, g: 107, b: 191 },
    { r: 89, g: 102, b: 193 },
    { r: 96, g: 97, b: 195 },
    { r: 103, g: 92, b: 196 },
    { r: 110, g: 87, b: 198 },
    { r: 116, g: 81, b: 199 },
    { r: 124, g: 76, b: 200 },
    { r: 130, g: 69, b: 201 },
    { r: 137, g: 63, b: 202 },
    { r: 144, g: 57, b: 202 },
    { r: 150, g: 50, b: 202 },
    { r: 156, g: 43, b: 201 },
    { r: 162, g: 35, b: 200 },
    { r: 169, g: 27, b: 199 },
    { r: 174, g: 20, b: 198 },
    { r: 180, g: 14, b: 196 },
    { r: 186, g: 0, b: 194 },
    { r: 191, g: 0, b: 191 },
    { r: 197, g: 0, b: 189 },
    { r: 202, g: 13, b: 186 },
    { r: 206, g: 20, b: 183 },
    { r: 211, g: 29, b: 179 },
    { r: 216, g: 39, b: 175 },
    { r: 220, g: 46, b: 171 },
    { r: 224, g: 55, b: 167 },
    { r: 227, g: 63, b: 162 },
    { r: 231, g: 72, b: 157 },
    { r: 234, g: 80, b: 152 },
    { r: 237, g: 89, b: 146 },
    { r: 239, g: 98, b: 140 },
    { r: 241, g: 106, b: 134 },
    { r: 243, g: 114, b: 128 },
    { r: 245, g: 122, b: 121 },
    { r: 246, g: 131, b: 114 },
    { r: 247, g: 139, b: 107 },
    { r: 248, g: 147, b: 100 },
    { r: 248, g: 155, b: 92 },
    { r: 248, g: 163, b: 84 },
    { r: 248, g: 170, b: 76 },
    { r: 247, g: 178, b: 68 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 21, g: 16, b: 16 },
    { r: 34, g: 26, b: 26 },
    { r: 42, g: 34, b: 34 },
    { r: 49, g: 40, b: 40 },
    { r: 53, g: 48, b: 48 },
    { r: 58, g: 53, b: 53 },
    { r: 62, g: 60, b: 60 },
    { r: 64, g: 65, b: 65 },
    { r: 67, g: 71, b: 71 },
    { r: 69, g: 76, b: 76 },
    { r: 70, g: 82, b: 82 },
    { r: 71, g: 86, b: 86 },
    { r: 73, g: 92, b: 92 },
    { r: 73, g: 96, b: 96 },
    { r: 73, g: 101, b: 101 },
    { r: 73, g: 104, b: 104 },
    { r: 71, g: 109, b: 109 },
    { r: 71, g: 112, b: 112 },
    { r: 69, g: 117, b: 117 },
    { r: 68, g: 120, b: 120 },
    { r: 66, g: 123, b: 123 },
    { r: 64, g: 126, b: 126 },
    { r: 62, g: 129, b: 129 },
    { r: 58, g: 131, b: 131 },
    { r: 55, g: 134, b: 134 },
    { r: 51, g: 136, b: 136 },
    { r: 48, g: 138, b: 138 },
    { r: 44, g: 139, b: 139 },
    { r: 39, g: 141, b: 141 },
    { r: 35, g: 142, b: 142 },
    { r: 32, g: 143, b: 143 },
    { r: 24, g: 144, b: 144 },
    { r: 19, g: 144, b: 144 },
    { r: 16, g: 144, b: 144 },
    { r: 0, g: 144, b: 144 },
    { r: 0, g: 144, b: 144 },
    { r: 0, g: 143, b: 143 },
    { r: 0, g: 142, b: 142 },
    { r: 0, g: 140, b: 140 },
    { r: 12, g: 139, b: 139 },
    { r: 19, g: 137, b: 137 },
    { r: 27, g: 134, b: 134 },
    { r: 32, g: 132, b: 132 },
    { r: 39, g: 130, b: 130 },
    { r: 47, g: 126, b: 126 },
    { r: 55, g: 123, b: 123 },
    { r: 61, g: 119, b: 119 },
    { r: 69, g: 116, b: 116 },
    { r: 75, g: 111, b: 111 },
    { r: 82, g: 107, b: 107 },
    { r: 89, g: 102, b: 102 },
    { r: 96, g: 97, b: 97 },
    { r: 103, g: 92, b: 92 },
    { r: 110, g: 87, b: 87 },
    { r: 116, g: 81, b: 81 },
    { r: 124, g: 76, b: 76 },
    { r: 130, g: 69, b: 69 },
    { r: 137, g: 63, b: 63 },
    { r: 144, g: 57, b: 57 },
    { r: 150, g: 50, b: 50 },
    { r: 156, g: 43, b: 43 },
    { r: 162, g: 35, b: 35 },
    { r: 169, g: 27, b: 27 },
    { r: 174, g: 20, b: 20 },
    { r: 180, g: 14, b: 14 },
    { r: 186, g: 0, b: 0 },
    { r: 191, g: 0, b: 0 },
    { r: 197, g: 0, b: 0 },
    { r: 202, g: 13, b: 13 },
    { r: 206, g: 20, b: 20 },
    { r: 211, g: 29, b: 29 },
    { r: 216, g: 39, b: 39 },
    { r: 220, g: 46, b: 46 },
    { r: 224, g: 55, b: 55 },
    { r: 227, g: 63, b: 63 },
    { r: 231, g: 72, b: 72 },
    { r: 234, g: 80, b: 80 },
    { r: 237, g: 89, b: 89 },
    { r: 239, g: 98, b: 98 },
    { r: 241, g: 106, b: 106 },
    { r: 243, g: 114, b: 114 },
    { r: 245, g: 122, b: 122 },
    { r: 246, g: 131, b: 131 },
    { r: 247, g: 139, b: 139 },
    { r: 248, g: 147, b: 147 },
    { r: 248, g: 155, b: 155 },
    { r: 248, g: 163, b: 163 },
    { r: 248, g: 170, b: 170 },
    { r: 247, g: 178, b: 178 },
  ],
  [
    { r: 0, g: 0, b: 255 },
    { r: 21, g: 30, b: 255 },
    { r: 34, g: 53, b: 253 },
    { r: 44, g: 78, b: 252 },
    { r: 51, g: 104, b: 250 },
    { r: 58, g: 127, b: 249 },
    { r: 64, g: 151, b: 247 },
    { r: 69, g: 171, b: 246 },
    { r: 75, g: 190, b: 244 },
    { r: 79, g: 207, b: 243 },
    { r: 84, g: 221, b: 241 },
    { r: 88, g: 233, b: 240 },
    { r: 92, g: 243, b: 238 },
    { r: 96, g: 250, b: 237 },
    { r: 100, g: 254, b: 235 },
    { r: 103, g: 254, b: 233 },
    { r: 107, g: 253, b: 232 },
    { r: 110, g: 248, b: 230 },
    { r: 114, g: 241, b: 229 },
    { r: 117, g: 231, b: 227 },
    { r: 120, g: 218, b: 225 },
    { r: 123, g: 203, b: 224 },
    { r: 126, g: 186, b: 222 },
    { r: 129, g: 167, b: 221 },
    { r: 131, g: 146, b: 219 },
    { r: 134, g: 123, b: 217 },
    { r: 137, g: 98, b: 215 },
    { r: 140, g: 73, b: 214 },
    { r: 142, g: 46, b: 212 },
    { r: 145, g: 15, b: 210 },
    { r: 147, g: 0, b: 209 },
    { r: 150, g: 30, b: 207 },
    { r: 152, g: 57, b: 205 },
    { r: 155, g: 83, b: 203 },
    { r: 157, g: 109, b: 201 },
    { r: 159, g: 133, b: 200 },
    { r: 162, g: 155, b: 198 },
    { r: 164, g: 175, b: 196 },
    { r: 166, g: 194, b: 194 },
    { r: 168, g: 210, b: 192 },
    { r: 170, g: 224, b: 190 },
    { r: 173, g: 236, b: 188 },
    { r: 175, g: 245, b: 186 },
    { r: 177, g: 251, b: 184 },
    { r: 179, g: 254, b: 182 },
    { r: 181, g: 255, b: 180 },
    { r: 183, g: 252, b: 178 },
    { r: 185, g: 247, b: 176 },
    { r: 187, g: 239, b: 174 },
    { r: 189, g: 229, b: 172 },
    { r: 191, g: 216, b: 170 },
    { r: 193, g: 200, b: 168 },
    { r: 195, g: 182, b: 165 },
    { r: 196, g: 162, b: 163 },
    { r: 198, g: 141, b: 161 },
    { r: 200, g: 118, b: 159 },
    { r: 202, g: 93, b: 156 },
    { r: 204, g: 66, b: 154 },
    { r: 206, g: 40, b: 151 },
    { r: 207, g: 0, b: 149 },
    { r: 209, g: 0, b: 147 },
    { r: 211, g: 37, b: 144 },
    { r: 213, g: 64, b: 141 },
    { r: 214, g: 90, b: 139 },
    { r: 216, g: 114, b: 136 },
    { r: 218, g: 138, b: 133 },
    { r: 219, g: 160, b: 131 },
    { r: 221, g: 179, b: 128 },
    { r: 223, g: 197, b: 125 },
    { r: 224, g: 214, b: 122 },
    { r: 226, g: 227, b: 119 },
    { r: 228, g: 238, b: 116 },
    { r: 229, g: 246, b: 113 },
    { r: 231, g: 252, b: 109 },
    { r: 232, g: 254, b: 106 },
    { r: 234, g: 254, b: 102 },
    { r: 236, g: 251, b: 99 },
    { r: 237, g: 246, b: 95 },
    { r: 239, g: 237, b: 91 },
    { r: 240, g: 226, b: 87 },
    { r: 242, g: 212, b: 82 },
    { r: 243, g: 196, b: 78 },
    { r: 245, g: 178, b: 73 },
    { r: 246, g: 158, b: 68 },
    { r: 248, g: 135, b: 62 },
    { r: 249, g: 112, b: 56 },
    { r: 251, g: 87, b: 49 },
    { r: 252, g: 61, b: 41 },
    { r: 254, g: 34, b: 31 },
    { r: 255, g: 0, b: 16 },
  ],
  [
    { r: 0, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
    { r: 255, g: 0, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 0, b: 255 },
    { r: 255, g: 255, b: 0 },
    { r: 0, g: 255, b: 255 },
    { r: 255, g: 0, b: 255 },
    { r: 181, g: 0, b: 0 },
    { r: 181, g: 255, b: 255 },
    { r: 0, g: 0, b: 181 },
    { r: 255, g: 255, b: 181 },
    { r: 181, g: 0, b: 255 },
    { r: 255, g: 181, b: 0 },
    { r: 0, g: 181, b: 255 },
    { r: 181, g: 255, b: 0 },
    { r: 181, g: 0, b: 181 },
    { r: 181, g: 255, b: 181 },
    { r: 255, g: 181, b: 255 },
    { r: 0, g: 181, b: 0 },
    { r: 181, g: 181, b: 255 },
    { r: 255, g: 0, b: 181 },
    { r: 181, g: 181, b: 0 },
    { r: 0, g: 181, b: 181 },
    { r: 255, g: 181, b: 181 },
    { r: 0, g: 255, b: 181 },
    { r: 181, g: 181, b: 181 },
    { r: 90, g: 90, b: 90 },
    { r: 221, g: 221, b: 221 },
    { r: 128, g: 128, b: 128 },
    { r: 202, g: 202, b: 202 },
    { r: 156, g: 156, b: 156 },
    { r: 0, g: 0, b: 255 },
    { r: 0, g: 161, b: 255 },
    { r: 0, g: 233, b: 255 },
    { r: 78, g: 255, b: 255 },
    { r: 161, g: 255, b: 255 },
    { r: 198, g: 255, b: 255 },
    { r: 161, g: 255, b: 198 },
    { r: 81, g: 255, b: 105 },
    { r: 114, g: 255, b: 0 },
    { r: 179, g: 255, b: 0 },
    { r: 228, g: 255, b: 0 },
    { r: 239, g: 251, b: 22 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 244, b: 0 },
    { r: 255, g: 228, b: 0 },
    { r: 255, g: 198, b: 0 },
    { r: 255, g: 181, b: 0 },
    { r: 255, g: 161, b: 0 },
    { r: 255, g: 114, b: 0 },
    { r: 255, g: 102, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 213, g: 252, b: 255 },
    { r: 213, g: 217, b: 255 },
    { r: 179, g: 97, b: 229 },
    { r: 146, g: 81, b: 189 },
    { r: 246, g: 243, b: 184 },
    { r: 229, g: 225, b: 171 },
    { r: 196, g: 196, b: 196 },
    { r: 255, g: 255, b: 255 },
    { r: 209, g: 255, b: 255 },
    { r: 147, g: 202, b: 255 },
    { r: 86, g: 0, b: 255 },
    { r: 179, g: 242, b: 152 },
    { r: 141, g: 228, b: 131 },
    { r: 108, g: 213, b: 121 },
    { r: 87, g: 198, b: 125 },
    { r: 255, g: 255, b: 161 },
    { r: 255, g: 228, b: 161 },
    { r: 255, g: 186, b: 139 },
    { r: 255, g: 80, b: 80 },
    { r: 228, g: 125, b: 125 },
    { r: 205, g: 112, b: 112 },
    { r: 246, g: 0, b: 246 },
    { r: 187, g: 162, b: 229 },
    { r: 253, g: 248, b: 243 },
    { r: 255, g: 221, b: 180 },
    { r: 255, g: 180, b: 0 },
    { r: 255, g: 255, b: 198 },
    { r: 255, g: 255, b: 113 },
    { r: 213, g: 255, b: 189 },
    { r: 113, g: 255, b: 0 },
    { r: 205, g: 246, b: 255 },
    { r: 80, g: 213, b: 255 },
    { r: 228, g: 221, b: 255 },
    { r: 161, g: 139, b: 255 },
    { r: 255, g: 198, b: 221 },
    { r: 242, g: 80, b: 113 },
  ],
  [
    { r: 255, g: 255, b: 255 },
    { r: 209, g: 255, b: 255 },
    { r: 147, g: 202, b: 255 },
    { r: 86, g: 0, b: 255 },
    { r: 179, g: 242, b: 152 },
    { r: 141, g: 228, b: 131 },
    { r: 108, g: 213, b: 121 },
    { r: 87, g: 198, b: 125 },
    { r: 255, g: 255, b: 161 },
    { r: 255, g: 228, b: 161 },
    { r: 255, g: 186, b: 139 },
    { r: 255, g: 80, b: 80 },
    { r: 228, g: 125, b: 125 },
    { r: 205, g: 112, b: 112 },
    { r: 246, g: 0, b: 246 },
    { r: 187, g: 162, b: 229 },
    { r: 253, g: 248, b: 243 },
    { r: 250, g: 250, b: 250 },
    { r: 212, g: 246, b: 246 },
    { r: 197, g: 253, b: 197 },
    { r: 131, g: 229, b: 181 },
    { r: 123, g: 214, b: 170 },
    { r: 253, g: 253, b: 232 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 205, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 229, g: 118, b: 0 },
    { r: 226, g: 71, b: 184 },
    { r: 246, g: 182, b: 246 },
    { r: 255, g: 255, b: 255 },
    { r: 0, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
    { r: 244, g: 228, b: 252 },
    { r: 230, g: 181, b: 239 },
    { r: 183, g: 114, b: 194 },
    { r: 122, g: 0, b: 212 },
    { r: 86, g: 0, b: 235 },
    { r: 0, g: 0, b: 255 },
    { r: 27, g: 124, b: 212 },
    { r: 35, g: 174, b: 156 },
    { r: 45, g: 214, b: 62 },
    { r: 184, g: 236, b: 45 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 209, b: 0 },
    { r: 255, g: 147, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 214, g: 0, b: 0 },
    { r: 161, g: 0, b: 0 },
    { r: 114, g: 0, b: 0 },
    { r: 0, g: 0, b: 0 },
    { r: 253, g: 225, b: 253 },
    { r: 255, g: 255, b: 255 },
    { r: 0, g: 0, b: 0 },
    { r: 149, g: 0, b: 186 },
    { r: 122, g: 0, b: 212 },
    { r: 86, g: 0, b: 235 },
    { r: 0, g: 0, b: 255 },
    { r: 27, g: 124, b: 212 },
    { r: 35, g: 174, b: 156 },
    { r: 45, g: 214, b: 62 },
    { r: 134, g: 225, b: 53 },
    { r: 184, g: 236, b: 45 },
    { r: 222, g: 246, b: 32 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 221, b: 0 },
    { r: 255, g: 181, b: 0 },
    { r: 255, g: 128, b: 0 },
    { r: 255, g: 0, b: 0 },
    { r: 253, g: 83, b: 143 },
    { r: 250, g: 116, b: 202 },
    { r: 248, g: 143, b: 248 },
    { r: 250, g: 178, b: 250 },
    { r: 252, g: 207, b: 252 },
    { r: 253, g: 232, b: 253 },
    { r: 255, g: 255, b: 255 },
    { r: 202, g: 90, b: 248 },
    { r: 0, g: 0, b: 215 },
    { r: 124, g: 160, b: 243 },
    { r: 175, g: 199, b: 249 },
    { r: 212, g: 239, b: 243 },
    { r: 108, g: 189, b: 149 },
    { r: 160, g: 240, b: 0 },
    { r: 232, g: 255, b: 109 },
    { r: 250, g: 243, b: 220 },
    { r: 238, g: 217, b: 186 },
    { r: 255, g: 240, b: 0 },
    { r: 255, g: 205, b: 0 },
    { r: 255, g: 133, b: 0 },
    { r: 196, g: 93, b: 93 },
    { r: 255, g: 164, b: 215 },
  ],
  [
    { r: 0, g: 161, b: 161 },
    { r: 0, g: 189, b: 189 },
    { r: 0, g: 213, b: 213 },
    { r: 0, g: 236, b: 236 },
    { r: 0, g: 255, b: 255 },
    { r: 121, g: 255, b: 255 },
    { r: 171, g: 255, b: 255 },
    { r: 210, g: 255, b: 255 },
    { r: 242, g: 255, b: 255 },
    { r: 255, g: 242, b: 228 },
    { r: 255, g: 227, b: 198 },
    { r: 255, g: 210, b: 161 },
    { r: 255, g: 191, b: 114 },
    { r: 255, g: 168, b: 0 },
    { r: 236, g: 153, b: 0 },
    { r: 213, g: 137, b: 0 },
    { r: 189, g: 121, b: 0 },
    { r: 161, g: 100, b: 0 },
    { r: 96, g: 0, b: 235 },
    { r: 78, g: 84, b: 251 },
    { r: 101, g: 149, b: 255 },
    { r: 125, g: 186, b: 255 },
    { r: 148, g: 212, b: 255 },
    { r: 173, g: 232, b: 255 },
    { r: 198, g: 245, b: 255 },
    { r: 219, g: 252, b: 255 },
    { r: 245, g: 255, b: 255 },
    { r: 255, g: 255, b: 245 },
    { r: 255, g: 248, b: 219 },
    { r: 255, g: 234, b: 198 },
    { r: 255, g: 210, b: 173 },
    { r: 255, g: 175, b: 148 },
    { r: 255, g: 125, b: 125 },
    { r: 251, g: 100, b: 116 },
    { r: 235, g: 73, b: 109 },
    { r: 205, g: 0, b: 92 },
    { r: 0, g: 143, b: 0 },
    { r: 0, g: 181, b: 0 },
    { r: 0, g: 211, b: 0 },
    { r: 0, g: 238, b: 0 },
    { r: 104, g: 252, b: 104 },
    { r: 162, g: 255, b: 162 },
    { r: 196, g: 255, b: 196 },
    { r: 224, g: 255, b: 224 },
    { r: 249, g: 255, b: 249 },
    { r: 255, g: 249, b: 255 },
    { r: 255, g: 224, b: 255 },
    { r: 255, g: 196, b: 255 },
    { r: 255, g: 162, b: 255 },
    { r: 252, g: 104, b: 252 },
    { r: 238, g: 0, b: 238 },
    { r: 211, g: 0, b: 211 },
    { r: 181, g: 0, b: 181 },
    { r: 143, g: 0, b: 143 },
    { r: 0, g: 0, b: 255 },
    { r: 100, g: 100, b: 255 },
    { r: 141, g: 141, b: 255 },
    { r: 172, g: 172, b: 255 },
    { r: 199, g: 199, b: 255 },
    { r: 211, g: 211, b: 255 },
    { r: 222, g: 222, b: 255 },
    { r: 233, g: 233, b: 255 },
    { r: 242, g: 244, b: 254 },
    { r: 242, g: 254, b: 244 },
    { r: 233, g: 255, b: 233 },
    { r: 222, g: 255, b: 222 },
    { r: 211, g: 255, b: 211 },
    { r: 199, g: 255, b: 199 },
    { r: 172, g: 255, b: 172 },
    { r: 141, g: 255, b: 141 },
    { r: 100, g: 255, b: 100 },
    { r: 0, g: 255, b: 0 },
    { r: 114, g: 80, b: 0 },
    { r: 146, g: 100, b: 0 },
    { r: 173, g: 125, b: 63 },
    { r: 196, g: 154, b: 113 },
    { r: 216, g: 183, b: 155 },
    { r: 230, g: 202, b: 181 },
    { r: 234, g: 210, b: 194 },
    { r: 243, g: 225, b: 214 },
    { r: 245, g: 240, b: 234 },
    { r: 232, g: 251, b: 251 },
    { r: 215, g: 253, b: 255 },
    { r: 194, g: 251, b: 255 },
    { r: 170, g: 248, b: 255 },
    { r: 143, g: 245, b: 255 },
    { r: 109, g: 239, b: 254 },
    { r: 61, g: 218, b: 237 },
    { r: 0, g: 197, b: 218 },
    { r: 0, g: 177, b: 198 },
  ],
  [
    { r: 198, g: 62, b: 62 },
    { r: 213, g: 106, b: 106 },
    { r: 228, g: 144, b: 144 },
    { r: 242, g: 179, b: 179 },
    { r: 255, g: 213, b: 213 },
    { r: 198, g: 146, b: 62 },
    { r: 213, g: 168, b: 106 },
    { r: 228, g: 191, b: 144 },
    { r: 242, g: 213, b: 179 },
    { r: 255, g: 235, b: 213 },
    { r: 165, g: 198, b: 62 },
    { r: 184, g: 213, b: 106 },
    { r: 204, g: 228, b: 144 },
    { r: 223, g: 242, b: 179 },
    { r: 242, g: 255, b: 213 },
    { r: 62, g: 165, b: 198 },
    { r: 106, g: 184, b: 213 },
    { r: 144, g: 204, b: 228 },
    { r: 179, g: 223, b: 242 },
    { r: 213, g: 242, b: 255 },
    { r: 98, g: 62, b: 198 },
    { r: 130, g: 106, b: 213 },
    { r: 161, g: 144, b: 228 },
    { r: 191, g: 179, b: 242 },
    { r: 221, g: 213, b: 255 },
    { r: 0, g: 157, b: 181 },
    { r: 0, g: 181, b: 203 },
    { r: 0, g: 203, b: 221 },
    { r: 0, g: 221, b: 239 },
    { r: 0, g: 239, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 114, g: 254, b: 254 },
    { r: 161, g: 254, b: 254 },
    { r: 198, g: 254, b: 254 },
    { r: 228, g: 254, b: 254 },
    { r: 255, g: 255, b: 255 },
    { r: 254, g: 254, b: 0 },
    { r: 254, g: 239, b: 0 },
    { r: 254, g: 221, b: 0 },
    { r: 254, g: 203, b: 0 },
    { r: 254, g: 181, b: 0 },
    { r: 254, g: 157, b: 0 },
    { r: 254, g: 128, b: 0 },
    { r: 254, g: 92, b: 0 },
    { r: 221, g: 0, b: 0 },
    { r: 181, g: 0, b: 0 },
    { r: 78, g: 78, b: 169 },
    { r: 64, g: 141, b: 189 },
    { r: 76, g: 172, b: 229 },
    { r: 136, g: 174, b: 255 },
    { r: 152, g: 210, b: 246 },
    { r: 210, g: 235, b: 243 },
    { r: 231, g: 246, b: 246 },
    { r: 242, g: 247, b: 252 },
    { r: 249, g: 255, b: 255 },
    { r: 254, g: 250, b: 243 },
    { r: 255, g: 242, b: 215 },
    { r: 249, g: 205, b: 157 },
    { r: 246, g: 174, b: 0 },
    { r: 229, g: 161, b: 86 },
    { r: 239, g: 112, b: 62 },
    { r: 246, g: 0, b: 0 },
    { r: 229, g: 0, b: 0 },
    { r: 189, g: 0, b: 0 },
    { r: 194, g: 169, b: 237 },
    { r: 0, g: 0, b: 226 },
    { r: 124, g: 160, b: 243 },
    { r: 175, g: 199, b: 249 },
    { r: 212, g: 239, b: 243 },
    { r: 90, g: 213, b: 209 },
    { r: 198, g: 229, b: 113 },
    { r: 108, g: 189, b: 149 },
    { r: 250, g: 243, b: 220 },
    { r: 238, g: 217, b: 186 },
    { r: 255, g: 240, b: 0 },
    { r: 255, g: 205, b: 0 },
    { r: 255, g: 133, b: 0 },
    { r: 213, g: 93, b: 93 },
    { r: 255, g: 216, b: 222 },
    { r: 255, g: 71, b: 194 },
    { r: 175, g: 0, b: 186 },
    { r: 152, g: 0, b: 217 },
    { r: 134, g: 0, b: 250 },
    { r: 0, g: 209, b: 240 },
    { r: 0, g: 226, b: 226 },
    { r: 0, g: 226, b: 179 },
    { r: 223, g: 255, b: 0 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 199, b: 0 },
    { r: 255, g: 0, b: 0 },
  ],
  [
    { r: 255, g: 250, b: 248 },
    { r: 255, g: 239, b: 232 },
    { r: 254, g: 219, b: 203 },
    { r: 254, g: 193, b: 171 },
    { r: 253, g: 165, b: 137 },
    { r: 247, g: 123, b: 106 },
    { r: 228, g: 78, b: 86 },
    { r: 205, g: 62, b: 73 },
    { r: 162, g: 0, b: 57 },
    { r: 251, g: 253, b: 255 },
    { r: 238, g: 245, b: 251 },
    { r: 225, g: 237, b: 247 },
    { r: 201, g: 227, b: 240 },
    { r: 165, g: 211, b: 234 },
    { r: 130, g: 193, b: 225 },
    { r: 92, g: 170, b: 215 },
    { r: 45, g: 144, b: 200 },
    { r: 45, g: 111, b: 165 },
    { r: 255, g: 255, b: 255 },
    { r: 248, g: 248, b: 248 },
    { r: 236, g: 236, b: 236 },
    { r: 220, g: 220, b: 220 },
    { r: 196, g: 196, b: 196 },
    { r: 171, g: 171, b: 171 },
    { r: 145, g: 145, b: 145 },
    { r: 97, g: 97, b: 97 },
    { r: 0, g: 0, b: 0 },
    { r: 255, g: 255, b: 228 },
    { r: 255, g: 246, b: 202 },
    { r: 255, g: 236, b: 174 },
    { r: 255, g: 213, b: 139 },
    { r: 254, g: 190, b: 124 },
    { r: 254, g: 141, b: 103 },
    { r: 241, g: 81, b: 84 },
    { r: 220, g: 0, b: 98 },
    { r: 181, g: 0, b: 98 },
    { r: 255, g: 255, b: 242 },
    { r: 255, g: 251, b: 219 },
    { r: 255, g: 241, b: 193 },
    { r: 255, g: 224, b: 142 },
    { r: 255, g: 198, b: 102 },
    { r: 246, g: 169, b: 71 },
    { r: 228, g: 139, b: 22 },
    { r: 198, g: 115, b: 32 },
    { r: 161, g: 97, b: 39 },
    { r: 255, g: 255, b: 236 },
    { r: 246, g: 252, b: 213 },
    { r: 226, g: 244, b: 215 },
    { r: 180, g: 229, b: 219 },
    { r: 129, g: 216, b: 224 },
    { r: 86, g: 193, b: 222 },
    { r: 93, g: 155, b: 207 },
    { r: 97, g: 115, b: 195 },
    { r: 45, g: 86, b: 150 },
    { r: 255, g: 255, b: 242 },
    { r: 251, g: 254, b: 218 },
    { r: 236, g: 248, b: 204 },
    { r: 210, g: 238, b: 191 },
    { r: 175, g: 225, b: 176 },
    { r: 129, g: 209, b: 154 },
    { r: 94, g: 184, b: 131 },
    { r: 0, g: 163, b: 118 },
    { r: 0, g: 133, b: 102 },
    { r: 254, g: 253, b: 254 },
    { r: 247, g: 246, b: 250 },
    { r: 236, g: 236, b: 245 },
    { r: 219, g: 220, b: 237 },
    { r: 201, g: 198, b: 226 },
    { r: 181, g: 179, b: 218 },
    { r: 165, g: 144, b: 204 },
    { r: 146, g: 100, b: 191 },
    { r: 127, g: 0, b: 179 },
    { r: 251, g: 254, b: 250 },
    { r: 242, g: 250, b: 239 },
    { r: 226, g: 244, b: 222 },
    { r: 203, g: 236, b: 199 },
    { r: 172, g: 224, b: 174 },
    { r: 129, g: 209, b: 154 },
    { r: 94, g: 189, b: 133 },
    { r: 0, g: 167, b: 106 },
    { r: 0, g: 132, b: 83 },
    { r: 255, g: 250, b: 245 },
    { r: 255, g: 243, b: 230 },
    { r: 254, g: 231, b: 204 },
    { r: 254, g: 211, b: 165 },
    { r: 254, g: 190, b: 124 },
    { r: 248, g: 164, b: 69 },
    { r: 236, g: 136, b: 16 },
    { r: 206, g: 117, b: 27 },
    { r: 180, g: 100, b: 32 },
  ],
  [
    { r: 255, g: 251, b: 249 },
    { r: 254, g: 239, b: 238 },
    { r: 254, g: 225, b: 222 },
    { r: 253, g: 202, b: 215 },
    { r: 251, g: 163, b: 203 },
    { r: 238, g: 115, b: 196 },
    { r: 211, g: 16, b: 179 },
    { r: 177, g: 16, b: 174 },
    { r: 136, g: 0, b: 165 },
    { r: 251, g: 254, b: 254 },
    { r: 239, g: 246, b: 250 },
    { r: 221, g: 232, b: 243 },
    { r: 201, g: 219, b: 236 },
    { r: 189, g: 196, b: 225 },
    { r: 189, g: 165, b: 213 },
    { r: 186, g: 129, b: 200 },
    { r: 182, g: 62, b: 178 },
    { r: 140, g: 0, b: 138 },
    { r: 251, g: 254, b: 254 },
    { r: 242, g: 250, b: 252 },
    { r: 228, g: 246, b: 243 },
    { r: 198, g: 235, b: 227 },
    { r: 161, g: 223, b: 205 },
    { r: 129, g: 211, b: 174 },
    { r: 94, g: 189, b: 133 },
    { r: 0, g: 167, b: 106 },
    { r: 0, g: 132, b: 83 },
    { r: 251, g: 254, b: 248 },
    { r: 239, g: 249, b: 237 },
    { r: 228, g: 245, b: 225 },
    { r: 207, g: 238, b: 215 },
    { r: 177, g: 228, b: 224 },
    { r: 141, g: 214, b: 232 },
    { r: 105, g: 189, b: 220 },
    { r: 45, g: 163, b: 210 },
    { r: 45, g: 128, b: 182 },
    { r: 255, g: 251, b: 246 },
    { r: 255, g: 244, b: 226 },
    { r: 254, g: 233, b: 201 },
    { r: 254, g: 219, b: 184 },
    { r: 254, g: 190, b: 151 },
    { r: 247, g: 161, b: 136 },
    { r: 235, g: 111, b: 89 },
    { r: 214, g: 0, b: 0 },
    { r: 180, g: 0, b: 0 },
    { r: 146, g: 111, b: 35 },
    { r: 189, g: 144, b: 50 },
    { r: 221, g: 182, b: 107 },
    { r: 239, g: 223, b: 179 },
    { r: 251, g: 244, b: 223 },
    { r: 250, g: 250, b: 250 },
    { r: 226, g: 245, b: 242 },
    { r: 181, g: 229, b: 222 },
    { r: 116, g: 196, b: 191 },
    { r: 16, g: 161, b: 155 },
    { r: 0, g: 124, b: 111 },
    { r: 128, g: 0, b: 138 },
    { r: 174, g: 103, b: 183 },
    { r: 198, g: 169, b: 209 },
    { r: 223, g: 205, b: 230 },
    { r: 243, g: 233, b: 244 },
    { r: 251, g: 251, b: 251 },
    { r: 236, g: 248, b: 232 },
    { r: 206, g: 237, b: 202 },
    { r: 152, g: 211, b: 157 },
    { r: 83, g: 175, b: 118 },
    { r: 0, g: 132, b: 83 },
    { r: 191, g: 16, b: 145 },
    { r: 225, g: 83, b: 179 },
    { r: 238, g: 174, b: 211 },
    { r: 248, g: 216, b: 236 },
    { r: 254, g: 239, b: 247 },
    { r: 251, g: 251, b: 251 },
    { r: 243, g: 250, b: 231 },
    { r: 217, g: 240, b: 185 },
    { r: 180, g: 219, b: 129 },
    { r: 140, g: 193, b: 92 },
    { r: 100, g: 160, b: 80 },
    { r: 180, g: 123, b: 45 },
    { r: 214, g: 150, b: 39 },
    { r: 239, g: 182, b: 71 },
    { r: 254, g: 217, b: 159 },
    { r: 255, g: 239, b: 216 },
    { r: 251, g: 251, b: 251 },
    { r: 235, g: 236, b: 245 },
    { r: 213, g: 209, b: 232 },
    { r: 181, g: 171, b: 210 },
    { r: 146, g: 100, b: 186 },
    { r: 107, g: 0, b: 138 },
    { r: 255, g: 255, b: 255 },
  ],
  [
    { r: 251, g: 250, b: 252 },
    { r: 243, g: 240, b: 247 },
    { r: 233, g: 218, b: 236 },
    { r: 227, g: 195, b: 226 },
    { r: 239, g: 161, b: 212 },
    { r: 243, g: 102, b: 188 },
    { r: 230, g: 68, b: 148 },
    { r: 197, g: 0, b: 131 },
    { r: 162, g: 0, b: 89 },
    { r: 255, g: 251, b: 253 },
    { r: 246, g: 241, b: 248 },
    { r: 231, g: 231, b: 243 },
    { r: 206, g: 220, b: 237 },
    { r: 162, g: 208, b: 230 },
    { r: 117, g: 192, b: 222 },
    { r: 22, g: 182, b: 188 },
    { r: 16, g: 166, b: 151 },
    { r: 16, g: 134, b: 117 },
    { r: 255, g: 251, b: 253 },
    { r: 246, g: 243, b: 249 },
    { r: 231, g: 231, b: 243 },
    { r: 206, g: 220, b: 237 },
    { r: 172, g: 208, b: 230 },
    { r: 117, g: 192, b: 222 },
    { r: 35, g: 169, b: 212 },
    { r: 32, g: 152, b: 190 },
    { r: 22, g: 119, b: 150 },
    { r: 162, g: 0, b: 89 },
    { r: 213, g: 78, b: 105 },
    { r: 234, g: 157, b: 140 },
    { r: 250, g: 205, b: 182 },
    { r: 254, g: 237, b: 226 },
    { r: 251, g: 251, b: 251 },
    { r: 231, g: 242, b: 248 },
    { r: 193, g: 225, b: 238 },
    { r: 131, g: 194, b: 223 },
    { r: 92, g: 161, b: 210 },
    { r: 35, g: 111, b: 157 },
    { r: 162, g: 0, b: 89 },
    { r: 213, g: 78, b: 105 },
    { r: 234, g: 157, b: 140 },
    { r: 250, g: 205, b: 182 },
    { r: 254, g: 237, b: 226 },
    { r: 255, g: 255, b: 255 },
    { r: 239, g: 239, b: 239 },
    { r: 218, g: 218, b: 218 },
    { r: 186, g: 186, b: 186 },
    { r: 140, g: 140, b: 140 },
    { r: 81, g: 81, b: 81 },
    { r: 205, g: 0, b: 98 },
    { r: 235, g: 111, b: 100 },
    { r: 250, g: 167, b: 131 },
    { r: 254, g: 211, b: 157 },
    { r: 255, g: 239, b: 192 },
    { r: 255, g: 255, b: 221 },
    { r: 239, g: 249, b: 252 },
    { r: 209, g: 236, b: 244 },
    { r: 172, g: 210, b: 231 },
    { r: 133, g: 173, b: 215 },
    { r: 112, g: 117, b: 195 },
    { r: 205, g: 0, b: 98 },
    { r: 235, g: 111, b: 100 },
    { r: 250, g: 167, b: 131 },
    { r: 254, g: 211, b: 157 },
    { r: 255, g: 239, b: 189 },
    { r: 255, g: 255, b: 221 },
    { r: 236, g: 247, b: 189 },
    { r: 206, g: 236, b: 165 },
    { r: 161, g: 220, b: 159 },
    { r: 81, g: 197, b: 143 },
    { r: 0, g: 163, b: 118 },
    { r: 201, g: 16, b: 130 },
    { r: 233, g: 126, b: 142 },
    { r: 250, g: 167, b: 131 },
    { r: 254, g: 211, b: 157 },
    { r: 255, g: 239, b: 189 },
    { r: 255, g: 255, b: 221 },
    { r: 243, g: 250, b: 197 },
    { r: 209, g: 238, b: 205 },
    { r: 161, g: 223, b: 205 },
    { r: 113, g: 186, b: 220 },
    { r: 155, g: 142, b: 204 },
    { r: 180, g: 227, b: 180 },
    { r: 220, g: 211, b: 233 },
    { r: 254, g: 222, b: 185 },
    { r: 255, g: 255, b: 198 },
    { r: 119, g: 166, b: 212 },
    { r: 248, g: 22, b: 180 },
    { r: 221, g: 152, b: 76 },
    { r: 161, g: 161, b: 161 },
  ],
];
