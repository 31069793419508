var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "dropzone" } }, [_vm._v("drop zone")]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "text-align": "center", "padding-top": "10px" } },
      [
        _c(
          "v-btn",
          {
            attrs: { outlined: "", rounded: "", color: "primary" },
            on: { click: _vm.loading },
          },
          [_vm._v("load")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.loadedData
      ? _c(
          "div",
          {
            staticStyle: {
              "white-space": "pre-wrap",
              "word-wrap": "break-word",
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.droppedInfo) + "\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }