var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { subheader: "", tile: "" } },
    [
      _c(
        "v-list-item-group",
        {
          attrs: { color: "primary" },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        _vm._l(_vm.clrmap_names, function (clrmap_name, i) {
          return _c(
            "v-list-item",
            { key: i, attrs: { link: "" } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [_vm._v(_vm._s(clrmap_name))]),
                  _vm._v(" "),
                  _c("colorbar", {
                    attrs: { width: 200, height: 25, clrindex: i },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }