var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  " + _vm._s(_vm.name) + "\n  "),
      _vm._l(_vm.sliders, function (slider, i) {
        return _c(
          "div",
          { key: i },
          [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("v-subheader", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        slider.title + "=" + slider.tick_label[slider.value]
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticStyle: { margin: "0 0 0 auto" },
                    attrs: { outlined: "", color: "red" },
                    on: {
                      click: function ($event) {
                        return _vm.replay(i)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(slider.clicked ? "▶︎" : "■") +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-slider", {
              attrs: {
                "tick-size": slider.step,
                step: slider.step,
                min: slider.min,
                max: slider.max,
                ticks: "always",
                dense: "",
              },
              on: {
                mouseup: function ($event) {
                  return _vm.changeURL(i)
                },
              },
              model: {
                value: slider.value,
                callback: function ($$v) {
                  _vm.$set(slider, "value", $$v)
                },
                expression: "slider.value",
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }