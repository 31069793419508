var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fab-button" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "scroll-x-reverse-transition",
            "max-width": "500",
            "content-class": "my-dialog-style",
            "hide-overlay": "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { elevation: "2", fab: "", tile: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.fab,
            callback: function ($$v) {
              _vm.fab = $$v
            },
            expression: "fab",
          },
        },
        [_vm._v(" "), _c("Panel")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }