var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        { attrs: { subheader: "" } },
        [
          _c("v-subheader", [_vm._v("数学的操作")]),
          _vm._v(" "),
          _c(
            "v-list-item-group",
            _vm._l(_vm.mathmaticalMethods, function (mathmaticalMethod) {
              return _c(
                "v-list-item",
                {
                  key: mathmaticalMethod.name,
                  on: {
                    click: function () {
                      _vm.math_method = mathmaticalMethod
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      "\n          " +
                        _vm._s(mathmaticalMethod.name) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }