var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticStyle: { display: "flex", "align-items": "center" },
          attrs: { cols: "3" },
        },
        [
          _c("v-text-field", {
            attrs: { label: "title" },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { outlined: "", color: "primary" },
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("save")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.textValue,
              callback: function ($$v) {
                _vm.textValue = $$v
              },
              expression: "textValue",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "8" } }, [_c("dcwmt_animation")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }