var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    { attrs: { app: "", value: _vm.canDraw } },
    [
      _vm.nowlink === "ロード" ? _c("drawer_load") : _vm._e(),
      _vm._v(" "),
      _vm.nowlink === "カラーマップの切り替え"
        ? _c("drawer_colormap")
        : _vm._e(),
      _vm._v(" "),
      _vm.nowlink === "数学的操作" ? _c("drawer_math") : _vm._e(),
      _vm._v(" "),
      _vm.nowlink === "描画方法の切り替え" ? _c("drawer_figure") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }