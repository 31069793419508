var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { staticClass: "ml-n9", attrs: { centered: "", color: "gray darken-1" } },
    _vm._l(_vm.links, function (link) {
      return _c(
        "v-tab",
        {
          key: link,
          on: {
            click: function ($event) {
              return _vm.onClickTab(link)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(link) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }